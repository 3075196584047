import React, { useState } from "react";
import { AxiosError } from "axios";

import styled from "styled-components";
import { useForm } from "react-hook-form";
import ErrorSummary, { addServerErrors } from "../components/ErrorSummary";
import {
  MembershipCreateModel,
  MembershipType,
  MembershipUpdateModel,
  MembershipViewModel,
} from "../hooks/membership/membershipModels";
import useCreateMembership from "../hooks/membership/useCreateMembership";
import useGetMemberships from "../hooks/membership/useMemberships";
import useGetMembershipsByUserId from "../hooks/membership/useMembershipsByUserId";
import moment from "moment";
import { Box, BoxHeader } from "./Box";
import useBookingsByMembershipId from "../hooks/booking/useBookingsByMembershipId";
import useBookingsByUserIdAndMembershipId from "../hooks/booking/useBookingsByUserIdAndMembershipId";
import useClassesByIds from "../hooks/class/useClassesByIds";
import { BookingClass } from "../pages/Booking";
import { DatePicker } from "@material-ui/pickers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import useUpdateMembership from "../hooks/membership/useUpdateMembership";
import CustomButton from "./CustomButton";
import ButtonAligner from "./ButtonAligner";
import TextInput from "./form/TextInput";
import Dropdown from "./form/Dropdown";
export type UserMembershipsProps = {
  userId: string;
};

export type MembershipProps = {
  membership: MembershipViewModel;
  userid: string | undefined;
};
export type MembershipTableProps = {
  memberships: MembershipViewModel[] | undefined;
  userid: string | undefined;
};

const Hello = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey3);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;
const Bookings = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey3);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

const Membership = ({ membership, userid }: MembershipProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const {
    data: adminBookings,
    isLoading: adminBookingLoading,
  } = useBookingsByUserIdAndMembershipId(
    userid ? userid : "",
    membership.id,

    showDetails && userid !== undefined
  );

  const {
    data: pesantbookings,
    isLoading: pesantBookingLoading,
  } = useBookingsByMembershipId(
    membership.id,
    showDetails && userid === undefined
  );

  const bookings = adminBookings || pesantbookings;
  const loadingBookings = adminBookingLoading || pesantBookingLoading;

  const { data: classes, isLoading: classesLoading } = useClassesByIds(
    bookings ? bookings.map((x) => x.classId) : [],
    bookings !== undefined && bookings.length !== 0
  );
  const handleClickOpen = () => {
    setShowEdit(true);
  };

  const handleClose = () => {
    setShowEdit(false);
  };

  const {
    handleSubmit,
    setError,

    control,
  } = useForm<MembershipUpdateModel>();

  const [startDate, setStartDate] = useState<Date>(membership.start);
  const [endDate, setEndDate] = useState<Date | null>(membership.expires);
  const [createClassMutate] = useUpdateMembership();
  const onSubmit = (data: MembershipUpdateModel) => {
    data.membershipId = membership.id;
    data.userId = membership.userId;
    data.start = startDate;
    data.expires = endDate;
    createClassMutate(data)
      .then(() => {
        setShowEdit(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };
  return (
    <>
      <Hello>
        <div style={{ flexGrow: 1 }}>
          <div>
            <strong>
              {userid !== undefined &&
                membership.type === MembershipType.Free && <>Gratis</>}
              {membership.type === MembershipType.PunchCard && (
                <>
                  KLIPPEKORT - <strong>{membership.usedPunches}</strong> ud af{" "}
                  <strong>{membership.punches}</strong> klip brugt{" "}
                </>
              )}
              {userid !== undefined &&
                membership.type === MembershipType.Rolling && <>Almindelig</>}
            </strong>{" "}
          </div>
          <div>
            START: <strong>{moment(membership.start).format("LL")}</strong>
          </div>
          {membership.expires && (
            <div>
              UDLØB: <strong>{moment(membership.expires).format("LL")}</strong>
            </div>
          )}
          {userid !== undefined && membership.pbs && (
            <div>
              PBS: <strong>{membership.pbs}</strong>
            </div>
          )}
        </div>
        <div>
          {!loadingBookings && !classesLoading && (
            <CustomButton
              onClick={() => {
                setShowDetails(!showDetails);
              }}
            >
              {showDetails
                ? "Skjul bookinger"
                : "Vis bookinger (" + membership.usedPunches + ")"}
            </CustomButton>
          )}
          {userid !== undefined && (
            <CustomButton
              onClick={() => {
                handleClickOpen();
              }}
            >
              RET
            </CustomButton>
          )}
        </div>
        <Dialog
          open={showEdit}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Ret</DialogTitle>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText>Ret medlemskab</DialogContentText>

              <div>
                <DatePicker
                  label="Start dato"
                  format="dd-MM-yyyy"
                  value={startDate}
                  onChange={(date: Date | null) => {
                    if (date) setStartDate(date);
                  }}
                  animateYearScrolling
                />
              </div>
              <div>
                <DatePicker
                  format="dd-MM-yyyy"
                  clearable
                  label="Udløbs dato"
                  value={endDate}
                  onChange={(date: Date | null) => {
                    setEndDate(date);
                  }}
                  animateYearScrolling
                />
              </div>

              {membership.type === 2 && (
                <TextInput
                  label="Antal klip"
                  name="punches"
                  type="number"
                  control={control}
                  fullWidth={true}
                  defaultValue={membership.punches.toString()}
                />
              )}
              {membership.type === 1 && (
                <TextInput
                  label="PBS"
                  name="PBS"
                  control={control}
                  fullWidth={true}
                  defaultValue={membership.pbs}
                />
              )}
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={handleClose} color="primary">
                Annuller
              </CustomButton>
              <CustomButton type="submit" color="primary">
                Ret medlemskab
              </CustomButton>
            </DialogActions>
          </form>
        </Dialog>
      </Hello>
      {showDetails && (
        <Bookings>
          {showDetails &&
            bookings &&
            bookings.map((booking) => {
              const classItem = classes?.find((b) => b.id === booking.classId);
              if (!classItem) {
                return null;
              }
              return (
                <BookingClass
                  key={booking.classId}
                  booking={booking}
                  class={classItem}
                  showDate={true}
                  activeBookings={bookings ? bookings.filter(x => x.occupyActiveBookingSlot === true).length : 0}
                />
              );
            })}
          {showDetails &&
            bookings &&
            bookings.length === 0 &&
            !loadingBookings && <div>Ingen bookinger</div>}
        </Bookings>
      )}
    </>
  );
};

const MembershipTable = ({ memberships, userid }: MembershipTableProps) => {
  return (
    <>
      {memberships && (
        <div>
          {memberships.map((x) => (
            <Membership membership={x} userid={userid} key={x.id} />
          ))}
        </div>
      )}
    </>
  );
};
export const UserMemberships = () => {
  const { data: memberships, isLoading } = useGetMemberships();

  return (
    <Box>
      <BoxHeader>Medlemsskab</BoxHeader>
      {isLoading && <div>Loader..</div>}
      {memberships && memberships.length > 0 && (
        <MembershipTable memberships={memberships} userid={undefined} />
      )}
      {memberships && memberships.length === 0 && (
        <p>Du har ikke et aktivt medlemskab. Kontakt Boxen8500.</p>
      )}
    </Box>
  );
};
const UserMembershipsById = ({ userId }: UserMembershipsProps) => {
  // const { data: memberships, isLoading } = userId ? useGetMembershipsByUserId(userId) : useGetMemberships();
  const { data: memberships, isLoading } = useGetMembershipsByUserId(userId);

  const [createMutate] = useCreateMembership();
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    setError,
  } = useForm<MembershipCreateModel>();

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onSubmit = (data: MembershipCreateModel) => {
    data.start = startDate;
    data.expires = endDate;
    createMutate(data)
      .then(() => {
        setOpen(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };

  const [open, setOpen] = React.useState(false);
  const [cctype, setCctype] = React.useState(1);

  return (
    <Box>
      <BoxHeader>Medlemsskab</BoxHeader>

      {isLoading && <div>Loading</div>}
      <MembershipTable memberships={memberships} userid={userId} />
      <ButtonAligner>
        <CustomButton onClick={() => setOpen(true)}>
          Tilføj nyt medlemskab
        </CustomButton>
      </ButtonAligner>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Tilføj medlemskab</DialogTitle>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>Tilføj medlemskab</DialogContentText>
            {/* register your input into the hook by invoking the "register" function */}
            <input
              name="userId"
              type="hidden"
              defaultValue={userId}
              ref={register({
                required: { value: true, message: "bruger id påkrævet" },
              })}
            />
            <Dropdown
              label="Medlemskab type"
              name="type"
              control={control}
              multiple={false}
              fullWidth={true}
              defaultValue={cctype}
              value={cctype}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                var values = event.target.value as number;
                setValue("type", values);
                setCctype(values);
              }}
            >
              <MenuItem value={1}>Almindelig</MenuItem>
              <MenuItem value={2}>Klippekort</MenuItem>
              <MenuItem value={0}>Gratis</MenuItem>
            </Dropdown>
            {cctype === 2 && (
              <TextInput
                label="Antal klip"
                name="punches"
                type="number"
                control={control}
                fullWidth={true}
                defaultValue={"10"}
              />
            )}
            <div>
              <DatePicker
                label="Start dato"
                format="dd-MM-yyyy"
                value={startDate}
                onChange={(date: Date | null) => {
                  if (date) setStartDate(date);
                }}
                animateYearScrolling
              />
            </div>{" "}
            <div>
              <DatePicker
                format="dd-MM-yyyy"
                clearable
                label="Udløbs dato"
                value={endDate}
                onChange={(date: Date | null) => {
                  setEndDate(date);
                }}
                animateYearScrolling
              />
            </div>
            {cctype === 1 && (
              <TextInput
                label="PBS"
                name="PBS"
                control={control}
                fullWidth={true}
              />
            )}
            <ErrorSummary errors={errors} />
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => {
                setOpen(false);
              }}
              color="primary"
            >
              Annuller
            </CustomButton>
            <CustomButton
              type="submit"
              color="primary"
              // disabled={accountToAdd === null}
            >
              Gem
            </CustomButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default UserMembershipsById;
