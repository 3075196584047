import React from "react";
import {  ErrorOption, FieldName, useForm } from "react-hook-form";
import {
  FullAccountViewModel,
  UpdateWriteModel,
} from "../hooks/account/accountModels";
import { BoxHeader } from "./Box";
import ButtonAligner from "./ButtonAligner";
import CustomButton, { ThemeColor } from "./CustomButton";
import ErrorSummary from "./ErrorSummary";
import TextInput from "./form/TextInput";

export type EditUserDetailsProps = {
  user: FullAccountViewModel;
  handleCancelEdit: () => void;
  handleSubmitEdit: (
    data: UpdateWriteModel,
    setError: (name: FieldName<UpdateWriteModel>, error: ErrorOption) => void
  ) => void;
};
const EditUserDetails = ({
  user,
  handleCancelEdit,
  handleSubmitEdit,
}: EditUserDetailsProps) => {
  const {
    handleSubmit,
    setError,
    errors,
    control,
  } = useForm<UpdateWriteModel>();

  const onSubmit = (data: UpdateWriteModel) => {
    handleSubmitEdit(data, setError);
  };
  return (
    <>
      {user && (
        <div>
          <BoxHeader>Personlige oplysninger</BoxHeader>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label="Fornavn"
              name="firstName"
              control={control}
              fullWidth={true}
              defaultValue={user.firstName}
            />
            <TextInput
              label="Efternavn"
              name="lastName"
              control={control}
              fullWidth={true}
              defaultValue={user.lastName}
            />
            <TextInput
              label="Adresse"
              name="address"
              control={control}
              fullWidth={true}
              defaultValue={user.address}
            />
            <TextInput
              label="Postnr"
              name="zipCode"
              control={control}
              fullWidth={true}
              defaultValue={user.zipCode}
            />
            <TextInput
              label="By"
              name="city"
              control={control}
              fullWidth={true}
              defaultValue={user.city}
            />
            <TextInput
              label="Telefon"
              name="phone"
              control={control}
              fullWidth={true}
              defaultValue={user.phone}
            />
            <TextInput
              label="Email adresse"
              name="email"
              control={control}
              fullWidth={true}
              defaultValue={user.email}
            />

            <ErrorSummary errors={errors} />
            <br />
            <ButtonAligner>
              <CustomButton type="submit">Gem oplysninger</CustomButton>
              <CustomButton
                onClick={handleCancelEdit}
                themecolor={ThemeColor.Black}
              >
                Annuller
              </CustomButton>
            </ButtonAligner>
          </form>
        </div>
      )}
    </>
  );
};

export default EditUserDetails;
