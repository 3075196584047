import { AxiosResponse } from "axios";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { RequestPasswordResetModel } from "./accountModels";

export default function useRequestPasswordReset() {
  return useMutationForForms<AxiosResponse<string>, unknown, RequestPasswordResetModel>(
    (data: RequestPasswordResetModel) => axios.post(`/api/account/RequestPasswordReset`, data),
    {
      onSuccess: (data, updateData) => {
        
        
      },
    }
  );
}
