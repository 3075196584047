import { useQuery } from "react-query";
import axios from "./../../axios"
import { ClassTypeViewModel } from "./classTypeModels";


const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/classtype`);
  return data;
};

export default function useClassTypes() {
  return useQuery<ClassTypeViewModel[]>(["classTypes"], getClasses);
}
