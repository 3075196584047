import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";
import { ClassCreateWriteModel, ClassViewModel } from "./classModels";

export default function useCreateClass() {
  return useMutationWithError<ClassViewModel, unknown, ClassCreateWriteModel>(
    (data: ClassCreateWriteModel) => axios.post(`/api/class`, data),
    {
      onSuccess: (data, createData) => {
        // Query Invalidations
        var converted = new Date(createData.start)  
        // console.log(date)
        queryCache.invalidateQueries(["classes", `${converted.getFullYear()}-${converted.getMonth() + 1}-${converted.getDate()}`]);
      },
    }
  );
}
