import { AxiosResponse } from "axios";
import { queryCache } from "react-query";
import { LoginPayload } from "../../Context/actions";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";

export default function useRegisterAccount() {
  return useMutationForForms<AxiosResponse<string>, unknown, LoginPayload>(
    (data: LoginPayload) => axios.post(`/api/account/token`, data),
    {
      onSuccess: (data, updateData) => {
        
        queryCache.invalidateQueries(["me"]);
      },
    }
  );
}
