import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { EmailSettingsWriteModel } from "./accountModels";

export default function useSetEmailSettings() {
  return useMutationForForms<unknown, unknown, EmailSettingsWriteModel>(
    (data: EmailSettingsWriteModel) => axios.post(`/api/account/SetEmailSettings`, data),
    {
      onSuccess: (data, updateData) => {
        
        queryCache.invalidateQueries(["me"]);
      },
    }
  );
}
