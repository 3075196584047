import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  Crosshair,
} from "react-vis";
import useWeeklyGraph from "../hooks/activity/useWeeklyGraph";

const WeeklyStatistics = () => {
  const { data: hello } = useWeeklyGraph();

  const data: any[] = [];
  const invisdata: any[] = [];
  let maxTrainings = 0;
  if (hello) {
    for (let index = 0; index < hello.length; index++) {
      const item = hello[index];
      const types: any[] = [];
      for (const key in item.split) {
        types.push({
          name: key,
          count: item.split[key],
        });
      };
      data.push({ x: index, week: item.week, y: item.trainings, types: types });

      if (item.trainings > maxTrainings)
        maxTrainings = item.trainings;

      if (index === 0) {
        invisdata.push({ x: item.week, y: 0 });
      }
    }
  }

  const trainingsArray = [];
  for (let index = 0; index <= maxTrainings; index++) {
    trainingsArray.push(index);
  }
  const [index, setIndex] = useState<number>(-1);

  const _formatCrosshairItems = (values: any[]) => {
    return values[0].types.map((v: any, i: number) => {
      return {
        title: v.name,
        value: v.count,
      };
    });
  };

  const _formatCrosshairTitle = (values: any[]) => {
    return {
      title: "Træninger i uge " + data[values[0].x].week,
      value: values[0].y,
    };
  };

  const _mouseLeaveHandler = () => {
    setIndex(-1);
  };

  const _nearestXHandler = (value: any, { index: ii }: any) => {

    if (index !== ii) {
      setIndex(ii);
    }
  };

  return (
    <div className="example-with-click-me">
      {data && data.length > 0 && (
        <div className="chart">
          <FlexibleWidthXYPlot
            margin={{ right: 20 }}
            animation
            onMouseLeave={_mouseLeaveHandler}
            height={200}
          >
            <HorizontalGridLines />
            <YAxis
              className="cool-custom-name"
              tickSizeInner={0}
              tickSizeOuter={8}
              hideTicks={false}
              tickValues={trainingsArray}
              tickFormat={(tick) => {
                return Number(tick).toString();
              }}
            />
            <XAxis
              className="even-cooler-custom-name"
              tickSizeInner={0}
              tickSizeOuter={8}
              tickValues={data.map(item => item.x)}
              tickFormat={(tick) => {
                return "uge " + data[Number(tick)].week.toString();
              }}
            />

            <LineSeries
              data={data}
              color={"rgb(190,23,49)"}
              curve="curveMonotoneX"
              onNearestX={_nearestXHandler}
            />
            {/* <LineSeries data={invisdata} opacity={0} /> */}
            <Crosshair
              itemsFormat={_formatCrosshairItems}
              titleFormat={_formatCrosshairTitle}
              values={index > -1 ? [data[index]] : []}
            />
          </FlexibleWidthXYPlot>
        </div>
      )}
    </div>
  );
};

export default WeeklyStatistics;
