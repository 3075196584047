import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export default function useDeleteFriendship() {
  return useMutationWithError<unknown, unknown, string>(
    (friendship: string) => axios.delete(`/api/Friendship/delete?friendship=${friendship}`),
    {
      onSuccess: (data, friendship) => {
        // Query Invalidations
        queryCache.invalidateQueries(["friends"]);
        queryCache.invalidateQueries(["friendrequests"]);
      }
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}