import React from "react";
import styled from "styled-components";
import Logo from "../../components/Logo";
import { BoxHeader } from "../../components/Box";

const LogoHolder = styled.div`
  padding-bottom: 40px;
`;

export default function Support() {
  return (
    <>
      <LogoHolder>
        <Logo forbackground={false} />
      </LogoHolder>
      <BoxHeader>Support</BoxHeader>
      <p>
        Har du problemer med at logge ind, booking, eller anden funktionalitet,
        venligst kontakt support.
      </p>
      <p>Du kan kontakte support på info@boxen8500.dk via email.</p>
    </>
  );
}
