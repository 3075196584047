import { useQuery } from "react-query";
import axios from "./../../axios";
import { NotificationSettingItem } from "./notificationModels";

const get = async (_: any) => {
  const { data } = await axios.get(`/api/notification/GetSettings`);
  return data;
};

export default function useGetNotificationsSettings() {
  return useQuery<NotificationSettingItem[]>(["notificationsSettings"], get);
}
