import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import moment from "moment";
import "moment/locale/da";
import AuthProvider from "./Context/AuthProvider";
import "typeface-open-sans";
import styled, { createGlobalStyle } from "styled-components";
import {
  palette,
  PaletteProps,
  spacing,
  SpacingProps,
} from "@material-ui/system";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Navigation from "./components/Navigation";
import Content from "./components/Content";
import daLocale from "date-fns/locale/da";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import APIErrorProvider from "./Context/APIErrorProvider";
import APIErrorNotification from "./components/APIErrorNotification";

import SocketProvider from "./Context/SocketProvider";
import UINotifications from "./components/UINotifications";
import Updater from "./components/Updater";
import { Capacitor } from "@capacitor/core";
import { DELETEACCOUNT, LOGIN, LOGOUT, PASSWORDRESET, PRIVACYPOLICY, REGISTERUSER, REQUESTPASSWORDRESET, SUPPORT } from "./routes";
import PublicLayout from "./pages/public/PublicLayout";
import PushNotificationProvider from "./Context/PushNotificationProvider";

const GlobalStyle = createGlobalStyle<PlatformProps>`
:root {
  --red: rgb(190,23,49);
  --black: rgb(0,0,0);
  --white: rgb(255,255,255);
  --grey1: rgb(134,135,134);
  --grey2: rgb(197,198,198);
  --grey3: rgb(236,236,236);
  --green: rgb(146,184,126);
  --blue: rgb(37,142,171);
  --platform-top: ${(props) => (Capacitor.getPlatform() === "ios" ? "30px" : "0px")};
  --platform-bottom: ${(props) => (Capacitor.getPlatform() === "ios" ? "15px" : "0px")};

}
#root{

  display: flex;
}
  #root, html, body {
    height: 100%;
    background: var(--grey3);
    color: var(--black)
  }
  a {
    color: var(--black);
    text-decoration-color: var(--grey2);
  }
  a:hover{
    color: var(--red)
  }
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
  * {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
}
  `;
const Box = styled.div<PaletteProps & SpacingProps>`
  ${spacing}${palette}

  // background: white;
  height: 100%;
  width: 100%;
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    margin: auto;
    height: 90%;
    border-radius: 18px;
    box-shadow: 0px 0px 34px 29px rgba(0, 0, 0, 0.02);
    min-width: 1024px;
    min-height: 768px;
    width: 80%;
  }
`;

export type PlatformProps = {
  platform: string;
};
const Layout = styled.div<PaletteProps & SpacingProps>`
  height: 100%;
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-rows: auto;
  grid-template-areas: "navigation  content";
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    grid-gap: 0;
    grid-template-columns: auto;
    grid-template-rows: auto calc(55px + var(--platform-bottom));
    grid-template-areas:
      "content "
      "navigation";
  }
`;

const theme = createMuiTheme({
  // status: {
  //   danger: orange[500],
  // },
  palette: {
    primary: {
      main: "#ff00ff",
    },
    background: {
      // default: "#EEF5F5",
      // default: "#EFF1F3",
    },
    success: {
      main: "rgb(146,184,126)",
      // contrastText:
    },
  },
  typography: {
    fontFamily: ['"Open Sans"'].join(","),
    fontSize: 12,
  },
});

function App() {
  const locale = "da";

  // const { Filesystem } = Plugins;
  // const readDir = (path: string, index: number) => {
  //   Filesystem.readdir({
  //     path: path,
  //     directory: FilesystemDirectory.Documents,
  //   }).then((val) => {
  //     console.log("".padEnd(index, "-") + "" + path);
  //     val.files.map((file) => {
  //       var newIndex = index + 1;
  //       readDir(path + file + "/", newIndex);
  //       return null;
  //     });
  //   });
  // };
  // readDir("../", 0);
  moment.locale(locale);

  // // Show us the notification payload if the app is open on our device
  // PushNotifications.addListener(
  //   "pushNotificationReceived",
  //   (notification: PushNotification) => {
  //     alert("Push received: " + JSON.stringify(notification));
  //   }
  // );

  // // Method called when tapping on a notification
  // PushNotifications.addListener(
  //   "pushNotificationActionPerformed",
  //   (notification: PushNotificationActionPerformed) => {
  //     alert("Push action performed: " + JSON.stringify(notification));
  //   }
  // );
  return (
    <React.StrictMode>
      <Updater>
        <AuthProvider>
          <APIErrorProvider>
            <SocketProvider>
              <PushNotificationProvider>
                <ThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={daLocale}
                  >
                    <CssBaseline />
                    <APIErrorNotification />
                    <UINotifications />
                    <Router>
                      <Switch>
                        <Route path={[LOGIN, LOGOUT, REGISTERUSER, PRIVACYPOLICY, DELETEACCOUNT, SUPPORT, REQUESTPASSWORDRESET, PASSWORDRESET]}>
                          <PublicLayout />
                        </Route>
                        <Route>
                          <Box>
                            <Layout>
                              <Content />
                              <Navigation />
                            </Layout>
                          </Box>
                        </Route>
                      </Switch>
                    </Router>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </PushNotificationProvider>
            </SocketProvider>
          </APIErrorProvider>
        </AuthProvider>
      </Updater>
      <GlobalStyle platform={Capacitor.getPlatform()} />
    </React.StrictMode>
  );
}

export default App;
