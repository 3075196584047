import { queryCache } from "react-query";
import axios from "../../axios"
import useMutationWithError from "../useMutationWithError";
import {  MembershipCreateModel, MembershipViewModel } from "./membershipModels";


export default function useCreateMembership() {
  return useMutationWithError<MembershipViewModel, unknown, MembershipCreateModel>(
    (data: MembershipCreateModel) => axios.post(`/api/membership`, data),
    {
      onSuccess: () => {
        // Query Invalidations
        queryCache.invalidateQueries("memberships");
      },
    }
  );
}
