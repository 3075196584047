import { queryCache } from "react-query";
import axios from "../../axios"
import useMutationWithError from "../useMutationWithError";
import { DefaultClassUpdateModel, DefaultClassViewModel } from "./defaultClassModels";


export default function useUpdateDefaultClass() {
  return useMutationWithError<DefaultClassViewModel, unknown, DefaultClassUpdateModel>(
    (data: DefaultClassUpdateModel) => axios.post(`/api/defaultclass/update`, data),
    {
      onSuccess: () => {
        // Query Invalidations
        queryCache.invalidateQueries("defaultClasses");
      },
    }
  );
}
