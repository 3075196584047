import { useQuery } from "react-query";
import axios from "./../../axios";
import { ActivityModel } from "./activityModels";

const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/Activity/Get`);
  return data;
};

export default function useClassTypes() {
  return useQuery<ActivityModel>(["activity"], getClasses);
}
