import { useQuery } from "react-query";
import axios from "./../../axios"
import { HistoryEntry } from "./bookingModels";


const getBookings = async (_: any, classId: string) => {
  const { data } = await axios.get(`/api/booking/GetHistory/${classId}`);
  return data;
};

export default function useBookingHistoryByClassId(classId: string) {
  return useQuery<HistoryEntry[]>(["bookingHistoryByClassId", classId], getBookings);
}
