import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import axios from "./../../axios";
import { RegisterDevicePayload, RegisterDeviceResponse } from "./notificationModels";

export default function useRegisterDevice() {
  return useMutation<AxiosResponse<RegisterDeviceResponse>,   unknown, RegisterDevicePayload>(
    (payload) => {
    
      return axios.post(`/api/notification/RegisterDevice`, payload);
    },
    {
      onSuccess: (data, notificationId) => {
        // Query Invalidations
        // queryCache.invalidateQueries(["bookingsByClassId", classId]);
      },
    }
  );

  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
