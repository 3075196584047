import { AxiosError } from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { addServerErrors } from "../components/ErrorSummary";
import useClassTypes from "../hooks/classTypes/useClassTypes";

import { DatePicker } from "@material-ui/pickers";
import useGetClassesByDate from "../hooks/class/useGetClassesByDate";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import { PageHeader } from "../components/PageHeader";
import { PageContent } from "../components/PageContent";
import useFillClasses from "../hooks/class/useFillClasses";
import { CLASS, CLASSSETUPDATE } from "../routes";
import {
  ClassCreateWriteModel,
  ClassUpdateSlotsWriteModel,
  ClassUpdateTimeWriteModel,
  ClassViewModel,
} from "../hooks/class/classModels";
import useCreateClass from "../hooks/class/useCreateClass";
import useCancelClass from "../hooks/class/useCancelClass";
import useDeleteClass from "../hooks/class/useDeleteClass";
import moment from "moment";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import Highlight from "../components/Highlight";
import useUpdateClassTime from "../hooks/class/useUpdateClassTime";
import useUpdateClassSlots from "../hooks/class/useUpdateClassSlots";
import TextInput from "../components/form/TextInput";
import Dropdown from "../components/form/Dropdown";
import { PageContainer } from "../components/PageContainer";

import { Box, BoxHeader } from "../components/Box";
export default function ClassSetup() {
  const classTypes = useClassTypes();

  let history = useHistory();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  let match = useRouteMatch();
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    history.push(
      `${match.path}/${date?.getFullYear()}-${
        (date?.getMonth() ?? 0) + 1
      }-${date?.getDate()}`
    );
  };

  return (
    <PageContainer>
      <PageHeader backButton={false}>Holdopsætning</PageHeader>
      <PageContent>
        <Box>
          {classTypes.isLoading && <div>Loader</div>}

          <DatePicker
            clearable
            label="Vælg dato"
            value={selectedDate}
            onChange={handleDateChange}
            animateYearScrolling
          />
          <Switch>
            <Route path={CLASSSETUPDATE}>
              <br />
              <br />
              <ClassSetupByDate />
            </Route>
            <Route path={match.path}></Route>
          </Switch>
        </Box>
      </PageContent>
    </PageContainer>
  );
}

interface ParamTypes {
  year: string;
  month: string;
  day: string;
}

function ClassSetupByDate() {
  let { year, month, day } = useParams<ParamTypes>();
  const convertedYear = Number(year);
  const convertedMonth = Number(month);
  const convertedDay = Number(day);
  const { data: classes, isLoading } = useGetClassesByDate(
    convertedYear,
    convertedMonth,
    convertedDay
  );

  const [useFillClassesMutate] = useFillClasses();
  function FillClasses() {
    useFillClassesMutate({
      year: year,
      month: month,
      day: day,
    });
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classTypes = useClassTypes();

  const {
    handleSubmit,
    setError,
    setValue,
    control,
  } = useForm<ClassCreateWriteModel>();

  const [createClassMutate] = useCreateClass();
  const onSubmit = (data: ClassCreateWriteModel) => {
    data.start = new Date(
      convertedYear,
      convertedMonth - 1,
      convertedDay,
      Number(data.start.toString().split(":")[0]),
      Number(data.start.toString().split(":")[1])
    );
    data.end = new Date(
      convertedYear,
      convertedMonth - 1,
      convertedDay,
      Number(data.end.toString().split(":")[0]),
      Number(data.end.toString().split(":")[1])
    );

    createClassMutate(data)
      .then(() => {
        setOpen(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };

  return (
    <div>
      <BoxHeader>Hold på valgte dato:</BoxHeader>
      {isLoading && <div>loader</div>}
      {classes && classes.length === 0 && (
        <div>
          Ingen hold på denne dato
          <div>
            <CustomButton onClick={() => FillClasses()}>
              Udfyld dag med standard opsætning
            </CustomButton>
          </div>
        </div>
      )}

      {classes && (
        <div>
          {classes.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Navn</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>Slut</TableCell>
                    <TableCell>Pladser</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classes.map((x) => {
                    return <ClassSetupItem key={x.id} x={x} />;
                  })}{" "}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <br />
          <br />
          <CustomButton onClick={handleClickOpen}>
            Tilføj nyt hold til valgte dato
          </CustomButton>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Tilføj hold {year}- {month}-{day}
            </DialogTitle>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <div>
                  <Dropdown
                    label="Hold type"
                    name="classTypeId"
                    control={control}
                    fullWidth={true}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      var values = event.target.value as string;
                      setValue("classTypeId", values);
                    }}
                  >
                    {classTypes.data &&
                      classTypes.data.map((x) => {
                        return (
                          <MenuItem key={x.id} value={x.id}>
                            {x.name}
                          </MenuItem>
                        );
                      })}
                  </Dropdown>
                </div>
                <div>
                  <TextInput
                    label="Starttidspunkt"
                    name="start"
                    type="time"
                    control={control}
                    fullWidth={true}
                    defaultValue={"14:30"}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </div>{" "}
                <div>
                  <TextInput
                    label="Sluttidspunkt"
                    name="end"
                    type="time"
                    control={control}
                    fullWidth={true}
                    defaultValue={"15:30"}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </div>{" "}
                <div>
                  <TextInput
                    label="Antal pladser"
                    name="slots"
                    control={control}
                    fullWidth={true}
                    defaultValue={"10"}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <CustomButton
                  onClick={handleClose}
                  themecolor={ThemeColor.Black}
                >
                  Annuller
                </CustomButton>
                <CustomButton type="submit">Tilføj hold</CustomButton>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      )}
    </div>
  );
}

interface ClassSetupItemProps {
  x: ClassViewModel;
}
function ClassSetupItem({ x }: ClassSetupItemProps) {
  const [useCancelClassMutate] = useCancelClass();
  function CancelClass(classItem: ClassViewModel) {
    useCancelClassMutate({ classId: classItem.id, date: classItem.start });
  }

  const [useDeleteClassMutate] = useDeleteClass();
  function DeleteClass(classItem: ClassViewModel) {
    useDeleteClassMutate({ classId: classItem.id, date: classItem.start });
  }

  const [openSlots, setOpenSlots] = React.useState(false);
  const [openTime, setOpenTime] = React.useState(false);

  const {
    handleSubmit: handleTimeSubmit,
    setError: setErrorTime,
    control: controlTime,
  } = useForm<ClassUpdateTimeWriteModel>();

  const [updateClassTimeMutate] = useUpdateClassTime();
  const onTimeSubmit = (data: ClassUpdateTimeWriteModel) => {
    data.classId = x.id;
    data.start = new Date(
      x.start.getFullYear(),
      x.start.getMonth(),
      x.start.getDate(),
      Number(data.start.toString().split(":")[0]),
      Number(data.start.toString().split(":")[1])
    );
    data.end = new Date(
      x.end.getFullYear(),
      x.end.getMonth(),
      x.end.getDate(),
      Number(data.end.toString().split(":")[0]),
      Number(data.end.toString().split(":")[1])
    );

    updateClassTimeMutate(data)
      .then(() => {
        setOpenTime(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setErrorTime);
        }
      });
  };

  const {
    handleSubmit: handleSlotsSubmit,
    setError: setErrorSlots,
    control: controlSlots,
  } = useForm<ClassUpdateSlotsWriteModel>();

  const [updateClassSlotsMutate] = useUpdateClassSlots();
  const onSlotsSubmit = (data: ClassUpdateSlotsWriteModel) => {
    data.classId = x.id;

    updateClassSlotsMutate(data)
      .then(() => {
        setOpenSlots(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setErrorSlots);
        }
      });
  };

  return (
    <>
      <TableRow key={x.id}>
        <TableCell>
          <Link to={CLASS.replace(":classId", x.id)}>{x.classTypeName}</Link>

          {x.highlights.map((h) => (
            <Highlight color={h.color} text={h.text} />
          ))}
        </TableCell>

        <TableCell>{moment(x.start).format("LT")}</TableCell>
        <TableCell>{moment(x.end).format("LT")}</TableCell>
        <TableCell>
          {x.bookings}/{x.slots}
        </TableCell>
        <TableCell>
          {!x.cancelled && (
            <CustomButton
              onClick={() => {
                setOpenTime(true);
              }}
            >
              Ret tidspunkt
            </CustomButton>
          )}
          {!x.cancelled && (
            <CustomButton
              onClick={() => {
                setOpenSlots(true);
              }}
            >
              Ret pladser
            </CustomButton>
          )}

          {x.bookings !== 0 && !x.cancelled && (
            <CustomButton
              themecolor={ThemeColor.Black}
              onClick={() => {
                if (window.confirm("Er du sikker på du vil aflyse holdet?"))
                  CancelClass(x);
              }}
            >
              Aflys hold
            </CustomButton>
          )}
          {(x.bookings === 0 || x.cancelled) && (
            <CustomButton
              themecolor={ThemeColor.Black}
              onClick={() => {
                if (window.confirm("Er du sikker på du vil fjernet holdet?"))
                  DeleteClass(x);
              }}
            >
              Fjern hold
            </CustomButton>
          )}
        </TableCell>
      </TableRow>
      <Dialog
        open={openTime}
        onClose={() => {
          setOpenTime(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Ret tidspunkt</DialogTitle>
        <form noValidate onSubmit={handleTimeSubmit(onTimeSubmit)}>
          <DialogContent>
            <DialogContentText>Ret tidspunkt</DialogContentText>
            <TextInput
              label="Starttidspunkt"
              name="start"
              type="time"
              control={controlTime}
              fullWidth={true}
              defaultValue={moment(x.start).format("LT")}
              inputProps={{
                step: 300, // 5 min
              }}
            />
            <TextInput
              label="Sluttidspunkt"
              name="end"
              type="time"
              control={controlTime}
              fullWidth={true}
              defaultValue={moment(x.end).format("LT")}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => {
                setOpenTime(false);
              }}
            >
              Annuller
            </CustomButton>
            <CustomButton
              type="submit"
              // disabled={accountToAdd === null}
            >
              Gem nyt tidspunkt
            </CustomButton>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openSlots}
        onClose={() => {
          setOpenSlots(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Ret antal pladser</DialogTitle>
        <form noValidate onSubmit={handleSlotsSubmit(onSlotsSubmit)}>
          <DialogContent>
            <DialogContentText>Ret antal pladser</DialogContentText>
            <TextInput
              label="Antal pladser"
              name="slots"
              control={controlSlots}
              fullWidth={true}
              defaultValue={x.slots.toString()}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => {
                setOpenSlots(false);
              }}
            >
              Annuller
            </CustomButton>
            <CustomButton
              type="submit"
              // disabled={accountToAdd === null}
            >
              Gem ny holdstørrelse
            </CustomButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
