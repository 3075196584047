import CustomButton from "../components/CustomButton";
import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, BoxHeader } from "../components/Box";
import {
  PageContainer,
  ContentContainerBackgroundSize,
  ContentContainerBackgroundColor,
} from "../components/PageContainer";
import { BigPageHeader } from "../components/PageHeader";
import useActivities from "../hooks/activity/useActivities";

import useActiveUserBookings from "../hooks/booking/useActiveUserBookings";
import useClasses from "../hooks/class/useClasses";
import { BookingClass } from "../pages/Booking";
import { BOOKING } from "../routes";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { PageContent } from "../components/PageContent";
import "../../node_modules/react-vis/dist/style.css";
import WeeklyStatistics from "../components/WeeklyStatistics";
import styled from "styled-components";
import ButtonAligner from "../components/ButtonAligner";
import ErrorBoundary from "../components/ErrorBoundry";
import TotalIcon from "../icons/TotalIcon";
import MonthIcon from "../icons/MonthIcon";
import YearIcon from "../icons/YearIcon";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import Logo from "../components/Logo";

const DashboardContainer = styled.div`
  text-align: center;
  color: var(--white);
  margin-top: 10px;
  grid-template-columns: 100% 100% 100%;
  display: grid;
  overflow: auto;
  grid-gap: 0;
  width: 100%;
  scroll-snap-type: x mandatory;
`;
const Next = styled.div`
  position: absolute;
  cursor: pointer;
  right: 40px;
  margin-top: 60px;
  &:after {
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='white' d='M6.2 1L4.8 2.5l7.4 7.5-7.4 7.5L6.2 19l9-9z'/></svg>");
  }
`;
const Prev = styled.div`
  position: absolute;
  cursor: pointer;
  left: 40px;
  margin-top: 60px;
  transform: rotate(180deg);
  &:after {
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='white' d='M6.2 1L4.8 2.5l7.4 7.5-7.4 7.5L6.2 19l9-9z'/></svg>");
  }
`;

const DashboardItem = styled.div`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  text-align: center;
  color: var(--white);
  margin-top: 10px;
  svg {
    width: 120px;
  }
`;
const DashboardText = styled.div`
  line-height: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const DashboardBigText = styled.div`
  font-size: 40px;
  font-weight: 600;
`;

const DashboardLogoContainer = styled.div`
svg {
  width: 130px;
}
`;
export default function Dashboard() {
  // let match = useRouteMatch();

  const { data: activity } = useActivities();
  const { data: classes, isLoading: classesIsLoading } = useClasses();
  const {
    data: bookings,
    isLoading: bookingsLoading,
  } = useActiveUserBookings();
  var loading = bookingsLoading || classesIsLoading;
  const [clicks, setClicks] = useState<number>(0);
  const [deviceId, setDeviceId] = useState<string>("NotLoaded");
  const [showDiagnostics, setShowDiagnostics] = useState<boolean>(false);
  const handleDashboardClick = () => {
    setClicks(clicks + 1);
    if (clicks > 10) {
      Device.getId().then((info) => {
        setDeviceId( info.identifier );
        console.log("IDENTIFUEDADS::: " + info.identifier)
      });
      setClicks(0);
      setShowDiagnostics(true);
    }
  };

  const inputRef22 = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleNextClick = () => {
    inputRef22.current.scrollTo({
      left: inputRef22.current.clientWidth + inputRef22.current.scrollLeft,
      behavior: "smooth",
    });
  };
  const handlePrevClick = () => {
    inputRef22.current.scrollTo({
      left: inputRef22.current.scrollLeft - inputRef22.current.clientWidth,
      behavior: "smooth",
    });
  };

  const [active, setActive] = useState<number>(0);
  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.large} backgroundColor={ContentContainerBackgroundColor.dark}>
      <BigPageHeader onClick={handleDashboardClick}>
        <DashboardLogoContainer>
        <Logo forbackground={true} />
        </DashboardLogoContainer>
      </BigPageHeader>
      <PageContent>
        <DashboardContainer
          ref={inputRef22}
          onScroll={(e: React.UIEvent<HTMLElement>) => {
            const newActive = Math.round(
              e.currentTarget.scrollLeft / e.currentTarget.clientWidth
            );
            if (active !== newActive) {
              setActive(newActive);
            }
          }}
        >
          {active !== 2 && <Next onClick={() => handleNextClick()} />}
          {active !== 0 && <Prev onClick={() => handlePrevClick()} />}
          <DashboardItem>
            <TotalIcon />
            <DashboardText>
              Du har trænet
              <DashboardBigText>
                {activity && activity.totalBookings}
              </DashboardBigText>
              gange i alt
            </DashboardText>
          </DashboardItem>
          <DashboardItem>
            <MonthIcon />
            <DashboardText>
              Du har trænet
              <DashboardBigText>
                {activity && activity.bookingsThisMonth}
              </DashboardBigText>
              gange denne måned
            </DashboardText>
          </DashboardItem>
          <DashboardItem>
            <YearIcon />
            <DashboardText>
              Du har trænet
              <DashboardBigText>
                {activity && activity.bookingsThisYear}
              </DashboardBigText>
              gange i år
            </DashboardText>
          </DashboardItem>
        </DashboardContainer>
        <Box>
          <BoxHeader>Tilmeldte hold</BoxHeader>
          {loading && <div>Loader</div>}
          {!loading &&
            bookings &&
            bookings.map((x) => {
              const classItem = classes?.find((c) => c.id === x.classId);
              return (
                classItem && (
                  <BookingClass
                    key={classItem.id}
                    activeBookings={bookings ? bookings.filter(x => x.occupyActiveBookingSlot === true).length : 0}
                    showDate={true}
                    booking={x}
                    class={classItem}
                  />
                )
              );
            })}
          {!loading && bookings && bookings.length === 0 && (
            <>
              <p>Du har ingen kommende bookinger</p>

              <ButtonAligner>
                <CustomButton component={NavLink} to={BOOKING}>
                  Gå til booking
                </CustomButton>
              </ButtonAligner>
            </>
          )}
        </Box>

        <Box>
          <BoxHeader>Ugentlige træninger</BoxHeader>
          <ErrorBoundary>
            <WeeklyStatistics />
          </ErrorBoundary>
        </Box>
      </PageContent>

      <Dialog
        open={showDiagnostics}
        onClose={() => {
          setShowDiagnostics(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Info</DialogTitle>

        <DialogContent>
          <div>
            <p>OK</p>
            <strong>Version:</strong> {process.env.REACT_APP_VERSION}
            <p>Device id: {Capacitor.getPlatform() !== "web" && deviceId}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => {
              setShowDiagnostics(false);
            }}
          >
            OK
          </CustomButton>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
}
