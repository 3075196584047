import React from "react";
export interface LogoSettingsProps {
  forbackground: boolean;

}
export default function Logo({ forbackground }: LogoSettingsProps) {

  const boxenColor = forbackground ? "#ffffff" : "#212121";
  const text8500Color = forbackground ? "#ffffff" : "#c41230";
  const background500Color = forbackground ? "#c41230" : "#ffffff";

  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      version="1"
      viewBox="25.82 133.77 321.33 100.41"
    >
      <defs>
        <clipPath id="fef425e04a">
          <path d="M303.066 135.043h44.086v98.785h-44.086zm0 0"></path>
        </clipPath>
      </defs>
      <path
        fill={boxenColor}
        d="M4.36-98.563h26.734c7.195 0 12.422 1.762 15.672 5.282 3.25 3.511 4.875 9.246 4.875 17.203v4.36c0 9.406-3.368 15.03-10.094 16.874 4.125 1.063 7.023 3.547 8.703 7.453 1.688 3.899 2.531 9.059 2.531 15.485 0 10.48-1.57 18.418-4.703 23.812C44.941-2.695 39.281 0 31.094 0H4.359zM27.655-61.5c1.676 0 2.82-.629 3.438-1.89.613-1.27.922-3.051.922-5.344v-9.875c0-3.207-1.418-4.813-4.25-4.813H23.64V-61.5zm-2.062 44.172c5.125 0 7.687-2.445 7.687-7.344v-12.281c0-2.977-.445-5.234-1.328-6.766-.875-1.531-2.422-2.297-4.64-2.297H23.64v28.578zm0 0"
        transform="translate(21.462 233.253)"
      ></path>
      <path
        fill={boxenColor}
        d="M27.89.922c-7.96 0-14.007-2.426-18.14-7.281-4.125-4.864-6.188-11.692-6.188-20.485V-73.78C3.563-82.27 5.626-88.676 9.75-93c4.133-4.32 10.18-6.484 18.14-6.484 7.946 0 13.989 2.164 18.126 6.484 4.132 4.324 6.203 10.73 6.203 19.219v46.937c0 8.867-2.07 15.711-6.203 20.532C41.879-1.489 35.836.921 27.89.921zm0-18.14c1.758 0 2.942-.759 3.547-2.282.614-1.531.922-3.676.922-6.438v-48.187c0-2.145-.289-3.883-.859-5.219-.574-1.344-1.742-2.015-3.5-2.015-3.293 0-4.938 2.484-4.938 7.453v48.094c0 2.824.344 4.964 1.032 6.421.687 1.45 1.953 2.172 3.797 2.172zm0 0"
        transform="translate(76.427 233.253)"
      ></path>
      <path
        fill={boxenColor}
        d="M1.484 0l10.563-52.328-8.375-46.36h19.281l5.156 33.97 4.485-33.97h19.39l-8.5 46.36L54.047 0H34.078l-6.187-38.563L21.578 0zm0 0"
        transform="translate(132.196 233.253)"
      ></path>
      <path
        fill={boxenColor}
        d="M4.36 0v-98.563h39.468v19.047h-19.39v19.157H43.03v18.593H24.438v22.61h20.656V0zm0 0"
        transform="translate(187.735 233.253)"
      ></path>
      <path
        fill={boxenColor}
        d="M4.36 0v-98.563h20.312l9.062 47.157v-47.157h19.047V0H33.5l-9.86-49.344V0zm0 0"
        transform="translate(235.012 233.253)"
      ></path>
      <g clipPath="url(#fef425e04a)">
        <path
          fill={background500Color}
          d="M303.066 135.043h44.086v98.852h-44.086zm0 0"
        ></path>
      </g>
      <path
        fill={text8500Color}
        d="M.313-8.64c0 2.75-.782 4.75-2.344 6C-3.594-1.39-5.898-.767-8.953-.767h-2c-1.582 0-2.844-.191-3.781-.578-.938-.383-1.633-1.054-2.094-2.015-.375.906-.992 1.558-1.86 1.953-.863.398-1.972.593-3.328.593h-.797c-2.613 0-4.539-.656-5.78-1.968-1.239-1.32-1.86-3.274-1.86-5.86 0-2.632.633-4.597 1.89-5.89 1.262-1.301 3.258-1.953 5.985-1.953 1.543 0 2.789.187 3.734.562.938.375 1.621 1.04 2.047 1.985.512-1.07 1.309-1.77 2.39-2.094 1.087-.32 2.641-.485 4.673-.485.398 0 .656.012.78.032 3.056 0 5.36.62 6.923 1.859C-.47-13.395.313-11.398.313-8.641zm-19.704 0c0-1.051-.957-1.579-2.875-1.579-.738 0-1.363.125-1.875.375-.52.242-.78.64-.78 1.203 0 .555.26.954.78 1.204.512.25 1.137.375 1.875.375 1.918 0 2.875-.524 2.875-1.579zm13.75 0c0-1.102-.988-1.657-2.968-1.657l-1.5.047c-1.145 0-2.067.094-2.766.281-.707.188-1.063.633-1.063 1.328 0 .586.313 1.008.938 1.266.617.25 1.383.375 2.297.375.398 0 .687-.008.875-.031h.265l.954.031c1.98 0 2.968-.547 2.968-1.64zm0 0"
        transform="translate(339.404 221.895)"
      ></path>
      <path
        fill={text8500Color}
        d="M.422-8.64c0 2.398-.586 4.308-1.766 5.734-1.176 1.43-2.922 2.14-5.234 2.14h-4.453V-7.03h2.562c1.024 0 1.824-.102 2.406-.313.586-.218.875-.648.875-1.297 0-.613-.195-1.03-.593-1.25-.395-.218-.977-.328-1.75-.328h-5.907c-.957 0-1.722.102-2.296.297-.57.2-.86.61-.86 1.235 0 1.148.828 1.718 2.485 1.718v5.5h-15.985V-15.78h5.625v8.61h4.235c-.395-.302-.72-.708-.97-1.22a3.924 3.924 0 01-.39-1.765c0-2.52.887-4.239 2.657-5.156 1.773-.915 4.28-1.376 7.53-1.376 2.68 0 4.856.196 6.532.579 1.668.375 2.969 1.148 3.906 2.312.93 1.168 1.39 2.887 1.39 5.156zm0 0"
        transform="translate(339.404 202.684)"
      ></path>
      <path
        fill={text8500Color}
        d="M.281-8.64c0 2.542-.719 4.5-2.156 5.874C-3.313-1.39-5.359-.703-8.015-.703h-13.579c-2.843 0-5.008-.66-6.5-1.985-1.5-1.332-2.25-3.316-2.25-5.953 0-2.664.75-4.66 2.25-5.984 1.492-1.332 3.657-2 6.5-2h13.578c2.688 0 4.746.688 6.172 2.063C-.426-13.188.281-11.212.281-8.642zm-5.531 0c0-.446-.203-.813-.61-1.094-.406-.282-.89-.422-1.452-.422h-14.844c-.864 0-1.52.101-1.969.297-.457.199-.688.605-.688 1.218 0 .606.231 1 .688 1.188.45.187 1.105.281 1.969.281h14.843c.563 0 1.047-.133 1.454-.406.406-.27.609-.625.609-1.063zm0 0"
        transform="translate(339.404 183.472)"
      ></path>
      <path
        fill={text8500Color}
        d="M.281-8.64c0 2.542-.719 4.5-2.156 5.874C-3.313-1.39-5.359-.703-8.015-.703h-13.579c-2.843 0-5.008-.66-6.5-1.985-1.5-1.332-2.25-3.316-2.25-5.953 0-2.664.75-4.66 2.25-5.984 1.492-1.332 3.657-2 6.5-2h13.578c2.688 0 4.746.688 6.172 2.063C-.426-13.188.281-11.212.281-8.642zm-5.531 0c0-.446-.203-.813-.61-1.094-.406-.282-.89-.422-1.452-.422h-14.844c-.864 0-1.52.101-1.969.297-.457.199-.688.605-.688 1.218 0 .606.231 1 .688 1.188.45.187 1.105.281 1.969.281h14.843c.563 0 1.047-.133 1.454-.406.406-.27.609-.625.609-1.063zm0 0"
        transform="translate(339.404 164.261)"
      ></path>
    </svg>
  );
}
