import {
  // NotificationType,
  NotificationTextViewModel,
  // UserAddedAsParticipantExtendedModel,
} from "./hooks/notification/notificationModels";

import React from "react";
import reactStringReplace from "react-string-replace";
// import moment from "moment";

export interface NotiticationTextProps {
  model: NotificationTextViewModel;
}
export function NotificationText({ model }: NotiticationTextProps) {
  var result = reactStringReplace(model.text, /#(.*?)#/g, (match, i) => {
    return <strong key={i}>{match}</strong>;
  });
  return <>{result}</>;

  // switch (model.type) {
  //   case NotificationType.UserAddedToClass:
  //     return ResolveUserAddedToClassNotificationText(model);

  //   default:
  //     return null;
  // }
}

// function ResolveUserAddedToClassNotificationText(model: NotificationTextViewModel) {
//   // var dataModel = model.data as UserAddedAsParticipantModel;
//   var extenedDataModel = model.extendedData as UserAddedAsParticipantExtendedModel;
//   if (!extenedDataModel) return null;
//   var start = moment(extenedDataModel.start);
//   return (
//     <>
//       {" "}
//       Du er på <strong>{extenedDataModel.className}</strong>{" "}
//       <strong>
//         {start.calendar(null, {
//           lastDay: "[I går]",
//           sameDay: "[I dag]",
//           nextDay: "[I morgen]",
//           lastWeek: "[sidste] dddd",
//           nextWeek: "[på] dddd",
//           sameElse: "Do MMMM",
//         })}
//       </strong>
//       {" kl. "}
//       <strong>{start.format("LT")}</strong>
//     </>
//   );
// }
