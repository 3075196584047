import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios"
import { ClassTypeCreateModel, ClassTypeViewModel } from "./classTypeModels";


export default function useCreateClassType() {
  return useMutationWithError<ClassTypeViewModel, unknown, ClassTypeCreateModel>(
    (data: ClassTypeCreateModel) => axios.post(`/api/classtype`, data),
    {
      onSuccess: () => {
        // Query Invalidations
        queryCache.invalidateQueries("classTypes");
      },
    }
  );
}
