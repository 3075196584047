// src/common/providers/APIErrorProvider/index.js
import React, { useEffect } from "react";
import {
  Capacitor
} from "@capacitor/core";
import {
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import useRegisterToken from "../hooks/notification/useRegisterToken";
import useRegisterDevice from "../hooks/notification/useRegisterDevice";
import useRegisterRequestedToSendNotifications from "../hooks/notification/useRegisterRequestedToSendNotifications";
import { Device } from "@capacitor/device";

export type PushNotificationProviderProps = {
  children: React.ReactNode;
};

export default function PushNotificationProvider({
  children,
}: PushNotificationProviderProps) {
  const [registerDeviceMutate] = useRegisterDevice();
  const [
    registerRequestedToSendNotificationsMutate,
  ] = useRegisterRequestedToSendNotifications();
  const [registerTokenMutate] = useRegisterToken();

  const register = () => {
    
    if (Capacitor.getPlatform() !== "web") {
      // On success, we should be able to receive notifications
      PushNotifications.addListener(
        "registration",
        (token: Token) => {

         // alert('Push registration success, token: ' + token.value);
        //  console.log('Push registration success, token: ' + token.value);
          Device.getId().then((info) => {
           // alert("devide id: " + info.identifier);
            registerTokenMutate({ token: token.value, deviceId: info.identifier });
            registerRequestedToSendNotificationsMutate({ deviceId: info.identifier });
          });
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        alert("Error on registration: " + JSON.stringify(error));
      });

      Device.getId().then((info) => {
       // alert("identifier: " + info.identifier)
        registerDeviceMutate({ deviceId: info.identifier }).then((r) => {
       //   alert("register device response: " + JSON.stringify(r));
          if (!r?.data?.requestedToSendNotifications) {
            PushNotifications.requestPermissions().then((result) => {
           ///   alert("permissions receive: " + result.receive )
           //   alert("result:   " +JSON.stringify(result))
              if (result.receive === "granted") {
                // Register with Apple / Google to receive push via APNS/FCM
             //   alert("calling register");
                PushNotifications.register();
              } else {
                registerRequestedToSendNotificationsMutate({
                  deviceId: info.identifier,
                });
                // Show some error
              }
            });
          }
        });
      });
    }
  }


  useEffect(register, []); // eslint-disable-line react-hooks/exhaustive-deps


  return <>{children}</>;
}
