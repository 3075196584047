import { useQuery } from "react-query";
import axios from "./../../axios"
import { FriendshipFeedModel } from "./friendshipModels";


const getClasses = async (_: any, friendUserId: string) => {
  const { data } = await axios.get(`/api/Friendship/GetUserFeed?friendUserId=${friendUserId}`);
  return data;
};

export default function useGetUserfeed(friendUserId: string, enabled: boolean = true) {
  return useQuery<FriendshipFeedModel[]>(["friendfeed", friendUserId], getClasses, {enabled:enabled});
}  
 