export const DASHBOARD : string = '/'
export const BOOKING : string = '/booking'
export const MYCLASSES : string = '/mine-hold'
export const CLASS : string = '/hold/:classId'
export const PROFILE : string = '/profil'
export const EDITPROFILE : string = '/profil/opdater'
export const MESSAGES : string = '/beskeder'
export const NOTIFICATIONSSETTINGS : string = '/beskeder-indstillinger'
export const DEFAULTCLASSES : string = '/standard-hold'
export const LOGIN : string = '/log-ind'
export const LOGOUT : string = '/log-ud'
export const USERS : string = '/medlemmer'
export const USER : string = '/medlemmer/:userId'
export const EDITUSER : string = '/medlemmer/:userId/opdater'
export const REGISTERUSER : string = '/opret-mig'
export const PRIVACYPOLICY : string = '/privacy-policy'
export const DELETEACCOUNT : string = '/delete-account'
export const REQUESTPASSWORDRESET : string = '/glemt-kodeord'
export const PASSWORDRESET : string = '/nulstil-kodeord'
export const SUPPORT : string = '/support'
export const CLASSTYPES : string = '/hold-typer'
export const CLASSSETUP : string = '/hold-opsætning'
export const CLASSSETUPDATE : string = '/hold-opsætning/:year-:month-:day'
export const FRIENDS : string = '/venner/vis'
export const FINDFRIENDS : string = '/venner/find-nye'
export const FRIENDSACTIVITY : string = '/venner'
