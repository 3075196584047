import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";
import { ClassUpdateSlotsWriteModel, ClassViewModel } from "./classModels";

export default function useUpdateClassSlots() {
  return useMutationWithError<
    ClassViewModel,
    unknown,
    ClassUpdateSlotsWriteModel
  >(
    (data: ClassUpdateSlotsWriteModel) =>
      axios.post(`/api/class/updateslots`, data),
    {
      onSuccess: (data: any, createData) => {
        // Query Invalidations
        var converted = new Date(data.data.start);
      
        // console.log(date)
        queryCache.invalidateQueries(["classes", `${converted.getFullYear()}-${converted.getMonth() + 1}-${converted.getDate()}`]);
      },
    }
  );
}
