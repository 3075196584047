import { useQuery } from "react-query";
import axios from "./../../axios";
import { BookingViewModel } from "./bookingModels";

const getBookings = async (_: any, userId: string, membershipId: string) => {
  const { data } = await axios.get(
    `/api/booking/GetByUserIdAndMembershipId?userId=${userId}&membershipId=${membershipId}`
  );
  return data;
};

export default function useBookingsByUserIdAndMembershipId(
  userid: string,
  membershipId: string,
  enabled: boolean = true
) {
  return useQuery<BookingViewModel[]>(
    ["bookingsByMembershipId", userid, membershipId],
    getBookings,
    { enabled: enabled }
  );
}
