import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export default function useMarkAllNotificationsAsSeen() {
  return useMutationWithError<string, unknown>(
    () => {
     
      return axios.post(`/api/notification/MarkAllAsSeen`, {});
    },
    {
      onSuccess: (data, notificationId) => {
        // Query Invalidations
        // queryCache.invalidateQueries(["bookingsByClassId", classId]);
       // alert("success")
      },
      onError: () => {
        // Query Invalidations
        // queryCache.invalidateQueries(["bookingsByClassId", classId]);
    ///    alert("error")
      },
    }
  );

  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
