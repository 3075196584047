import { useQuery } from "react-query";
import axios from "../../axios"


const getBookings = async (_: any) => {
  const { data } = await axios.get(`/api/booking/GetMaxActiveUserBookings`);
  return data;
};

export default function useMaxActiveUserBookings() {
  return useQuery<number>(["maxActiveBookings"], getBookings);
}
