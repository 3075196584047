import React from "react";
import useMe from "../hooks/account/useMe";
import { AxiosError } from "axios";
import { ErrorOption, FieldName } from "react-hook-form";
import { useHistory } from "react-router-dom";
import EditUserDetails from "../components/EditUserDetails";
import { addServerErrors } from "../components/ErrorSummary";
import {
  UpdateWriteModel,
  EmailSettingsWriteModel,
} from "../hooks/account/accountModels";
import { PROFILE } from "../routes";
import useUpdateAccount from "../hooks/account/useUpdateAccount";

import { Box } from "../components/Box";
import { PageHeader } from "../components/PageHeader";
import { PageContent } from "../components/PageContent";

import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";
import EditUserEmailNotificationSettings from "../components/EditUserEmailNotificationSettings";
import useSetEmailSettings from "../hooks/account/useSetEmailSettings";
export default function EditProfile() {
  // let match = useRouteMatch();
  const { data } = useMe();
  const history = useHistory();
  const [updateAccountMutate] = useUpdateAccount();
  const [setEmailSettingsMutate] = useSetEmailSettings();
  const handleCancelEdit = () => {
    history.push(PROFILE);
  };

  const handleSubmitEdit = (
    data: UpdateWriteModel,
    setError: (name: FieldName<UpdateWriteModel>, error: ErrorOption) => void
  ) => {
    updateAccountMutate(data)
      .then(() => {
        // alert("done");
        history.push(PROFILE);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };

  const handleSubmitEdit2 = (
    data: EmailSettingsWriteModel,
    setError: (
      name: FieldName<EmailSettingsWriteModel>,
      error: ErrorOption
    ) => void
  ) => {
    setEmailSettingsMutate(data)
      .then(() => {
        // alert("done");
        history.push(PROFILE);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };
  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.large}>
      <PageHeader backButton={true}>Ret personlige oplysninger</PageHeader>
      <PageContent>
        <Box>
          {data && (
            <EditUserDetails
              user={data}
              handleCancelEdit={handleCancelEdit}
              handleSubmitEdit={handleSubmitEdit}
            />
          )}
        </Box>
        <Box>
          {data && (
            <EditUserEmailNotificationSettings
              user={data}
              handleCancelEdit={handleCancelEdit}
              handleSubmitEdit={handleSubmitEdit2}
            />
          )}
        </Box>
      </PageContent>
    </PageContainer>
  );
}
