// src/common/providers/APIErrorProvider/index.js
import React, { useState, useCallback } from "react";

export type APIError = {
  error: ErrorMessage | null;
  addError: (message: string, status: string) => void;
  removeError: () => void;
};

export const APIErrorContext = React.createContext<APIError>({
  error: null,
  addError: () => {},
  removeError: () => {},
});

export interface ErrorMessage {
  message: string;
  status: string;
}

export type APIErrorProviderProps = {
  children: React.ReactNode;
};

export default function APIErrorProvider({ children }: APIErrorProviderProps) {
  const [error, setError] = useState<ErrorMessage | null>(null);

  const removeError = () => setError(null);

  const addError = (message: string, status: string) =>
    setError({ message, status });

  const contextValue = {
    error,
    addError: useCallback((message, status) => addError(message, status), []),
    removeError: useCallback(() => removeError(), []),
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
}
