import React from "react";

export default function DashboardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        fill="#868685"
        d="M494.4 230.65C506.33 381.98 381 507.31 229.67 495.4 110.63 486.03 14.17 389.61 4.76 270.57-7.21 119.23 118.14-6.16 269.5 5.77c119.05 9.38 215.51 105.83 224.9 224.88z"
      />
      <path
        fill="#FFF"
        d="M386.2 228.23l-42.87-31.94v-62.87h-52.31v23.89l-35.59-26.52a13.25 13.25 0 00-7.94-2.63c-2.8 0-5.58.88-7.94 2.63l-130.76 97.44a13.27 13.27 0 00-4.67 14.83c1.8 5.44 6.88 9.11 12.61 9.11h19.48V371.2h79.09v-97.37h64.38v97.37h79.1V252.17h19.48c5.73 0 10.81-3.67 12.61-9.11a13.27 13.27 0 00-4.67-14.83z"
      />
    </svg>
  );
}
