import React from "react";
import styled from "styled-components";
import { HighlightColor, HighlightModel } from "../hooks/highlightModel";

// const useStyles = makeStyles<Theme, Props>({
//   root: {
//     backgroundColor: props => GetColor(props.color),
//   },
// });

function GetColor(color: HighlightColor) {
  switch (color) {
    case HighlightColor.Red:
      return "var(--red)";
    case HighlightColor.Blue:
      return "var(--blue)";
    case HighlightColor.Green:
      return "var(--green)";
    case HighlightColor.Black:
      return "var(--black)";
    case HighlightColor.Grey1:
      return "var(--grey1)";
    case HighlightColor.Grey2:
      return "var(--grey2)";
    case HighlightColor.Grey3:
      return "var(--grey3)";
  }
}

function GetTextColor(color: HighlightColor) {
  switch (color) {
    case HighlightColor.Green:
    case HighlightColor.Grey3:
      return "var(--black)";
    case HighlightColor.Red:
    case HighlightColor.Black:
    case HighlightColor.Grey1:
    case HighlightColor.Grey2:
    case HighlightColor.Blue:
      return "var(--white)";
  }
}

export type HighlightChipProps = {
  hcolor: HighlightColor;
};

export type DayStyledProps = {
  active: boolean;
  hcolor: HighlightColor;
};
const HighlightChip = styled.span<HighlightChipProps>`
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  font-size: 10px;
  margin-right: 10px;
  background-color: ${(props) => GetColor(props.hcolor)};
  color: ${(props) => GetTextColor(props.hcolor)};
  display: inline-block;
`;

const Highlight = ({ text, color }: HighlightModel) => (
  <HighlightChip hcolor={color}>{text}</HighlightChip>
);

export default Highlight;
