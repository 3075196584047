import { AxiosError } from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import ErrorSummary, { addServerErrors } from "../components/ErrorSummary";
import {
  DayOfWeekFlag,
  DefaultClassCreateModel,
  DefaultClassUpdateModel,
  DefaultClassViewModel,
  WeekFlag,
} from "../hooks/defaultClasses/defaultClassModels";
import useCreateDefaultClass from "../hooks/defaultClasses/useCreateDefaultClass";
import useDefaultClasses from "../hooks/defaultClasses/useDefaultClasses";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import useClassTypes from "../hooks/classTypes/useClassTypes";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import useDeleteDefaultClass from "../hooks/defaultClasses/useDeleteDefaultClass";
import useUpdateDefaultClass from "../hooks/defaultClasses/useUpdateDefaultClass";
import { PageContainer } from "../components/PageContainer";
import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";
import { Box, BoxHeader } from "../components/Box";
import TextInput from "../components/form/TextInput";
import Dropdown from "../components/form/Dropdown";
import ButtonAligner from "../components/ButtonAligner";
const names = [
  { text: "Mandag", value: DayOfWeekFlag.Monday },
  { text: "Tirsdag", value: DayOfWeekFlag.Tuesday },
  { text: "Onsdag", value: DayOfWeekFlag.Wednesday },
  { text: "Torsdag", value: DayOfWeekFlag.Thursday },
  { text: "Fredag", value: DayOfWeekFlag.Friday },
  { text: "Lørdag", value: DayOfWeekFlag.Saturday },
  { text: "Søndag", value: DayOfWeekFlag.Sunday },
];
const weekOptions = [
  { text: "Lige", value: WeekFlag.Even },
  { text: "Ulige", value: WeekFlag.Odd },
];
export default function DefaultClasses() {
  const defaultClasses = useDefaultClasses();
  const [createMutate] = useCreateDefaultClass();

  const [open, setOpen] = React.useState(false);
  const {
    handleSubmit,
    errors,
    setError,
    setValue,
    control,
  } = useForm<DefaultClassCreateModel>();
  const onSubmit = (data: DefaultClassCreateModel) => {
    createMutate(data)
      .then(() => {
        setOpen(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };
  const [personName, setPersonName] = React.useState<DayOfWeekFlag[]>([]);
  const [weeks, setWeeks] = React.useState<WeekFlag[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as DayOfWeekFlag[]);
  };

  const handleWeekChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setWeeks(event.target.value as WeekFlag[]);
  };

  var groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[key(x)] = rv[key(x)] || []).push(x);
      return rv;
    }, {});
  };
  const classTypes = useClassTypes();

  var grouped =
    defaultClasses &&
    defaultClasses.data &&
    groupBy(defaultClasses.data, function (x: DefaultClassViewModel) {
      return x.classTypeName;
    });

  return (
    <PageContainer>
      <PageHeader backButton={false}>
        Hold skema{defaultClasses.isLoading && <div>Loader</div>}
      </PageHeader>
      <PageContent>
        {grouped &&
          Object.entries<any[]>(grouped).map(([key, value]) => {
            return (
              <Box key={key}>
                <BoxHeader>{key}</BoxHeader>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Start</TableCell>
                        <TableCell>Slut</TableCell>
                        <TableCell>Pladser</TableCell>
                        <TableCell>Dage</TableCell>
                        <TableCell>Uger</TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {value.map((x) => {
                        return (
                          <DefaultClassRow
                            model={x as DefaultClassViewModel}
                            key={(x as DefaultClassViewModel).id}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            );
          })}
        <Box>
          <ButtonAligner>
            <CustomButton onClick={() => setOpen(true)}>
              Opret nyt standard hold
            </CustomButton>
          </ButtonAligner>
        </Box>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Opret nyt standard hold
          </DialogTitle>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText>Opret nyt standard hold</DialogContentText>

              <Dropdown
                label="Hold type"
                name="classTypeId"
                control={control}
                multiple={false}
                fullWidth={true}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  var values = event.target.value as string;
                  setValue("classTypeId", values);
                }}
              >
                {classTypes.data &&
                  classTypes.data.map((x) => {
                    return (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
              </Dropdown>

              <TextInput
                label="Starttidspunkt"
                name="start"
                type="time"
                control={control}
                fullWidth={true}
                defaultValue={"10:00"}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <TextInput
                label="Sluttidspunkt"
                name="end"
                type="time"
                control={control}
                fullWidth={true}
                defaultValue={"11:00"}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <TextInput
                label="Antal pladser"
                name="slots"
                control={control}
                fullWidth={true}
                defaultValue={"10"}
              />

              <Dropdown
                label="Ugedage"
                name="daysOfWeek"
                multiple={true}
                control={control}
                fullWidth={true}
                value={personName}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  // setMarket(event.target.value as string[]);
                  handleChange(event);
                  var values = event.target.value as DayOfWeekFlag[];
                  var days = values.reduce(function (a, b) {
                    return a + b;
                  });
                  setValue("daysOfWeek", days);
                }}
                renderValue={(selected) => {
                  return (selected as DayOfWeekFlag[])
                    .sort((a, b) => a - b)
                    .map((x) => names.find((y) => y.value === x)?.text)
                    .join(", ");
                }}
              >
                {names.map((name) => (
                  <MenuItem key={name.value} value={name.value}>
                    <Checkbox checked={personName.indexOf(name.value) > -1} />
                    <ListItemText primary={name.text} />
                  </MenuItem>
                ))}
              </Dropdown>

              <Dropdown
                label="Uger"
                name="weeks"
                multiple={true}
                control={control}
                fullWidth={true}
                value={weeks}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  // setMarket(event.target.value as string[]);
                  handleWeekChange(event);
                  var values = event.target.value as WeekFlag[];
                  var days = values.reduce(function (a, b) {
                    return a + b;
                  });
                  setValue("weeks", days);
                }}
                renderValue={(selected) => {
                  return (selected as WeekFlag[])
                    .sort((a, b) => a - b)
                    .map((x) => weekOptions.find((y) => y.value === x)?.text)
                    .join(", ");
                }}
              >
                {weekOptions.map((name) => (
                  <MenuItem key={name.value} value={name.value}>
                    <Checkbox checked={weeks.indexOf(name.value) > -1} />
                    <ListItemText primary={name.text} />
                  </MenuItem>
                ))}
              </Dropdown>

              <ErrorSummary errors={errors} />
            </DialogContent>
            <DialogActions>
              <CustomButton
                onClick={() => {
                  setOpen(false);
                }}
                color="primary"
              >
                Annuller
              </CustomButton>
              <CustomButton
                type="submit"
                color="primary"
                // disabled={accountToAdd === null}
              >
                Gem
              </CustomButton>
            </DialogActions>
          </form>
        </Dialog>
      </PageContent>
    </PageContainer>
  );
}

interface DefaultClassRowProps {
  model: DefaultClassViewModel;
}
function DefaultClassRow({ model }: DefaultClassRowProps) {
  const row = model;

  const [open, setOpen] = React.useState(false);
  const [updateMutate] = useUpdateDefaultClass();
  const [deleteMutate] = useDeleteDefaultClass();
  const handleDelete = (defaultClassId: string) => {
    deleteMutate(defaultClassId);
  };

  const {
    handleSubmit,
    errors,
    setError,
    setValue,
    control,
  } = useForm<DefaultClassUpdateModel>();
  const onSubmit = (data: DefaultClassUpdateModel) => {
    data.defaultClassId = model.id;
    updateMutate(data)
      .then(() => {
        setOpen(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };
  let days: string[] = [];
  let defaultDays: DayOfWeekFlag[] = [];
  if ((row.daysOfWeek & DayOfWeekFlag.Monday) === DayOfWeekFlag.Monday) {
    days.push("mandag");
    defaultDays.push(DayOfWeekFlag.Monday);
  }
  if ((row.daysOfWeek & DayOfWeekFlag.Tuesday) === DayOfWeekFlag.Tuesday) {
    days.push("tirsdag");
    defaultDays.push(DayOfWeekFlag.Tuesday);
  }
  if ((row.daysOfWeek & DayOfWeekFlag.Wednesday) === DayOfWeekFlag.Wednesday) {
    days.push("onsdag");
    defaultDays.push(DayOfWeekFlag.Wednesday);
  }
  if ((row.daysOfWeek & DayOfWeekFlag.Thursday) === DayOfWeekFlag.Thursday) {
    days.push("torsdag");
    defaultDays.push(DayOfWeekFlag.Thursday);
  }
  if ((row.daysOfWeek & DayOfWeekFlag.Friday) === DayOfWeekFlag.Friday) {
    days.push("fredag");
    defaultDays.push(DayOfWeekFlag.Friday);
  }
  if ((row.daysOfWeek & DayOfWeekFlag.Saturday) === DayOfWeekFlag.Saturday) {
    days.push("lørdag");
    defaultDays.push(DayOfWeekFlag.Saturday);
  }
  if ((row.daysOfWeek & DayOfWeekFlag.Sunday) === DayOfWeekFlag.Sunday) {
    days.push("søndag");
    defaultDays.push(DayOfWeekFlag.Sunday);
  }
  const defaultWeeks: WeekFlag[] = [];

  if ((row.weeks & WeekFlag.Even) === WeekFlag.Even) {
    defaultWeeks.push(WeekFlag.Even);
  }
  if ((row.weeks & WeekFlag.Odd) === WeekFlag.Odd) {
    defaultWeeks.push(WeekFlag.Odd);
  }

  const [personName, setPersonName] = React.useState<DayOfWeekFlag[]>(
    defaultDays
  );
  const [weeks, setWeeks] = React.useState<WeekFlag[]>(defaultWeeks);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as DayOfWeekFlag[]);
  };

  const handleWeekChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setWeeks(event.target.value as WeekFlag[]);
  };

  const classTypes = useClassTypes();

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>
          {row.start.getUTCHours().toString().padStart(2, "0") +
            ":" +
            row.start.getUTCMinutes().toString().padStart(2, "0")}
        </TableCell>
        <TableCell>
          {row.end.getUTCHours().toString().padStart(2, "0") +
            ":" +
            row.end.getUTCMinutes().toString().padStart(2, "0")}
        </TableCell>
        <TableCell>{row.slots}</TableCell>
        <TableCell>{days.join(", ")}</TableCell>
        <TableCell>
          {(row.weeks & WeekFlag.Even) === WeekFlag.Even &&
            (row.weeks & WeekFlag.Odd) === WeekFlag.Odd && <span>Alle</span>}
          {(row.weeks & WeekFlag.Even) !== WeekFlag.Even &&
            (row.weeks & WeekFlag.Odd) !== WeekFlag.Odd && <span>Ingen</span>}
          {(row.weeks & WeekFlag.Even) === WeekFlag.Even &&
            (row.weeks & WeekFlag.Odd) !== WeekFlag.Odd && <span>Lige</span>}
          {(row.weeks & WeekFlag.Even) !== WeekFlag.Even &&
            (row.weeks & WeekFlag.Odd) === WeekFlag.Odd && <span>Ulige</span>}
        </TableCell>
        <TableCell>
          <CustomButton onClick={() => setOpen(true)}>Ret</CustomButton>
          <CustomButton
            themecolor={ThemeColor.Black}
            onClick={() => {
              if (window.confirm("Er du sikker på du vil fjerne holdet?"))
                handleDelete(row.id);
            }}
          >
            Fjern
          </CustomButton>
        </TableCell>
      </TableRow>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Ret </DialogTitle>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>Ret </DialogContentText>

            <Dropdown
              label="Hold type"
              name="classTypeId"
              control={control}
              fullWidth={true}
              multiple={false}
              defaultValue={model.classTypeId}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                var values = event.target.value as string;
                setValue("classTypeId", values);
              }}
            >
              {classTypes.data &&
                classTypes.data.map((x) => {
                  return (
                    <MenuItem
                      key={x.id}
                      value={x.id}
                      selected={x.id === model.classTypeId}
                    >
                      {x.name}
                    </MenuItem>
                  );
                })}
            </Dropdown>

            <TextInput
              label="Starttidspunkt"
              name="start"
              type="time"
              control={control}
              fullWidth={true}
              defaultValue={
                row.start.getUTCHours().toString().padStart(2, "0") +
                ":" +
                row.start.getUTCMinutes().toString().padStart(2, "0")
              }
              inputProps={{
                step: 300, // 5 min
              }}
            />
            <TextInput
              label="Sluttidspunkt"
              name="end"
              type="time"
              control={control}
              fullWidth={true}
              defaultValue={
                row.end.getUTCHours().toString().padStart(2, "0") +
                ":" +
                row.end.getUTCMinutes().toString().padStart(2, "0")
              }
              inputProps={{
                step: 300, // 5 min
              }}
            />
            <TextInput
              label="Antal pladser"
              name="slots"
              control={control}
              fullWidth={true}
              defaultValue={row.slots.toString()}
            />

            <Dropdown
              label="Ugedage"
              name="daysOfWeek"
              multiple={true}
              control={control}
              fullWidth={true}
              value={personName}
              defaultValue={
                personName.length
                  ? personName.reduce(function (a, b) {
                      return a + b;
                    })
                  : []
              }
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                // setMarket(event.target.value as string[]);
                handleChange(event);
                var values = event.target.value as DayOfWeekFlag[];
                var days = values.reduce(function (a, b) {
                  return a + b;
                });
                setValue("daysOfWeek", days);
              }}
              renderValue={(selected) => {
                return (selected as DayOfWeekFlag[])
                  .sort((a, b) => a - b)
                  .map((x) => names.find((y) => y.value === x)?.text)
                  .join(", ");
              }}
            >
              {names.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox checked={personName.indexOf(name.value) > -1} />
                  <ListItemText primary={name.text} />
                </MenuItem>
              ))}
            </Dropdown>

            <Dropdown
              label="Uger"
              name="weeks"
              multiple={true}
              control={control}
              fullWidth={true}
              value={weeks}
              defaultValue={
                weeks.length
                  ? weeks.reduce(function (a, b) {
                      return a + b;
                    })
                  : []
              }
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                // setMarket(event.target.value as string[]);
                handleWeekChange(event);
                var values = event.target.value as WeekFlag[];
                var days = values.reduce(function (a, b) {
                  return a + b;
                });
                setValue("weeks", days);
              }}
              renderValue={(selected) => {
                return (selected as WeekFlag[])
                  .sort((a, b) => a - b)
                  .map((x) => weekOptions.find((y) => y.value === x)?.text)
                  .join(", ");
              }}
            >
              {weekOptions.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox checked={weeks.indexOf(name.value) > -1} />
                  <ListItemText primary={name.text} />
                </MenuItem>
              ))}
            </Dropdown>
            <ErrorSummary errors={errors} />
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => {
                setOpen(false);
              }}
              color="primary"
            >
              Annuller
            </CustomButton>
            <CustomButton
              type="submit"
              color="primary"
              // disabled={accountToAdd === null}
            >
              Gem
            </CustomButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
