import React from "react";
import { FullAccountViewModel } from "../hooks/account/accountModels";
import { Box, BoxHeader } from "./Box";

export type UserDetailsProps = {
  user: FullAccountViewModel;
  children: React.ReactNode;
};
const UserDetails = ({ user, children }: UserDetailsProps) => {
  return (
    <>
      {user && (
        <Box>
          <BoxHeader>Personlige oplysninger</BoxHeader>

          <div>
            <strong>NAVN</strong>
            <br />
            {user.firstName} {user.lastName}
          </div>
          <div>
            <strong>ADRESSE</strong>
            <br />
            {user.address}, {user.zipCode} {user.city}
          </div>
          <div>
            <strong>TELEFON</strong>
            <br />
            {user.phone}
          </div>
          <div>
            <strong>EMAIL ADRESSE</strong>
            <br />
            {user.email}
          </div>
          <br />
          {children}
        </Box>
      )}
    </>
  );
};

export default UserDetails;
