import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export default function useMarkNotificationAsRead() {
  return useMutationWithError<boolean, unknown, string[]>(
    (notificationIds) => {
      notificationIds = [...notificationIds];
      return axios.post(`/api/notification/MarkAsRead`, {
        notificationIds: notificationIds,
      });
    },
    {
      onSuccess: (data, notificationId) => {
        // Query Invalidations
        // queryCache.invalidateQueries(["bookingsByClassId", classId]);
      },
    }
  );

  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
