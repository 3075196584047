import React from "react";
import { AccountWithHightlightsViewModel } from "../hooks/account/accountModels";

import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { USER } from "../routes";
import CustomButton from "./CustomButton";
import Highlight from "./Highlight";
const UserPictureContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
`;
const UserPicture = styled.img`
  width: 40px;
  border-radius: 50%;
`;

const Hello = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey3);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;
const ClassContainer = styled.div`
  float: left;
  margin-bottom: 10px;
`;
const NameAndActionContainer = styled.div`
  flex-grow: 1;
`;
const ButtonContainer = styled.div`
  display: inline-flex;
  float: right;
  align-items: center;
  & ${CustomButton} {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
const Position = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-right: 5px;
`;
const HightLightContainer = styled.span``;
const NameContainer = styled.span`
  padding-right: 5px;
`;
export type UserDetailsProps = {
  user: AccountWithHightlightsViewModel;
  position?: number | null;
  children?: React.ReactNode;
};
export default function UserLine({
  position,
  user,
  children,
}: UserDetailsProps) {
  return (
    <Hello>
      {position && <Position>#{position}</Position>}
      <UserPictureContainer>
        <UserPicture src={user.picture} alt={user.firstName} />
      </UserPictureContainer>
      <NameAndActionContainer>
        <ClassContainer>
          <NameContainer>
            <NavLink to={USER.replace(":userId", user.id)}>
              {user.firstName} {user.lastName}
            </NavLink>
          </NameContainer>
          {user.highlights && (
            <HightLightContainer>
              {user.highlights.map((h) => {
                return <Highlight color={h.color} text={h.text} key={h.text} />;
              })}
            </HightLightContainer>
          )}
        </ClassContainer>
        <ButtonContainer>{children}</ButtonContainer>
      </NameAndActionContainer>
    </Hello>
  );
}
