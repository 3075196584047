import React from "react";
import styled from "styled-components";
import Logo from "../../components/Logo";
import { BoxHeader } from "../../components/Box";

const LogoHolder = styled.div`
  padding-bottom: 40px;
`;

export default function PrivacyPolicy() {

  return (
    <>
      <LogoHolder> 
        <Logo forbackground={false} />
      </LogoHolder>
      <BoxHeader>1.Informationsindsamling</BoxHeader>
      <p>
        Vi indsamler information fra dig, når du registrerer dig på vores side,
        logger ind på din konto, foretager køb, tilmelder dig konkurrencer,
        og/eller når du logger ud. De indsamlede informationer inkluderer dit
        navn, e-mailadresse, telefonnummer, og/eller kreditkort. Dertil kommer,
        at vi automatisk modtager og optager informationer fra din computer og
        browser, inkluderet din IP adresse, software og hardware egenskaber, og
        siden du forespørger.
      </p>
      <BoxHeader>2. Informationsbrug</BoxHeader>
      <p>
        Enhver af informationerne vi indsamler fra dig, kan blive anvendt til:
        <ul>
          <li>
            Personalisere din oplevelse og reagere på dine individuelle behov
          </li>
          <li>Give brugerdefineret reklameindhold</li>
          <li>Forbedre vores webside</li>
          <li>Forbedre kundeservice og dine support behov</li>
          <li>Kontakte dig via e-mail</li>
          <li>Forvalte en konkurrence, kampagne eller undersøgelse</li>
        </ul>
      </p>
      <BoxHeader>3. E-handels fortrolighed</BoxHeader>
      <p>
        Vi er ene ejere af informationerne indsamlet på denne side. Dine
        personlige identificerbare informationer vil ikke blive solgt,
        udvekslet, overført eller givet til nogen anden virksomhed af nogen
        grund overhovedet, uden din accept, udover når nødvendigt for at
        gennemføre en forespørgsel og/eller transaktion, eksempelvis forsendelse
        af en ordre.
      </p>
      <BoxHeader>4. Tredje part aftale</BoxHeader>
      <p>
        Vi hverken sælger, handler eller på anden vis overfører dine personligt
        identificerbare informationer til ekstern part. Dette inkluderer ikke
        betroet 3de parts, som bistår os i håndteringen af vores webside eller
        udfører vores forretning, så længe disse accepterer at holde disse
        informationer fortrolige. Vi mener det er nødvendigt at dele
        informationer, for at kunne undersøge, undgå eller tage skridt i
        forbindelse med illegale aktiviteter, mistanke om bedrageri, situationer
        som involverer potentiel trussel mod den fysiske sikkerhed af enhver
        person, brud på vores brugerbetingelser, eller på anden vis påkrævet ved
        lov. Ikke private informationer kan derimod videregives til andre parter
        til brug for marketing, reklame eller andet.
      </p>
      <BoxHeader>5. Informationsbeskyttelse</BoxHeader>
      <p>
        Vi implementerer en række sikkerhedsforanstaltninger, for at fastholde
        sikkerheden af dine personlige informationer. Vi bruge state-of-the-art
        kryptering til beskyttelse af følsomme informationer transmitteret
        online. VpnMentor beskytter også dine informationer offline. Kun
        ansatte, som skal udføre specifikke jobs (eksempelvis fakturering eller
        kundeservice) gives adgang til personligt identificerbare informationer.
        Computerne/serverne som anvendes til at opbevare personligt
        identificerbare informationer, opbevares i et sikkert miljø. Anvender vi
        cookies? Ja. Vores cookies forbedrer adgang til vores side og
        identificerer gentagne besøgende. Dertil kommer at vores cookies øger en
        brugers oplevelse ved at spore og målrette hans/hendes interesser. Denne
        cookie brug, er dog på ingen måde forbundet til nogen personligt
        identificerbare informationer på vores side.
      </p>
      <BoxHeader>6. Afmeld</BoxHeader>
      <p>
        Vi bruger e-mailadressen som du giver, til at sende dig informationer og
        opdateringer vedrørende din ordre, lejlighedsvise virksomhedsnyheder,
        relateret produktinformation, osv. Hvis du på noget tidspunkt ønsker at
        afmelde dig modtagelse af fremtidige e-mails, så inkluderer vi
        detaljeret instruktioner til afmeldelse i bunden af hver e-mail.
      </p>
      <BoxHeader>7. Samtykke</BoxHeader>
      <p>
        Ved brug af vores side, giver du samtykke til vores
        fortrolighedspolitik.
      </p>
    </>
  );
}
