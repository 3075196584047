import { useQuery } from "react-query";
import axios from "../../axios"


const getClassTypeNameWhichDoesNotOccupyBookingSlot = async (_: any) => {
  const { data } = await axios.get(`/api/booking/GetClassTypeNameWhichDoesNotOccupyBookingSlot`);
  return data;
};

export default function useClassTypeNameWhichDoesNotOccupyBookingSlot() {
  return useQuery<string[]>(["ClassTypeNameWhichDoesNotOccupyBookingSlot"], getClassTypeNameWhichDoesNotOccupyBookingSlot);
}
