import { useQuery } from "react-query";
import axios from "./../../axios"
import { FriendshipViewModel } from "./friendshipModels";


const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/Friendship/GetRequested`);
  return data;
};

export default function useGetRequested() {
  return useQuery<FriendshipViewModel[]>(["friendrequested"], getClasses);
}
