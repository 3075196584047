import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { EmailSettingsWriteModelById } from "./accountModels";

export default function useUpdateAccountById() {
  return useMutationForForms<string, unknown, EmailSettingsWriteModelById>(
    (data: EmailSettingsWriteModelById) =>
      axios.post(`/api/account/SetEmailSettingsByUserId?userId=${data.userId}`, data),
    {
      onSuccess: (data, updateData) => {
        queryCache.invalidateQueries(["account-get-full", updateData.userId]);
      }
    }
  );
}
