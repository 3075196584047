// src/components/APIErrorNotification/index.js
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert/Alert";
import React from "react";
import useAPIError from "../hooks/useAPIError";

export default function APIErrorNotification() {
  const { error, removeError } = useAPIError();

  const handleSubmit = () => {
    removeError();
  };

  return (
    <Snackbar
      open={!!error}
      autoHideDuration={6000}
      onClose={handleSubmit}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleSubmit} severity="error">
        {error && error.message && error.message}
      </Alert>
    </Snackbar>
  );
}
