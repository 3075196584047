import { useQuery } from "react-query";
import { PagedResultModel } from "../pagedResultModel";
import axios from "./../../axios"
import {  AccountWithHightlightsViewModel } from "./accountModels";


const getGet = async (_: any, page: number, pageSize: number, query: string) => {

  const { data } = await axios.get<PagedResultModel<AccountWithHightlightsViewModel>>(`/api/account/get`, { params: { page, pageSize, query } });
  return data;
};

export default function useGet(page: number, pageSize: number, query: string) {
  return useQuery(["account-get", page, pageSize, query], getGet);
}
