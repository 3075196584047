import { useQuery } from "react-query";
import axios from "./../../axios"
import {  AccountViewModel } from "./accountModels";

const getGet = async (_: any, query: string) => {
  const { data } = await axios.get<AccountViewModel[]>(`/api/account/autoComplete`, { params: {  query } });
  return data;
};

export default function useAutoComplete(query: string) {
  return useQuery(["account-autoComplete", query], getGet);
}
