import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export default function useRequestFriendship() {
  return useMutationWithError<unknown, unknown, string>(
    (receiver: string) => axios.post(`/api/Friendship/SendRequest?receiver=${receiver}`),
    {
      onSuccess: (data, receiver) => {
        // Query Invalidations
        queryCache.invalidateQueries(["friendrequested"]);
      }
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}