export interface DefaultClassViewModel {
  classTypeName: string;
  id: string;
  classTypeId: string;
  start: Date;
  end: Date;
  slots: number;
  daysOfWeek: DayOfWeekFlag;
  weeks: WeekFlag;
}

export interface DefaultClassCreateModel {
  classTypeId: string;
  start: Date;
  end: Date;
  slots: number;
  daysOfWeek: DayOfWeekFlag;
  weeks: WeekFlag;
}


export interface DefaultClassUpdateModel extends DefaultClassCreateModel {
  defaultClassId: string;
}

export enum DayOfWeekFlag {
  None = 0,
  Monday = 1 << 0,
  Tuesday = 1 << 1,
  Wednesday = 1 << 2,
  Thursday = 1 << 3,
  Friday = 1 << 4,
  Saturday = 1 << 5,
  Sunday = 1 << 6,
}

export enum WeekFlag {
  None = 0,
  Odd = 1 << 0,
  Even = 1 << 1,
}
