import { AxiosResponse } from "axios";
import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { RegisterModel } from "./accountModels";

export default function useRegisterAccount() {
  return useMutationForForms<AxiosResponse<string>, unknown, RegisterModel>(
    (data: RegisterModel) => axios.post(`/api/account/register`, data),
    {
      onSuccess: (data, updateData) => {
        
        queryCache.invalidateQueries(["me"]);
      },
    }
  );
}
