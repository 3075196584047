import { AccountWithHightlightsViewModel } from "../account/accountModels";

export interface BookingViewModel {
  classId: string;
  userId: string;
  membershipId: string;
  state: BookingState;
  position: number;
  occupyActiveBookingSlot: boolean;
}

export interface BookingFullViewModel extends BookingViewModel {
  user: AccountWithHightlightsViewModel; 
}

export enum BookingState  {
  Participating,
  WaitingList,
  NoShow
}


export interface NoShowMonthModel {
  month: string;
  users: NoShowUserModel[];
}

export interface NoShowUserModel {
  userId: string;
  count: number;
}

export interface HistoryEntry {
 issuedByUserId?: string;
 issuedByUserName?: string;

  userId?: string;
  userName?: string;

  text: number;
  timeOfEvent: Date;
}