import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";
import { DeleteClassModel } from "./classModels";

export default function useDeleteClass() {
  return useMutationWithError<boolean, unknown, DeleteClassModel>(
    ({ classId }) => axios.delete(`/api/class/${classId}`),
    {
      onSuccess: (data, payload) => {
        // Query Invalidations
        var converted = new Date(payload.date);
    
        queryCache.invalidateQueries(["classes", `${converted.getFullYear()}-${converted.getMonth() + 1}-${converted.getDate()}`]);
      },
    }
  );
}
