import moment from "moment";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { PageContainer } from "../components/PageContainer";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import Highlight from "../components/Highlight";
import { PageHeader } from "../components/PageHeader";
import { BookingState, BookingViewModel } from "../hooks/booking/bookingModels";
import useActiveUserBookings from "../hooks/booking/useActiveUserBookings";
import useBookClass from "../hooks/booking/useBookClass";
import useRemoveBookingFromClass from "../hooks/booking/useRemoveBookingFromClass";
import useMaxActiveUserBookings from "../hooks/booking/useMaxActiveUserBookings";
import { ClassViewModel } from "../hooks/class/classModels";
import useClasses from "../hooks/class/useClasses";
import { HighlightColor } from "../hooks/highlightModel";
import { PageContent } from "../components/PageContent";
import { AccountViewModel } from "../hooks/account/accountModels";
import { NavLink } from "react-router-dom";
import { CLASS, USER } from "../routes";
import useClassTypeNameWhichDoesNotOccupyBookingSlot from "../hooks/booking/useClassTypeNameWhichDoesNotOccupyBookingSlot";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert/Alert";
import { AlertTitle } from "@material-ui/lab";
import { InfoOutlined } from "@material-ui/icons";
import useClassTypeInfos from "../hooks/classTypes/useClassTypeInfos";
import { Capacitor } from "@capacitor/core";



export type BookingListProps = {
  count: number;
};
const BookingList = styled.div<BookingListProps>`
  display: grid;
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    grid-template-columns: repeat(${(props) => props.count}, 100%);
    overflow-x: scroll;
    overflow-y: hidden;
  }
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    grid-template-columns: repeat(${(props) => props.count}, 250px);
    overflow-y: auto;
  }
  margin-top: 10px;

  scroll-snap-type: x mandatory;
  grid-area: booking;
`;

const BookingDayContainer = styled.div`
  // background: white;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  padding: 10px;

  padding-top: 0px;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    overflow-y: auto;
  }
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    padding-right: 0;
  }
`;
const BookingDay = styled.div`
  background: white;
  padding: 20px;
`;

const BookingContainer = styled(PageContent)`
  grid-gap: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 80px minmax(0, 1fr);
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    grid-template-rows: 50px minmax(0, 1fr);
  }
  grid-template-areas:
    "dayscontainer"
    "booking";

  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    overflow-y: auto;
  }
`;

export type DaysContainerProps = {
  count: number;
};
const DaysContainer = styled.div<DaysContainerProps>`
  grid-area: dayscontainer;
  // white-space: nowrap;
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, 55px);
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    grid-template-columns: repeat(${(props) => props.count}, 250px);
  }
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  padding-left: 10px;
  padding-right: 10px;
`;

export type DayStyledProps = {
  active: boolean;
};
const Day = styled.div<DayStyledProps>``;

export type DayBackgroundProps = {
  active: boolean;
};
const DayBackground = styled.div<DayBackgroundProps>`
  color: white;
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 5px;
  // margin: 0px 10px;
  display: inline-flex;
  flex-direction: column;
  // width: 50px;
  height: 100%;
  align-items: center;
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    flex-direction: row;
    justify-content: start;
    padding: 0;
    padding-left: 10px;
  }

  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    width: 100%;
  }
  ${(props) =>
    props.active &&
    css`
      background: var(--black);
    `}
`;
const WeekDay = styled.div`
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    display: none;
  }
`;
const WeekDayLong = styled.div`
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  padding-left: 10px;
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    display: none;
  }
`;
export type DayNumberProps = {
  active: boolean;
};
const DayNumber = styled.div<DayNumberProps>`
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  margin-top: 5px;
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    margin: 0;
  }
  ${(props) =>
    props.active &&
    css`
      background: var(--white);
      color: var(--black);
    `}
`;
export type DayHasBookingsProps = {
  active: boolean;
};
const DayHasBookings = styled.div<DayHasBookingsProps>`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-top: 5px;
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: var(--white);
    `}
`;

export default function Booking() {
  const { data: classes } = useClasses();
  const { data: bookings } = useActiveUserBookings();

  const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  var grouped =
    classes &&
    groupBy(classes, (x) => {
      var date = new Date(x.start);
      var myDateString = date.getFullYear() + '' + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2);
      return myDateString;
    });

  
  const inputRef = useRef(new Array(100));
  const inputRef2 = useRef(new Array(100));
  const [active, setActiveState] = useState(0);
  const [scrollOnBack, setScrollOnBack] = useState(true);
  useEffect(() => {
    var num = localStorage.getItem("activeBooking");
    if (inputRef.current.length > 0 && num !== "0" && scrollOnBack) {
      if (inputRef.current[parseInt(num ?? "0")]) {
        setScrollOnBack(false);
        inputRef.current[parseInt(num ?? "0")].scrollIntoView();
        localStorage.setItem("activeBooking", "0");
      }
    }
    //
    // localStorage.setItem("activeBooking", "0");
  }, [scrollOnBack, setScrollOnBack]);
  const setActive = (num: number) => {
    setActiveState(num);
    localStorage.setItem("activeBooking", num.toString());
  };
  var dayCount = grouped ? Object.entries(grouped).length : 0;
  return (
    <PageContainer>
      <PageHeader>Holdoversigt</PageHeader>
      <BookingContainer>
        <DaysContainer count={dayCount}>
          {grouped &&
            Object.entries(grouped).map(([key, value], index) => {
              var mo = moment(key, "YYYYMMDD");
              var hasBooking = false;
              for (var i = 0; i < value.length; i++) {
                const classItem = value[i] as ClassViewModel;
                const booking = bookings?.find(
                  (b) => b.classId === classItem.id
                );
                if (booking) {
                  hasBooking = true;
                  break;
                }
              }
              return (
                <Day
                  key={key}
                  onClick={() => {
                    inputRef.current[index].scrollIntoView();
                    inputRef2.current[index].scrollIntoView({
                      behavior: "smooth",
                      inline: "center",
                    });
                    setActive(index);
                  }}
                  ref={(el) => (inputRef2.current[index] = el)}
                  active={index === active}
                >
                  <DayBackground active={index === active}>
                    <WeekDay>{mo.format("ddd")}</WeekDay>
                    <DayNumber active={index === active}>
                      {mo.format("D")}
                    </DayNumber>
                    <WeekDayLong>{mo.format("dddd")}</WeekDayLong>
                    <DayHasBookings active={hasBooking}></DayHasBookings>
                  </DayBackground>
                </Day>
              );
            })}
        </DaysContainer>
        <BookingList
          count={dayCount}
          onScroll={(e: React.UIEvent<HTMLElement>) => {
            const newActive = Math.round(
              e.currentTarget.scrollLeft / e.currentTarget.clientWidth
            );

            if (newActive !== active) {
              setActive(newActive);
              inputRef2.current[newActive].scrollIntoView({
                behavior: "smooth",
                inline: "center",
              });
            }
          }}
        >
          {grouped &&
            Object.entries(grouped).map(([key, value], index) => {
              return (
                <BookingDayContainer
                  key={key}
                  ref={(el) => (inputRef.current[index] = el)}
                >
                  <BookingDay>
                    {value.map((x) => {
                      const classItem = x as ClassViewModel;
                      const booking = bookings?.find(
                        (b) => b.classId === classItem.id
                      );
                      return (
                        <BookingClass
                          key={classItem.id}
                          booking={booking}
                          class={classItem}
                          showDate={false}
                          activeBookings={bookings ? bookings.filter(x => x.occupyActiveBookingSlot === true).length : 0}
                        />
                      );
                    })}
                  </BookingDay>
                </BookingDayContainer>
              );
            })}
        </BookingList>
      </BookingContainer>
    </PageContainer>
  );
}

type CardProps = {
  booking: BookingViewModel | undefined;
  class: ClassViewModel;
  activeBookings: number;
  showDate?: boolean | null;
  user?: AccountViewModel | null;
};

const StyledBookingClass = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey3);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

const NameContainer = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: var(--black);
`;
const UserPictureContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
`;
const UserPicture = styled.img`
  width: 40px;
  border-radius: 50%;
`;
const UserNameContainer = styled.div``;
const ClassContainer = styled.div`
  flex-grow: 1;
`;
const UserName = styled.span`
  font-weight: 600;
`;
const TimeContainer = styled.div`
  text-transform: uppercase;
  // font-weight: 600;
  color: var(--grey1);
`;
const ActionContainer = styled.div``;
const ClassName = styled.span`
  margin-right: 10px;

`;
const ClassInfo = styled.span`
  
  position: relative;
  margin-right: 16px;
  svg{
    height: 16px;
    margin-left: 5px;
     position: absolute;

    path:last-child{
      fill: var(--grey1);;
    }
  }
`;

const StyledAlert = styled(Alert)`
  // background: var(--green);
  // color: var(--white);
`;
const StyledSnackbar = styled(Snackbar)`
  top: calc(50px + var(--platform-top));
`;
export const BookingClass: FunctionComponent<CardProps> = ({
  booking,
  activeBookings,
  class: classItem,
  showDate,
  user,
}) => {
  const [bookMutate] = useBookClass();
  const [removeBookingFromClassMutate] = useRemoveBookingFromClass();

  function Book(classId: string) {
    bookMutate(classId);
  }
  function RemoveBooking(classId: string) {
    removeBookingFromClassMutate(classId);
  }
  const { data: maxNumberOfActiveBookings } = useMaxActiveUserBookings();
  const { data: classTypeInfos } = useClassTypeInfos();
  const { data: classTypeNameWhichDoesNotOccupyBookingSlot } = useClassTypeNameWhichDoesNotOccupyBookingSlot();

  const removeBookingTime = new Date(classItem.start);
  removeBookingTime.setHours(removeBookingTime.getHours() - 2);

  const start = moment(classItem.start);
  const end = moment(classItem.end);

  const [show, setShow] = useState(false);
  const handleInfoClick = () => {
    setShow(true)
  }

  const handleSubmit = () => {
    setShow(false)
  };
  
  const getInfo = (className:string) => {

    var found = classTypeInfos?.find(x => x.classTypes.includes(className));
    if(found){
      return <div dangerouslySetInnerHTML={{__html: found.info}}></div>
    }
    return null;
  }
  const info = getInfo(classItem.classTypeName);
  return (
    <StyledBookingClass key={classItem.id}>
      {user && (
        <UserPictureContainer>
          <UserPicture src={user.picture} alt={user.firstName} />
        </UserPictureContainer>
      )}
      <ClassContainer>
        {user && (
          <UserNameContainer>
            <UserName>
              <NavLink to={USER.replace(":userId", user.id)}>
                {user.firstName} {user.lastName}
              </NavLink>
            </UserName>{" "}
            har meldt sig på:
          </UserNameContainer>
        )}
        <NameContainer>
          <ClassName>
            <NavLink to={CLASS.replace(":classId", classItem.id)}>
              {classItem.classTypeName}
            </NavLink>
            {info != null && <ClassInfo onClick={handleInfoClick}><InfoOutlined /></ClassInfo>}
            
          </ClassName>
          
          <span>
            {/* Do not show class highlights when booked */}
            {(!booking || classItem.cancelled) &&
              classItem.highlights.map((h) => (
                <Highlight color={h.color} text={h.text} key={h.text} />
              ))}
            {!classItem.cancelled &&
              booking &&
              booking.state === BookingState.WaitingList && (
                <Highlight
                  color={HighlightColor.Grey3}
                  text={`Nr ${booking.position} på venteliste`}
                />
              )}
            {!classItem.cancelled &&
              booking &&
              booking.state === BookingState.Participating && (
                <Highlight color={HighlightColor.Red} text={`Tilmeldt`} />
              )}
            {!classItem.cancelled &&
              booking &&
              booking.state === BookingState.NoShow && (
                <Highlight
                  color={HighlightColor.Black}
                  text={`Du mødte ikke op`}
                />
              )}
          </span>
        </NameContainer>
        <TimeContainer>
          {showDate &&
            start.calendar(null, {
              lastDay: "[I går]",
              sameDay: "[I dag]",
              nextDay: "[I morgen]",
              lastWeek: "[sidste] dddd",
              nextWeek: "dddd",
              sameElse: "Do MMMM",
            }) + " - "}
          {start.format("LT")} - {end.format("LT")}
        </TimeContainer>
      </ClassContainer>
      <ActionContainer>
        {classItem &&
          !classItem.cancelled &&
          booking &&
          removeBookingTime > new Date() && (
            <CustomButton
              onClick={() => RemoveBooking(classItem.id)}
              themecolor={ThemeColor.Black}
            >
              afmeld
            </CustomButton>
          )}
        {classItem &&
          !classItem.cancelled &&
          !booking &&
          ((maxNumberOfActiveBookings && activeBookings < maxNumberOfActiveBookings) || (classTypeNameWhichDoesNotOccupyBookingSlot && classTypeNameWhichDoesNotOccupyBookingSlot?.indexOf(classItem.classTypeName) > -1)) &&
          classItem.slots > 0 && 
          classItem.start > new Date() && (
            <CustomButton
              onClick={() => Book(classItem.id)}
              themecolor={ThemeColor.Grey3}
            >
              tilmeld
            </CustomButton>
          )}
      </ActionContainer>
      {info != null && <StyledSnackbar
            key={classItem.id}
            open={show}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <StyledAlert icon={false}  onClose={() => handleSubmit()} severity="info">
              <AlertTitle><strong>{classItem.classTypeName}</strong></AlertTitle>
              {info}
              <CustomButton onClick={() => handleSubmit()} themecolor={ThemeColor.Green}>OK</CustomButton>
              </StyledAlert>
          </StyledSnackbar>}
    </StyledBookingClass>
  );
};
