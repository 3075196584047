import React from "react";

export default function ProfileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        fill="#868685"
        d="M494.4 230.65C506.33 381.98 381 507.31 229.67 495.4 110.63 486.03 14.17 389.61 4.76 270.57-7.21 119.23 118.14-6.16 269.5 5.77c119.05 9.38 215.51 105.83 224.9 224.88z"
      />
      <path
        fill="#FFF"
        d="M371.59 295.74c-27.77-13.23-57.47-21.58-87.73-25.17 1.02-7.17 2.05-14.37 3.06-21.54 6.72-4.31 11.56-10.64 11.99-15.93.79-6.66 1.57-13.32 2.34-19.99.93.97 2.18 1.66 3.61 1.82 3.2.32 6.05-2 6.38-5.19l3.19-30.78c.34-3.18-1.99-6.03-5.18-6.35-1.21-.14-2.37.13-3.38.67.69-5.85 1.37-11.68 2.02-17.56.56-8.02 4.53-29.42-10.93-43.22-22.36-21.95-71.56-21.95-93.92 0-15.45 13.8-11.48 35.2-10.95 43.22.68 5.88 1.37 11.71 2.04 17.56-.98-.54-2.17-.81-3.38-.67-3.16.32-5.5 3.17-5.17 6.35l3.19 30.78c.31 3.19 3.15 5.51 6.35 5.19 1.45-.16 2.69-.85 3.62-1.82.77 6.67 1.55 13.33 2.34 19.99.46 5.29 5.29 11.62 12 15.93 1.01 7.17 2.03 14.36 3.03 21.54-30.22 3.59-59.94 11.94-87.7 25.17-8.95 4.71-15.24 16.86-14.27 26.3 1.04 10.81 2.11 21.63 3.23 32.43.96 9.43 9.05 18.75 18.01 20.48 76.03 12.26 153.21 12.26 229.26 0 8.93-1.73 17.03-11.05 17.99-20.48 1.12-10.8 2.17-21.62 3.23-32.43.92-9.43-5.32-21.61-14.27-26.3z"
      />
    </svg>
  );
}
