
import { AxiosError } from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import ErrorSummary, { addServerErrors } from "../components/ErrorSummary";
import { ClassTypeCreateModel } from "../hooks/classTypes/classTypeModels";
import useClassTypes from "../hooks/classTypes/useClassTypes";
import useCreateClassType from "../hooks/classTypes/useCreateClassType";
export default function ClassTypes() {
  const classTypes = useClassTypes();
  const [createMutate] = useCreateClassType();

  const {
    register,
    handleSubmit,
    errors,
    setError,
  } = useForm<ClassTypeCreateModel>();
  const onSubmit = (data: ClassTypeCreateModel) => {
    createMutate(data).catch((err: AxiosError) => {
      if (err.response?.data.errors) {
        addServerErrors(err.response?.data.errors, setError);
      }
    });
  };
  return (
    <>
      <h2>Hold typer {classTypes.isLoading && <div>Loader</div>}</h2>
     
          {classTypes.data &&
        classTypes.data.map((x) => {
          return (
           
            <div key={x.id}>
              {x.name} ---- {x.id}
            </div>
          );
        })}
       
     

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <input
          name="name"
          defaultValue="WOD"
          ref={register({
            required: { value: true, message: "Type navn påkrævet" },
          })}
        />

        <input type="submit" />
        <ErrorSummary errors={errors} />
      </form>
    </>
  );
}
