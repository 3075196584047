import styled, { css } from "styled-components";

import { Capacitor } from "@capacitor/core";
type StyledContentContainerProps = {
  platform?: string;
  backgroundSize?: ContentContainerBackgroundSize;
  backgroundColor?: ContentContainerBackgroundColor;
};
export enum ContentContainerBackgroundSize {
  normal,
  large,
}

export enum ContentContainerBackgroundColor {
  red,
  dark,
}
export const PageContainer = styled.div<StyledContentContainerProps>`
  background: linear-gradient(
    180deg,
    ${(props) => (props.backgroundColor === ContentContainerBackgroundColor.dark ? "rgba(33, 33, 33, 1)" : "rgba(190, 23, 49, 1)")} 
      ${(props) => (props.platform !== "web" ? "35%" : "25%")},
    rgba(73, 100, 73, 0) 0%
  );

  ${(props) =>
    props.backgroundSize === ContentContainerBackgroundSize.large &&
    css`
      background: linear-gradient(
        180deg,
        ${props.backgroundColor === ContentContainerBackgroundColor.dark ? "rgba(33, 33, 33, 1)" : "rgba(190, 23, 49, 1)"} 
         ${props.platform !== "web" ? "55%" : "35%"},
        rgba(73, 100, 73, 0) 0%
      );
    `}

  padding-top: calc(10px + var(--platform-top));
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50px minmax(0, 1fr);
 
  grid-template-areas:
    "pageheader"
    "pagecontent";
`;

PageContainer.defaultProps = {
  platform: Capacitor.getPlatform(),
  backgroundSize: ContentContainerBackgroundSize.normal,
  backgroundColor: ContentContainerBackgroundColor.red,
};

// type Props = {
//   backgroundSize?: ContentContainerBackgroundSize;
//   backgroundColor?: ContentContainerBackgroundColor;
// };

// export const ContentContainer: React.FC<Props> = ({
//   children,
//   backgroundSize = ContentContainerBackgroundSize.normal,
// }) => {
//   var platform = Capacitor.getPlatform();
//   return (
//     <StyledContentContainer platform={platform} backgroundSize={backgroundSize}>
//       {children}
//     </StyledContentContainer>
//   );
// };
