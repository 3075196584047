import React, { useState } from "react";
import {  ErrorOption, FieldName, useForm } from "react-hook-form";
import {
  FullAccountViewModel,
  EmailSettingsWriteModel,
} from "../hooks/account/accountModels";
import { BoxHeader } from "./Box";
import ButtonAligner from "./ButtonAligner";
import CustomButton, { ThemeColor } from "./CustomButton";
import ErrorSummary from "./ErrorSummary";
import CheckboxInput from "./form/CheckboxInput";
import {
  PushNotifications,
} from '@capacitor/push-notifications';
import { Capacitor } from "@capacitor/core";
export type EditUserEmailNotificationSettingsProps = {
  user: FullAccountViewModel;
  handleCancelEdit: () => void;
  handleSubmitEdit: (
    data: EmailSettingsWriteModel,
    setError: (name: FieldName<EmailSettingsWriteModel>, error: ErrorOption) => void
  ) => void;
};

const EditUserEmailNotificationSettings = ({
  user,
  handleCancelEdit,
  handleSubmitEdit,
}: EditUserEmailNotificationSettingsProps) => {
  const {
    handleSubmit,
    setError,
    errors,
    control,
  } = useForm<EmailSettingsWriteModel>();

  const onSubmit = (data: EmailSettingsWriteModel) => {
  //  console.log('data', data);
    handleSubmitEdit(data, setError);
  };

  const [permissionState, setPermissionState] = useState<string>("unknown");

  if (Capacitor.getPlatform() !== "web") {
    PushNotifications.checkPermissions().then((status) => {
      setPermissionState(status.receive);
      if(status.receive === "granted"){

      }
    })
  }
  return (
    <>
      {user && (
        <div>
          <BoxHeader>Notifikation indstillinger</BoxHeader>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>

            {permissionState === "denied" && <div>Gå til indstillinger for at tillade mobil notifikationer</div>}
            {permissionState === "granted" && <CheckboxInput
              label="Modtag push notificationer på mobil"
              name="adfsadsad"
              control={control}
              fullWidth={true}
              disabled={true}
              defaultChecked={true}              
            />}
          
            
            <CheckboxInput
              label="Modtag notifikationer som e-mail"
              name="sendNotificationsAsEmail"
              control={control}
              fullWidth={true}
              defaultChecked={user.sendNotificationsAsEmail}
            />

          

            <ErrorSummary errors={errors} />
            <br />
            <ButtonAligner>
              <CustomButton type="submit">Gem indstillinger</CustomButton>
              <CustomButton
                onClick={handleCancelEdit}
                themecolor={ThemeColor.Black}
              >
                Annuller
              </CustomButton>
            </ButtonAligner>
          </form>
        </div>
      )}
    </>
  );
};

export default EditUserEmailNotificationSettings;
