import { AxiosError } from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ErrorSummary, { addServerErrors } from "../../components/ErrorSummary";
import { useAuthState } from "../../Context/AuthProvider";
import { LoginPayload } from "../../Context/actions";
import { DASHBOARD, REGISTERUSER, REQUESTPASSWORDRESET } from "../../routes";

import styled from "styled-components";
import CustomButton from "../../components/CustomButton";
import useLogin from "../../hooks/account/useLogin";
import Logo from "../../components/Logo";

import TextInput from "../../components/form/TextInput";

import {  Capacitor } from "@capacitor/core";

import useRegisterToUserOnDevice from "../../hooks/notification/useRegisterToUserOnDevice";
import { BoxHeader } from "../../components/Box";
import AppStoreIcon from "../../icons/AppStoreIcon";
import { Device } from "@capacitor/device";
const LogoHolder = styled.div`
  padding-bottom: 40px;
`;

const Spacer = styled.div`
  margin-bottom: 20px;
`;
const Divider = styled.div`
  margin-top: 20px;
  border-top: 1px solid var(--grey3);
  padding-top: 20px;
`;

const AppIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img{
    height: 60px;
  }

`;
export default function Login() {
  const history = useHistory();
  const { handleSubmit, errors, setError, control } = useForm<LoginPayload>();

  const [registerMutate] = useRegisterToUserOnDevice();
  const [loginMutate] = useLogin();
  const { setToken } = useAuthState();
  const onSubmit = (data: LoginPayload) => {
    loginMutate(data)
      .then((result) => {
        if (result?.data) {
          setToken(result?.data);
          if (Capacitor.getPlatform() !== "web") {
            Device.getId().then((info) => {
              //alert(info.uuid)
              registerMutate({ deviceId: info.identifier });
            });
          }
          history.push(DASHBOARD);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };

  return (
    <>
      <LogoHolder>
        <Logo forbackground={false} />
      </LogoHolder>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Spacer>
          <TextInput
            control={control}
            name="email"
            label="Email adresse"
            type="email"
            //defaultValue="kennethkjeldsen@gmail.com"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="password"
            label="Adgangskode"
            type="password"
            //defaultValue="123456"
            fullWidth={true}
          />
          <ErrorSummary errors={errors} />
        </Spacer>
        <CustomButton type="submit">Log ind</CustomButton>{" "}
        <CustomButton
          onClick={() => {
            history.push(REQUESTPASSWORDRESET);
          }}
        >
          Glemt kodeord?
        </CustomButton>
      </form>
      <Divider>
        <BoxHeader>Er du ny?</BoxHeader>
        <CustomButton
          onClick={() => {
            history.push(REGISTERUSER);
          }}
        >
          Opret bruger
        </CustomButton>
      </Divider>
      {Capacitor.getPlatform() === "web" && (
        <Divider>
          <BoxHeader>Hent som app</BoxHeader>
          <p>
            Hent booking platform som app - få en bedre oplevelse med
            notifikatiner.
          </p>
          <AppIcons>
            <a href="https://apps.apple.com/us/app/crossfit8500/id1552705444">
              <AppStoreIcon />
            </a>{" "}
        
            <a href="https://play.google.com/store/apps/details?id=com.prokode.crossfit&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
              
                alt="Nu på Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/da_badge_web_generic.png"
              />
            </a>
          </AppIcons>
        </Divider>
      )}
    </>
  );
}
