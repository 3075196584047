import React, { useContext } from "react";
import styled from "styled-components";
import { useAuthState } from "../Context/AuthProvider";
import { SocketContext } from "../Context/SocketProvider";
import useMe from "../hooks/account/useMe";
import BookingIcon from "../icons/BookingIcon";
import DashboardIcon from "../icons/DashboardIcon";
import MessagesIcon from "../icons/MessagesIcon";
import ProfileIcon from "../icons/ProfileIcon";
import FriendsIcon from "../icons/FriendsIcon";
import SettingsIcon from "../icons/SettingsIcon";
import LogOutIcon from "../icons/LogOutIcon";
import {
  BOOKING,
  CLASSSETUP,
  CLASSTYPES,
  DASHBOARD,
  DEFAULTCLASSES,
  FRIENDSACTIVITY,
  LOGOUT,
  MESSAGES,
  PROFILE,
  USERS,
} from "../routes";
import Logo from "./Logo";
import NavigationItem from "./NavigationItem";
import { Capacitor } from "@capacitor/core";

const NavigationHello = styled.div`
  background: white;

  grid-area: navigation;
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    //  max-height: 50px;
    box-shadow: 0px 0px 34px 29px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
  }

  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    display: flex;
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    display: none;
  }
`;

const UserContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    display: none;
  }
`;
const UserPicture = styled.img`
  border-radius: 50%;
  width: 130px;
  height: 130px;
  border: 2px solid var(--red);
`;
const UserName = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  padding: 30px;
`;

const NavigationTop = styled.div`
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const Navigation = () => {
  const socketContext = useContext(SocketContext);
  const userDetails = useAuthState();
  const { data } = useMe();
  if (userDetails === null || userDetails.token === null) return null;

  var messagesCount = socketContext.notificationCount;
  return (
    <NavigationHello>
      <NavigationTop>
        <LogoContainer>
          <Logo forbackground={false} />
        </LogoContainer>
        {data && (
          <UserContainer>
            <UserPicture src={data.picture} alt={data.firstName} />
            <UserName>
              {data.firstName} {data.lastName}
            </UserName>
          </UserContainer>
        )}
        <NavigationItem
          icon={<DashboardIcon />}
          exact={true}
          text="FORSIDE"
          to={DASHBOARD}
        />
        <NavigationItem
          icon={<BookingIcon />}
          exact={false}
          text="Book hold"
          to={BOOKING}
        />

        <NavigationItem
          icon={<FriendsIcon />}
          exact={false}
          text="Venner"
          to={FRIENDSACTIVITY}
        />

        <NavigationItem
          icon={<MessagesIcon />}
          exact={false}
          text={`Beskeder${messagesCount > 0 ? " (" + messagesCount + ")" : ""
            }`}
          to={MESSAGES}
        />

        <NavigationItem
          icon={<ProfileIcon />}
          exact={false}
          text="Profil"
          to={PROFILE}
        />



        <NavigationItem
          icon={<SettingsIcon />}
          requiresRoles="Administrator"
          desktopOnly={true}
          exact={false}
          text="Holdopsætning"
          to={CLASSSETUP}
        />

        <NavigationItem
          icon={<SettingsIcon />}
          requiresRoles="Administrator"
          desktopOnly={true}
          exact={false}
          text="Hold typer"
          to={CLASSTYPES}
        />

        <NavigationItem
          icon={<SettingsIcon />}
          requiresRoles="Administrator"
          desktopOnly={true}
          exact={false}
          text="Medlemmer"
          to={USERS}
        />

        <NavigationItem
          icon={<SettingsIcon />}
          requiresRoles="Administrator"
          desktopOnly={true}
          exact={false}
          text="Standard hold"
          to={DEFAULTCLASSES}
        />
      </NavigationTop>

      <div>
        <NavigationItem
          icon={<LogOutIcon />}
          desktopOnly={true}
          exact={false}
          text="Log ud"
          to={LOGOUT}
        />
      </div>
    </NavigationHello>
  );
};

export default Navigation;
