import React
// , { useState, useEffect } 
from "react";
// import { FilePluginPlugin } from "@kekridk/prokode-file-plugin";
// import axios from "../axios";
// import "@kekridk/prokode-file-plugin";
// import { Plugins, FilesystemDirectory, Capacitor } from "@capacitor/core";
// const { WebView, Filesystem, FilePlugin } = Plugins;

// type DeploymentManifestFile = {
//     path: string;
//     size: number;
//     checksum: string;
// };

export type UpdaterProps = {
    children: React.ReactNode;
};

export default function Updater({ children }: UpdaterProps) {

    // const checkforupdateTimer = () => {
        
    //     if (Capacitor.getPlatform() === "web") {
    //         return;
    //     }

    //     if(localStorage.getItem('autoupdate') !== 'true'){
    //         return;
    //     }
       

    //     var plugun = FilePlugin as FilePluginPlugin;
    //     plugun.getAppInfo().then(({ dataDirectory: dataDir }) => {

    //         // Filesystem.readdir({

    //         //     path: '',
    //         //     directory: dataDir

    //         // })

    //     });

    //     checkforupdate();
    //     window.setInterval(() => {
    //         checkforupdate();
    //     }, 30 * 1000)
    // }
    // const checkforupdate = () => {

    //     var currentVersion = process.env.REACT_APP_VERSION;
    //     axios
    //         .get<string>(`/current-version.txt?timestamp=${new Date().getTime()}`)
    //         .then((result) => {
    //             if (currentVersion && currentVersion !== result.data) {
    //               //  alert("Ny app version tilgængelige - hentes nu..");
    //                 fetchManifest(result.data, currentVersion);
    //             }
    //         });
    // };

    // class Path {
    //     static join(...paths: string[]): string {
    //         let fullPath: string = paths.shift() || "";
    //         for (const path of paths) {
    //             if (fullPath && fullPath.slice(-1) !== "/") {
    //                 fullPath += "/";
    //             }
    //             fullPath =
    //                 path.slice(0, 1) !== "/" ? fullPath + path : fullPath + path.slice(1);
    //         }
    //         return fullPath;
    //     }
    // }

    // const SNAPSHOT_CACHE = "ionic_built_snapshots";

    // const [progress, setProgress] = useState<number>(0);
    // const [totalProgress, setTotalProgress] = useState<number>(0);
    // console.log(totalProgress);
    // const fetchManifest = (newVersion: string, currentVersion: string) => {
    //     var plugun = FilePlugin as FilePluginPlugin;
    //     axios
    //         .get<DeploymentManifestFile[]>(
    //             `/deployment-manifest.json?timestamp=${new Date().getTime()}`
    //         )
    //         .then(({ data: deploymentManifestFiles }) => {
    //             axios
    //                 .get<DeploymentManifestFile[]>(
    //                     `/deployment-manifest.json?timestamp=${new Date().getTime()}`,
    //                     {
    //                         baseURL: undefined,
    //                     }
    //                 )
    //                 .then(({ data: currentDeploymentManifestFiles }) => {
    //                     WebView.getServerBasePath().then(({ path: currentServerPath }) => {
    //                         plugun.getAppInfo().then(({ dataDirectory: dataDir }) => {

    //                             var promises: any[] = [];

    //                             var totalSize = 0;

    //                             const filesToDownload: DeploymentManifestFile[] = [];
    //                             const filesToCopy: DeploymentManifestFile[] = [];
    //                             const ok = currentDeploymentManifestFiles;
    //                             console.log(ok);
    //                             for (var i = 0; i < deploymentManifestFiles.length; i++) {
    //                                 const deploymentFile = deploymentManifestFiles[i];
    //                                 // var file = currentDeploymentManifestFiles.find(x => x.path === deploymentFile.path)
    //                                 // if (file) {
    //                                 //   if (file.checksum === deploymentFile.checksum) {
    //                                 //     filesToCopy.push(deploymentFile);
    //                                 //   } else {
    //                                 //     filesToDownload.push(deploymentFile);
    //                                 //   }
    //                                 // } else {
    //                                 //TODO: fix copy current base - to save data
    //                                 filesToDownload.push(deploymentFile);
    //                                 // }
    //                             }

    //                             for (var u = 0; u < filesToCopy.length; u++) {
    //                                 const deploymentFile = filesToCopy[u];
    //                                 alert(currentServerPath);
    //                                 alert(
    //                                     new URL(
    //                                         Path.join(
    //                                             SNAPSHOT_CACHE,
    //                                             currentVersion,
    //                                             deploymentFile.path
    //                                         )
    //                                     ).pathname
    //                                 );

    //                                 Filesystem.copy({
    //                                     from: new URL(
    //                                         Path.join(
    //                                             SNAPSHOT_CACHE,
    //                                             currentVersion,
    //                                             deploymentFile.path
    //                                         )
    //                                     ).pathname,
    //                                     to: new URL(
    //                                         Path.join(SNAPSHOT_CACHE, newVersion, deploymentFile.path)
    //                                     ).pathname,
    //                                     directory: FilesystemDirectory.External,
    //                                 })
    //                                     .then(() => {
    //                                         alert("file copied");
    //                                     })
    //                                     .catch((e: any) => {
    //                                         alert(JSON.stringify(e));
    //                                     });
    //                             }

    //                             for (var j = 0; j < filesToDownload.length; j++) {
    //                                 const deploymentFile = filesToDownload[j];
    //                                 totalSize = totalSize + deploymentFile.size;
    //                                 //alert("downloading" + "https://crossfit.prokode.dk" + deploymentFile.path)
    //                                 var promise = plugun
    //                                     .download({
    //                                         url: "https://crossfit.prokode.dk" + deploymentFile.path,
    //                                         target: new URL(
    //                                             Path.join(
    //                                                 dataDir,
    //                                                 SNAPSHOT_CACHE,
    //                                                 newVersion,
    //                                                 deploymentFile.path
    //                                             )
    //                                         ).pathname,
    //                                     })
    //                                     .then((r: any) => {
    //                                         const downloadedSize = progress + deploymentFile.size;
    //                                         setProgress(downloadedSize);
    //                                         //  alert("downloaded")
    //                                     })
    //                                     .catch((e: any) => {
    //                                         alert(JSON.stringify(e));
    //                                     });
    //                                 promises.push(promise);
    //                             }

    //                             setTotalProgress(totalSize);
    //                             Promise.all(promises).then(() => {
    //                                 applyUpdate(dataDir, newVersion);
    //                             });
    //                         });
    //                     });
    //                 });
    //         });
    // };

    // const applyUpdate = (dataDir: string, newVersion: string) => {
    //   //  alert("Ny app version aktiveres");

    //     WebView.setServerBasePath({
    //         path: new URL(Path.join(dataDir, SNAPSHOT_CACHE, newVersion)).pathname,
    //     }).then(() => {
    //         WebView.persistServerBasePath();
    //     });
    // };

    // useEffect(checkforupdateTimer, []);

    return <>{children}</>;
}
