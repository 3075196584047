import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { SetPictureWriteModel } from "./accountModels";

export default function useSetPicture() {
  return useMutationForForms<unknown, unknown, SetPictureWriteModel>(
    (data: SetPictureWriteModel) =>
      axios.post(`/api/account/SetPicture`, data.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(Math.round((100 * event.loaded) / event.total));
        },
      }),
    {
      onSuccess: (data, updateData) => {
        queryCache.invalidateQueries(["me"]);
      },
    }
  );
}
