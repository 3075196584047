export enum NotificationState {
  Unread = 0,
  Seen = 1,
  Read = 2,
}

export enum NotificationType {
  UserAddedAsParticipantOnClass,
  ClassTimeUpdated,
  UserRemovedAsParticipantOnClass,
  UserRemovedAsParticipantOnClassBecauseClassCancelled,
  UserMovedFromWaitlistToParticipantOnClass,
  UserRemovedFromWaitlistOnClass,
  UserRemovedFromWaitlistOnClassBecauseClassCancelled,
  UserAddedToWaitlistOnClass,
  UserMarkedAsNoShowOnClass,
  UserUnmarkedAsNoShowOnClass,
  UserWaitlistPositionChangedOnClass,
  //  UserParticipantPositionChangedOnClass,
  FriendshipCreated,
  FriendshipApproved,
  // FriendshipDeleted,
  MembershipCreated,
  MembershipUpdated,
  UserCreated,
  UserDetialsUpdated,
  UserPictureUpdated
}

export interface NotificationSettingItem {
  type: NotificationType;
  typeText: string;
  push: boolean;
  email: boolean;
}

export interface NotificationTextViewModel {
  id: string;
  userId: string;
  createdTimestamp: Date;
  readTimestamp?: Date;
  seenTimestamp?: Date;
  data: any;
  title: any;
  text: any;
  state: NotificationState;
  type: NotificationType;
}
export interface UserAddedAsParticipantModel 
{
  classId: string;
}
export interface UserAddedAsParticipantExtendedModel 
{
  className: string;
  start: Date;
}
export interface SaveSettingsModel 
{
  notifications: NotificationSettingItem[];
}
export interface RegisterTokenPayload 
{
  token: string;
  deviceId: string;
}

export interface RegisterDevicePayload 
{
  deviceId: string;
}
export interface RegisterDeviceResponse
{
  requestedToSendNotifications: boolean;
}
export interface RegisterToUserOnDevicePayload 
{
  deviceId: string;
}
export interface UnRegisterToUserOnDevicePayload 
{
  deviceId: string;
}
export interface RegisterRequestedToSendNotificationsPayload 
{
  deviceId: string;
}