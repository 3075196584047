import { useQuery } from "react-query";
import axios from "./../../axios"
import { FullAccountViewModel } from "./accountModels";


const getMe = async (_: any) => {
  const { data } = await axios.get<FullAccountViewModel>(`/api/account/me`);
  return data;
};

export default function useMe() {
  return useQuery(["me"], getMe);
}
