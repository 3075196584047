import { useQuery } from "react-query";
import { AccountViewModel } from "../account/accountModels";
import axios from "./../../axios";

const getClasses = async (_: any, query: string) => {
  const { data } = await axios.get(`/api/Friendship/FindNewFriend`, {
    params: { query },
  });
  return data;
};

export default function useFindNewFriend(query: string) {
  return useQuery<AccountViewModel[]>(["new-friends", query], getClasses);
}
