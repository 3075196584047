import { useQuery } from "react-query";
import axios from "./../../axios";
import { NotificationTextViewModel } from "./notificationModels";

const get = async (_: any) => {
  const { data } = await axios.get(`/api/notification`);
  return data;
};

export default function useGetNotifications() {
  return useQuery<NotificationTextViewModel[]>(["notifications"], get);
}
