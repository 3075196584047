import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { AccountViewModel } from "../hooks/account/accountModels";
import useAutoComplete from "../hooks/account/useAutoComplete";

interface AutoCompleteProps {
    onAccountSelected: (account: AccountViewModel | null) => void;
  }

export default function AccountAutoComplete(props: AutoCompleteProps) {

  const [value, setValue] = React.useState<AccountViewModel | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<AccountViewModel[]>([]);
  const { data: accounts } = useAutoComplete(inputValue);

  React.useEffect(() => {
    props.onAccountSelected(value);
  }, [value, props]);

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (active) {
      let newOptions = [] as AccountViewModel[];

      if (value) {
        newOptions = [value];
      }

      if (accounts) {
        newOptions = [...newOptions, ...accounts];
      }

      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, accounts]);

  return (
    <Autocomplete
      id="google-map-demo"
      style={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.firstName + " " + option.lastName
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event: any, newValue: AccountViewModel | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Vælg medlem"
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">     
            <Grid item xs>
              {option.firstName} {option.lastName}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
