import React from "react";

import styled, { css } from "styled-components";
import { InputLabel, Select, SelectProps, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

export type TextInputProps = {
  control: any;
  label: string;
  name: string;
  type?: string;
  defaultValue?: unknown;
  fullWidth?: boolean;
  value?: unknown;
  multiple?: boolean;
  children: React.ReactNode;
  renderValue?: (value: SelectProps["value"]) => React.ReactNode;
  onChange?: SelectInputProps["onChange"];
};

export type ExtraProps = {
  fullWidth: boolean;
};
const InputContainer = styled.div<ExtraProps>`
  margin-bottom: 10px;
  ${(props) =>
    props.fullWidth &&
    css`
      > div {
        width: 100%;
      }
    `}
`;

export const CssSelectField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--red)",
    },
    "& .MuiInput-underline::after": {
      borderBottomColor: "var(--red)",
    },
    "& .MuiInput-underline::before": {
      borderBottomColor: "var(--red)",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      //   borderColor: 'red',
      // },
      // '&:hover fieldset': {
      //   borderColor: 'yellow',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "var(--red)",
      },
    },
  },
})(Select);

export default function Dropdown({
  control,
  label,
  name,
  children,
  renderValue,
  onChange,
  value,
  defaultValue,
  fullWidth = false,
  multiple = false,
}: TextInputProps) {
  
  const MySelect = () => {
    return (
      <CssSelectField
        label={label}
        defaultValue={defaultValue}
        value={value}
        name={name}
        onChange={onChange}
        multiple={multiple}
        renderValue={renderValue}
      >
        {children}
      </CssSelectField>
    );
  };

  return (
    <InputContainer fullWidth={fullWidth}>
      <>
        <InputLabel shrink>{label}</InputLabel>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          as={MySelect}
        />
      </>
    </InputContainer>
  );
}
