export interface HighlightModel {
    text: string;
    color: HighlightColor

  }
  
  export enum HighlightColor{
    Red,
    Black,
    Grey1,
    Grey2,
    Grey3,
    Green,
    Blue
  }