import { AxiosError } from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { BoxHeader } from "../../components/Box";

import styled from "styled-components";
import CustomButton, { ThemeColor } from "../../components/CustomButton";
import ErrorSummary, { addServerErrors } from "../../components/ErrorSummary";
import { RequestPasswordResetModel } from "../../hooks/account/accountModels";
import { LOGIN } from "../../routes";
import useRequestPasswordReset from "../../hooks/account/useRequestPasswordReset";
import TextInput from "../../components/form/TextInput";

const Spacer = styled.div`
  margin-bottom: 20px;
`;
export default function RequestResetPassword() {
  const history = useHistory();
  const [sent, setSent] = useState<boolean>(false);
  const {
    handleSubmit,
    errors,
    setError,
    control,
  } = useForm<RequestPasswordResetModel>();
  const [registerAccountMutate] = useRequestPasswordReset();
  const onSubmit = (data: RequestPasswordResetModel) => {
    registerAccountMutate(data)
      .then(() => {
        setSent(true);
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
    // loginUser(dispatch, data)
    //   .then((dd) => {
    //     history.push(DASHBOARD);
    //   })
    //   .catch((err: AxiosError) => {
    //     if (err.response?.data.errors) {
    //       addServerErrors(err.response?.data.errors, setError);
    //     }
    //   });
  };
  const handleCancelEdit = () => {
    history.push(LOGIN);
  };
  return (
    <>
      {!sent && (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Spacer>
            <BoxHeader>GLEMT KODEORD?</BoxHeader>

            <TextInput
              control={control}
              name="email"
              label="Email adresse"
              fullWidth={true}
            />
            <ErrorSummary errors={errors} />
          </Spacer>
          <CustomButton type="submit">Nustil KODEORD</CustomButton>{" "}
          <CustomButton
            onClick={handleCancelEdit}
            themecolor={ThemeColor.Black}
          >
            Annuller
          </CustomButton>
        </form>
      )}

      {sent && (
        <>
          <Spacer>
            <p>Der er sendt en e-mail, hvis du har en bruger</p>
          </Spacer>
          <CustomButton
            onClick={handleCancelEdit}
            themecolor={ThemeColor.Black}
          >
            Tilbage til login
          </CustomButton>
        </>
      )}
    </>
  );
}
