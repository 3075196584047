import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export interface useUnmarkBookingAsNoShowByUserIdPayload {
  classId: string;
  userId: string;
}
export default function useUnmarkBookingAsNoShowByUserId() {
  return useMutationWithError<boolean, unknown, useUnmarkBookingAsNoShowByUserIdPayload>(
    ({ classId, userId }) =>
      axios.post(
        `/api/booking/UnmarkAsNoShow?classId=${classId}&userId=${userId}`
      ),
    {
      onSuccess: (data, { classId }) => {
        // Query Invalidations
        queryCache.invalidateQueries("activeUserBookings");
        queryCache.invalidateQueries(["bookingsByClassId", classId]);
      },
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
