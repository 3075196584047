import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";
import { BookingViewModel } from "./bookingModels";

export default function useRemoveBookingFromClass() {
  return useMutationWithError<BookingViewModel, unknown, string>(
    (classId: string) => axios.delete(`/api/booking?classId=${classId}`),
    {
      onSuccess: (data, classId) => {
        // Query Invalidations
        queryCache.invalidateQueries("activeUserBookings");
        queryCache.invalidateQueries("bookingsByMembershipId");
        queryCache.invalidateQueries(["bookingsByClassId", classId]);
        queryCache.invalidateQueries(["classes", classId]);
      },
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
