import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { UpdateWriteModel } from "./accountModels";

export default function useUpdateAccount() {
  return useMutationForForms<unknown, unknown, UpdateWriteModel>(
    (data: UpdateWriteModel) => axios.post(`/api/account/update`, data),
    {
      onSuccess: (data, updateData) => {
        
        queryCache.invalidateQueries(["me"]);
      },
    }
  );
}
