import { AxiosResponse } from "axios";
import { queryCache, useMutation } from "react-query";
import axios from "./../../axios";

export default function useRefreshToken() {
  return useMutation<AxiosResponse<string>, unknown, null>(
    () => axios.post("/api/account/refreshtoken"),
    {
      onSuccess: (data, updateData) => {
        
        queryCache.invalidateQueries(["me"]);
      },
    }
  );
}
