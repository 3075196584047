import React from "react";

export default function LogOutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        fill="#868685"
        d="M494.72 230.45C506.65 381.77 381.32 507.11 230 495.19 110.95 485.82 14.49 389.4 5.08 270.36-6.89 119.03 118.46-6.37 269.82 5.56c119.05 9.38 215.51 105.83 224.9 224.89z"
      />
      <path
        fill="#FFF"
        d="M249.9 106.9c-79.24 0-143.48 64.22-143.48 143.48 0 79.25 64.24 143.48 143.48 143.48 79.25 0 143.48-64.22 143.48-143.48-.01-79.26-64.23-143.48-143.48-143.48zm-10.62 232.6v-46.82H166v-84.61h73.28v-46.82l111.34 89.13-111.34 89.12z"
      />
    </svg>
  );
}
