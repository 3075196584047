import { useQuery } from "react-query";
import axios from "./../../axios";
import { WeeklyGraphModel } from "./activityModels";

const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/Activity/GetWeeklyGraph`);
  return data;
};

export default function useWeeklyGraph() {
  return useQuery<WeeklyGraphModel[]>(["weeklyGraph"], getClasses);
}
