import React, { useEffect, useState } from "react";

import { Box } from "../components/Box";
import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";
import { PageHeader } from "../components/PageHeader";
import {
  NotificationSettingItem
} from "../hooks/notification/notificationModels";

import styled from "styled-components";
import { PageContent } from "../components/PageContent";
import { useHistory } from "react-router-dom";
import { MESSAGES } from "../routes";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ButtonAligner from "../components/ButtonAligner";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import useGetNotificationsSettings from "../hooks/notification/useGetNotificationsSettings";
import useSaveNotificationsSettings from "../hooks/notification/useSaveNotificationsSettings";

// type NotificationProps = {
//   state: NotificationState;
// };



const CustomCheckbox = styled(Checkbox)`
  color: var(--red) !important;
`;

export default function NotificationsSettings() {
  const history = useHistory();
  const settings = useGetNotificationsSettings();
  const [saveNotificationsSettingsMutate] = useSaveNotificationsSettings();
  const [settingsData, setSettingsData] = useState<NotificationSettingItem[]>(
    []
  );
  const handleSave = () => {
    saveNotificationsSettingsMutate({ notifications: settingsData });
    history.push(MESSAGES)
  };
  useEffect(() => {
    if (settings.data) setSettingsData(settings.data);
  }, [settings.data]);
  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.normal}>
      <PageHeader backButton={true}>Notifikationsindstillinger</PageHeader>
      <PageContent>
        <Box>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell align="center">Mobil push</TableCell>
                  <TableCell align="center">E-mail</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settingsData &&
                  settingsData.map((elm, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={elm.type}
                      >
                        <TableCell>{elm.typeText}</TableCell>
                        <TableCell align="center">
                          <CustomCheckbox
                            checked={elm.push}
                            onClick={() => {
                              var newArray = [...settingsData];
                              newArray[index].push = !newArray[index].push;
                              setSettingsData(newArray);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CustomCheckbox
                            checked={elm.email}
                            onClick={() => {
                              var newArray = [...settingsData];
                              newArray[index].email = !newArray[index].email;
                              setSettingsData(newArray);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <ButtonAligner>
            <CustomButton onClick={handleSave}>
              Gem Notifikationsindstillinger
            </CustomButton>
            <CustomButton
              onClick={() => {
                history.goBack();
              }}
              themecolor={ThemeColor.Black}
            >
              Annuller
            </CustomButton>
          </ButtonAligner>
        </Box>
      </PageContent>
    </PageContainer>
  );
}
