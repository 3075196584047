import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";
import {
  ClassUpdateTimeWriteModel,
  ClassViewModel,
} from "./classModels";

export default function useUpdateClassTime() {
  return useMutationWithError<
    ClassViewModel,
    unknown,
    ClassUpdateTimeWriteModel
  >(
    (data: ClassUpdateTimeWriteModel) => axios.post(`/api/class/updatetime`, data),
    {
      onSuccess: (data, createData) => {
        // Query Invalidations
        var converted = new Date(createData.start);     
        queryCache.invalidateQueries(["classes", `${converted.getFullYear()}-${converted.getMonth() + 1}-${converted.getDate()}`]);
      },
    }
  );
}
