import { Button } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { useAuthState } from "../Context/AuthProvider";
import { Capacitor } from "@capacitor/core";
export type NavigationItemStyledProps = {
  desktopOnly: boolean;
  mobileOnly: boolean;
};
const Hello = styled.span<NavigationItemStyledProps>`
  width: 80%;
  margin: 0 auto;

  ${(props) =>
    props.mobileOnly &&
    css`
      @media (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
        display: none;
      }
    `}
  ${(props) =>
    props.desktopOnly &&
    css`
      @media (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
        display: none;
      }
    `}
  .activeimage {
    display: none;
  }
  .active {
    background: var(--grey3);
    color: var(--red);
    .activeimage {
      display: inline;
    }
    .inactiveimage {
      display: none;
    }
  }

  a{
    display: block;
    margin-bottom: 10px;
    line-height: 25px;
  }

  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    a span {
      display: flex;
      align-items: center;
      justify-content: start;
    }
    svg {
      width: 20px;
      margin-right: 10px;
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    
    * { 
      font-size: 9px;

    }

    a {
      height: 100%;
    }
    a span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      white-space: nowrap;
    }
    svg {
      width: 20px;
    }
  }
`;

export type NavigationItemProps = {
  to: string;
  text: string;
  icon: React.ReactNode;
  exact: boolean;
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  requiresRoles?: "Administrator" | "Coach" | null;
};

const NavigationItem = ({
  to,
  text,
  icon,
  exact,
  mobileOnly = false,
  desktopOnly = false,
  requiresRoles = null,
}: NavigationItemProps) => {
  const userDetails = useAuthState();
  let hasAccess = true;
  if (hasAccess && requiresRoles) {
    if (userDetails?.user?.roles)
      hasAccess = userDetails.user.roles.indexOf(requiresRoles) > -1;
    else hasAccess = false;
  }
  if (!hasAccess) return null;
  return (
    <Hello mobileOnly={mobileOnly} desktopOnly={desktopOnly}>
      <Button
        component={NavLink}
        exact={exact}
        activeClassName="active"
        onClick={() => {
          localStorage.setItem("activeBooking", "0");
        }}
        to={to}
        // onTouchStart={(e:any) => {e.currentTarget.click(); e.preventDefault();}}
      >
        {icon}
        {text}
      </Button>
    </Hello>
  );
};

export default NavigationItem;
