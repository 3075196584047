import { useQuery } from "react-query";
import axios from "../../axios"
import { ClassViewModel } from "./classModels";


const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/class`);
  return data;
};

export default function useClasses() {
  return useQuery<ClassViewModel[]>(["classes"], getClasses);
}
