import axios from "axios";
import { Capacitor } from '@capacitor/core';
const instance = axios.create({
  baseURL: Capacitor.getPlatform() === 'web' ? undefined : 'https://crossfit.prokode.dk',
  timeout: 10000,
  params: {}, // do not remove this, its added to add params later in the config
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

function iter(o: any) {
  var ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(.\d{3})?)Z/;

  Object.keys(o).forEach(function (k) {
    if (o[k] !== null && typeof o[k] === "object") {
      iter(o[k]);
      return;
    }
    if (ISO_8601.test(o[k])) {
      o[k] = new Date(Date.parse(o[k]));
    }
  });
}

instance.interceptors.response.use(
  function (response) {
    var data = response.data;
    iter(data);
    return response;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
