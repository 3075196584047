import { useMutation } from "react-query";
import {
  MutationResultPair,
  MutationFunction,
  MutationConfig,
} from "react-query/types/core";

export default function useMutationForForms<
  TResult,
  TError = unknown,
  TVariables = undefined,
  TSnapshot = unknown
>(
  mutationFn: MutationFunction<TResult, TVariables>,
  config?: MutationConfig<TResult, TError, TVariables, TSnapshot>
): MutationResultPair<TResult, TError, TVariables, TSnapshot> {

  const mutation = useMutation<TResult, TError, TVariables, TSnapshot>(
    mutationFn,
    {throwOnError: true, ...config}
  );


  return mutation;
}
