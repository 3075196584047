import { Button, ButtonProps } from "@material-ui/core";
import styled from "styled-components";

export enum ThemeColor {
  Red,
  Black,
  Grey1,
  Grey2,
  Grey3,
  Green,
  Blue,
}



function GetColor(color: ThemeColor) {
  switch (color) {
    case ThemeColor.Red:
      return "var(--red)";
    case ThemeColor.Blue:
      return "var(--blue)";
    case ThemeColor.Green:
      return "var(--green)";
    case ThemeColor.Black:
      return "var(--black)";
    case ThemeColor.Grey1:
      return "var(--grey1)";
    case ThemeColor.Grey2:
      return "var(--grey2)";
    case ThemeColor.Grey3:
      return "var(--grey3)";
  }
}

function GetTextColor(color: ThemeColor) {
  switch (color) {
    case ThemeColor.Blue:
    case ThemeColor.Green:
    case ThemeColor.Red:
    case ThemeColor.Black:
      return "var(--white)";
    case ThemeColor.Grey1:
    case ThemeColor.Grey2:
    case ThemeColor.Grey3:
      return "var(--black)";
  }
}


type StyledContentContainerProps = ButtonProps & {
  themecolor?: ThemeColor;
  component?: any;
  to?: any;
};

const CustomButton = 
  styled(Button)<StyledContentContainerProps>`
  
  white-space: nowrap;
  background-color: ${(props) => GetColor(props.themecolor ?? ThemeColor.Grey3)};
    color: ${(props) => GetTextColor(props.themecolor ?? ThemeColor.Grey3)};
    &:hover {
      background-color: ${(props) => GetColor(props.themecolor ?? ThemeColor.Grey3)};
    }
  `
 
  


 // <StyledContentContainerProps>`
    // background-color: ${(props) => GetColor(props.themecolor)};
    // color: ${(props) => GetTextColor(props.themecolor)};
    // &:hover {
    //   background-color: ${(props) => GetColor(props.themecolor)};
    // }
export default CustomButton;
