import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export interface RemoveBookingFromClassByUserIdPayload {
  classId: string;
  userId: string;
}
export default function useRemoveBookingFromClassByUserId() {
  return useMutationWithError<boolean, unknown, RemoveBookingFromClassByUserIdPayload>(
    ({ classId, userId }) =>
      axios.delete(
        `/api/booking/DeleteByUserId?classId=${classId}&userId=${userId}`
      ),
    {
      onSuccess: (data, { classId }) => {
        // Query Invalidations
        queryCache.invalidateQueries("activeUserBookings");
        queryCache.invalidateQueries(["bookingsByClassId", classId]);
        queryCache.invalidateQueries(["classes", classId]);
      },
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
