import React from "react";

export default function SettingsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" >
      <path
        fill="#868685"
        d="M494.4 230.65C506.33 381.98 381 507.31 229.67 495.4 110.63 486.03 14.17 389.61 4.76 270.57-7.21 119.23 118.14-6.16 269.5 5.77c119.05 9.38 215.51 105.83 224.9 224.88z"
      />
      <g fill="#FFF">
        <path d="M220.91 309.75c-1.06-7.08-3.27-13.79-6.52-19.87l11.92-6.35a74.953 74.953 0 00-15.65-20.16l-9.16 10.02c-5.15-4.7-11.05-8.61-17.57-11.37l5.25-12.43a74.792 74.792 0 00-24.86-5.78l-.79 13.53c-1.2-.08-2.39-.19-3.61-.19-5.96 0-11.71.89-17.17 2.48l-3.75-13a75.098 75.098 0 00-22.95 11.16l7.92 11.02c-5.69 4.11-10.67 9.13-14.68 14.89l-11.12-7.74a74.8 74.8 0 00-10.81 23.13l13.09 3.57c-1.41 5.18-2.24 10.6-2.24 16.22 0 1.54.12 3.05.23 4.56l-13.51.99c.63 8.59 2.71 16.93 6.18 24.79l12.39-5.47c2.86 6.46 6.8 12.32 11.58 17.39l-9.88 9.3a74.855 74.855 0 0020.39 15.35l6.19-12.05a61.063 61.063 0 0019.97 6.26l-1.79 13.41c3.29.44 6.64.66 9.98.66 5.21 0 10.41-.53 15.47-1.6l-2.76-13.2c6.99-1.47 13.52-4.17 19.42-7.79l7.05 11.57a75.014 75.014 0 0019.19-16.78l-10.48-8.53c4.39-5.4 7.84-11.57 10.22-18.25l12.78 4.52a75.067 75.067 0 004.31-25.14c0-3.73-.28-7.47-.82-11.14l-13.41 2.02zm-39.27 26.7l-.56-.46a27.39 27.39 0 01-6.95 6.08l.4.66c-2.68 1.65-5.62 2.92-8.79 3.59l-.17-.82c-3.01.63-6.22.73-9.27.34l-.1.72c-3.22-.43-6.24-1.39-9.02-2.83l.33-.65a27.137 27.137 0 01-7.36-5.54l-.56.54c-2.19-2.32-4-4.98-5.3-7.93l.73-.32a26.838 26.838 0 01-2.22-8.91l-.71.05c-.06-.7-.21-1.38-.21-2.09 0-2.56.46-5 1.1-7.36l.69.19c.81-2.98 2.12-5.78 3.89-8.33l-.66-.46c1.83-2.63 4.11-4.9 6.71-6.77l.45.62c2.53-1.83 5.31-3.17 8.27-4.03l-.2-.68c2.49-.73 5.07-1.24 7.79-1.24.56 0 1.1.14 1.65.17l-.04.73c3.12.19 6.13.88 8.97 2.08l.33-.77c2.96 1.26 5.61 3.07 7.95 5.22l-.51.55c2.3 2.11 4.21 4.56 5.66 7.28l.63-.34a27.77 27.77 0 013 8.97l-.73.11c.2 1.32.3 2.67.3 4.06 0 3.13-.53 6.2-1.56 9.11l.69.25a27.657 27.657 0 01-4.62 8.21zM306.54 252.31c21.34 0 38.64-17.31 38.64-38.65s-17.3-38.64-38.64-38.64c-21.33 0-38.63 17.3-38.63 38.64s17.3 38.65 38.63 38.65z" />
        <path d="M406.39 249.07c4.02-11.37 6.07-23.29 6.07-35.41 0-5.26-.39-10.55-1.16-15.72l-14.63 2.18c-1.57-10.47-4.83-20.38-9.65-29.37L400 163.8a106.313 106.313 0 00-22.04-28.33l-9.99 10.94c-7.61-6.96-16.33-12.73-25.97-16.8l5.7-13.54a105.381 105.381 0 00-35.01-8.12l-.85 14.76c-1.77-.1-3.52-.27-5.31-.27-8.82 0-17.33 1.32-25.41 3.68l-4.1-14.17a105.408 105.408 0 00-32.31 15.74l8.64 12.01c-8.41 6.06-15.77 13.48-21.7 22l-12.12-8.43a105.441 105.441 0 00-15.21 32.58l14.28 3.88c-2.09 7.65-3.3 15.66-3.3 23.96 0 2.29.18 4.54.34 6.8l-14.73 1.09c.9 12.08 3.82 23.82 8.71 34.88l13.5-5.96c4.23 9.55 10.06 18.22 17.14 25.72l-10.77 10.16c8.31 8.8 17.96 16.06 28.7 21.58l6.76-13.14a90.384 90.384 0 0029.53 9.25l-1.92 14.64c4.64.61 9.35.93 14 .93 7.36 0 14.71-.76 21.83-2.27L325.37 303c10.33-2.17 19.98-6.17 28.7-11.53l7.74 12.62a106.23 106.23 0 0026.99-23.64l-11.44-9.3c6.49-7.98 11.58-17.11 15.1-26.98l13.93 4.9zm-42.28-35.41c0 31.75-25.83 57.59-57.57 57.59-31.75 0-57.57-25.83-57.57-57.59 0-31.74 25.83-57.57 57.57-57.57 31.75 0 57.57 25.83 57.57 57.57z" />
      </g>
    </svg>
  );
}
