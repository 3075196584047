import { useQuery } from "react-query";
import axios from "./../../axios";
import { AccountWithHightlightsViewModel } from "./accountModels";

const getGet = async (_: any, ids: string[]) => {
  const { data } = await axios.post<
    AccountWithHightlightsViewModel[]
  >(`/api/account/GetUsersById`, { ids: ids });
  return data;
};

export default function useGetUsersById(
  ids: string[],
  enabled: boolean = true
) {
  return useQuery(["account-get-by-ids", ids], getGet, { enabled: enabled });
}
