import React from "react";
import { NavLink } from "react-router-dom";
import { Box, BoxHeader } from "../components/Box";
import { FINDFRIENDS } from "../routes";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import ButtonAligner from "../components/ButtonAligner";
import useGetFriends from "../hooks/friendship/useGetFriends";
import useGetRequests from "../hooks/friendship/useGetRequests";
import useDeleteFriendship from "../hooks/friendship/useDeleteFriendship";
import useApproveFriendship from "../hooks/friendship/useApproveFriendship";
import UserLine from "./UserLine";

export default function FriendsList() {
  // let match = useRouteMatch();
  const { data: friends } = useGetFriends();
  const { data: friendRequests } = useGetRequests();

  const [approveMutate] = useApproveFriendship();
  const [deleteMutate] = useDeleteFriendship();
  const handleApprove = (friendship: string) => {
    approveMutate(friendship);
  };
  const handleDelete = (friendship: string) => {
    deleteMutate(friendship);
  };
  return (
    <>
      {friendRequests && friendRequests.length > 0 && (
        <Box>
          <BoxHeader>Venneanmodninger</BoxHeader>

          <div>
            {friendRequests.map((x) => {
              return (
                <UserLine
                  key={x.id}
                  user={{
                    firstName: x.firstName,
                    lastName: x.lastName,
                    id: x.userId,
                    picture: x.picture,
                    highlights: [],
                  }}
                >
                  <CustomButton
                    onClick={() => {
                      handleApprove(x.id);
                    }}
                  >
                    Godkend
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      if (window.confirm("Er du sikker?")) {
                        handleDelete(x.id);
                      }
                    }}
                    themecolor={ThemeColor.Black}
                  >
                    Afvis
                  </CustomButton>
                </UserLine>
              );
            })}
          </div>
        </Box>
      )}
      <Box>
        {friends && (
          <div>
            {friends.map((x) => {
              return (
                <UserLine
                  key={x.id}
                  user={{
                    firstName: x.firstName,
                    lastName: x.lastName,
                    id: x.userId,
                    picture: x.picture,
                    highlights: [],
                  }}
                >
                  <CustomButton
                    onClick={() => {
                      if (window.confirm("Er du sikker?")) {
                        handleDelete(x.id);
                      }
                    }}
                    themecolor={ThemeColor.Black}
                  >
                    Fjern venskab
                  </CustomButton>
                </UserLine>
              );
            })}
            {friends.length === 0 && <p>Du har ingen venner endnu.</p>}
          </div>
        )}

        <ButtonAligner>
          <CustomButton component={NavLink} to={FINDFRIENDS}>
            Find nye venner
          </CustomButton>
        </ButtonAligner>
      </Box>
    </>
  );
}
