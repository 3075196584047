import React from "react";
import { NavLink, useParams } from "react-router-dom";
import UserDetails from "../components/UserDetails";
import UserMemberships from "../components/UserMemberships";
import UserPicture from "../components/UserPicture";
import useGetFullUser from "../hooks/account/useGetFullUser";
import useGetUser from "../hooks/account/useGetUser";
import useGetUserfeed from "../hooks/friendship/useGetUserFeed";
import { EDITUSER } from "../routes";
import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";

import useActiveUserBookings from "../hooks/booking/useActiveUserBookings";
import styled from "styled-components";
import { Box, BoxHeader } from "../components/Box";
import { PageHeader } from "../components/PageHeader";
import { PageContent } from "../components/PageContent";
import { useAuthState } from "../Context/AuthProvider";
import useGetRequested from "../hooks/friendship/useGetRequested";
import useRequestFriendship from "../hooks/friendship/useRequestFriendship";
import useGetFriends from "../hooks/friendship/useGetFriends";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import ButtonAligner from "../components/ButtonAligner";
import useClassesByIds from "../hooks/class/useClassesByIds";
import { BookingClass } from "./Booking";
import useGetRequests from "../hooks/friendship/useGetRequests";
import useDeleteFriendship from "../hooks/friendship/useDeleteFriendship";
import useApproveFriendship from "../hooks/friendship/useApproveFriendship";
interface UserParams {
  userId: string;
}
const UserName = styled.div`
  color: var(--white);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
export default function User() {
  let { userId } = useParams<UserParams>();

  const userDetails = useAuthState();
  const isAdmin =
    (userDetails.user &&
      userDetails?.user?.roles?.indexOf("Administrator") > -1) === true;
  const { data: user } = useGetFullUser(userId, isAdmin);
  const { data: publicUser, isLoading: publicuserLoading } = useGetUser(userId);
  const { data: requested } = useGetRequested();
  const { data: requests } = useGetRequests();

  const { data: friends } = useGetFriends();
  const isFriends =
    (friends && friends.find((x) => x.userId === userId)) !== undefined;
  const { data: userFeed } = useGetUserfeed(userId, isFriends || isAdmin);
  const [requestMutate] = useRequestFriendship();

  var request = requests && requests.find((x) => x.userId === userId);

  var canRequest =
    requested &&
    !requested.find((x) => x.userId === userId) &&
    !request &&
    friends &&
    !friends.find((x) => x.userId === userId);

  const handleRequest = (userId: string) => {
    requestMutate(userId);
  };

  const [approveMutate] = useApproveFriendship();
  const [deleteMutate] = useDeleteFriendship();
  const handleApprove = (friendship: string) => {
    approveMutate(friendship);
  };
  const handleDelete = (friendship: string) => {
    deleteMutate(friendship);
  };
  const { data: classes } = useClassesByIds(
    userFeed ? userFeed.map((x) => x.classId) : [],
    userFeed !== undefined && userFeed.length !== 0
  );

  const { data: bookings } = useActiveUserBookings();

  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.large}>
      <PageHeader backButton={true}>Medlem</PageHeader>
      <PageContent>
        {publicUser && (
          <UserPicture user={publicUser} isAdmin={true} canEdit={isAdmin} />
        )}
        {publicUser && (
          <UserName>
            {publicUser.firstName} {publicUser.lastName}
          </UserName>
        )}
        {publicuserLoading && <UserName>Henter info..</UserName>}

        {isAdmin && user && (
          <UserDetails user={user}>
            <ButtonAligner>
              <CustomButton
                component={NavLink}
                to={EDITUSER.replace(":userId", userId)}
                color="secondary"
              >
                Ret oplysninger
              </CustomButton>
            </ButtonAligner>
          </UserDetails>
        )}

        {isAdmin && <UserMemberships userId={userId} />}

        {(isFriends || isAdmin) && (
          <Box>
            <BoxHeader>AKTIVITET</BoxHeader>

            {userFeed &&
              userFeed.map((x) => {
                const classItem = classes?.find((b) => b.id === x.classId);
                const booking = bookings?.find((b) => b.classId === x.classId);
                return (
                  classItem && (
                    <BookingClass
                      key={x.classId + x.userId}
                      booking={booking}
                      class={classItem}
                      showDate={true}
                      activeBookings={bookings ? bookings.filter(x => x.occupyActiveBookingSlot === true).length : 0}
                      user={{
                        firstName: x.firstName,
                        lastName: x.lastName,
                        id: x.userId,
                        picture: x.picture,
                      }}
                    />
                  )
                );
              })}
            {userFeed && userFeed.length === 0 && <>Ingen aktivitet</>}
          </Box>
        )}
        {!isFriends && (
          <Box>
            {canRequest && (
              <ButtonAligner>
                <CustomButton
                  onClick={() => {
                    handleRequest(userId);
                  }}
                >
                  Anmod om venskab
                </CustomButton>
              </ButtonAligner>
            )}
            {request && (
              <ButtonAligner>
                <CustomButton
                  onClick={() => {
                    if (request) handleApprove(request.id);
                  }}
                >
                  Godkend
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    if (window.confirm("Er du sikker?")) {
                      if (request) handleDelete(request.id);
                    }
                  }}
                  themecolor={ThemeColor.Black}
                >
                  Afvis
                </CustomButton>
              </ButtonAligner>
            )}
          </Box>
        )}
      </PageContent>
    </PageContainer>
  );
}
