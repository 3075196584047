import { useQuery } from "react-query";
import axios from "./../../axios";
import { MembershipViewModel } from "./membershipModels";

const getMemberships = async (_: any) => {
  const { data } = await axios.get(`/api/membership/GetPBS`);
  return data;
};

export default function useMembershipPBS() {
  return useQuery<MembershipViewModel[]>(["membership-pbs"], getMemberships);
}
