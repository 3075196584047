import { useQuery } from "react-query";
import axios from "./../../axios"
import { FriendshipViewModel } from "./friendshipModels";


const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/Friendship/GetFriendships`);
  return data;
};

export default function useGetFriends() {
  return useQuery<FriendshipViewModel[]>(["friends"], getClasses);
}
