export interface MembershipViewModel {
  id: string;
  userId: string;
  start: Date;
  expires: Date | null;
  type: MembershipType;
  punches: number;
  usedPunches: number;
  pbs: string;
}

export interface MembershipCreateModel {
  userId: string;
  start: Date;
  expires: Date | null;
  type: MembershipType;
  punches: number;
  PBS: string;
}

export interface MembershipUpdateModel {
  userId: string;
  start: Date;
  expires: Date | null;
  membershipId: string;
  punches: number;
  PBS: string;
}

export enum MembershipType {
  Free = 0,
  Rolling = 1,
  PunchCard = 2
}



export interface MembershipStatsModel {
  totalMemberships: string[];
  freeMemberships: string[];
  rollingMemberships: string[];
  punchCardMemberships:string[];
  expiresSoon: string[];
  expired: string[];
  coaches: string[];
}
