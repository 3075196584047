import { useQuery } from "react-query";
import axios from "./../../axios";
import { MembershipStatsModel } from "./membershipModels";

const getMemberships = async (_: any) => {
  const { data } = await axios.get(`/api/membership/GetStats`);
  return data;
};

export default function useMembershipStats() {
  return useQuery<MembershipStatsModel>(["membership-stats"], getMemberships);
}
