import CustomButton from "../components/CustomButton";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import useGet from "../hooks/account/useGet";
import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";
import { PageHeader } from "../components/PageHeader";
import { Box, BoxHeader } from "../components/Box";
import useMembershipStats from "../hooks/membership/useMembershipStats";
import useMembershipPBS from "../hooks/membership/useMembershipPBS";
import useGetUsersById from "../hooks/account/useGetUsersById";
import useGetNoShows from "../hooks/booking/useGetNoShows";
import UserLine from "../components/UserLine";
import { PageContent } from "../components/PageContent";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
export default function Users() {
  const [page, setPage] = React.useState(1);
  const [title, setTitle] = React.useState<string>("");
  const [ids, setIds] = React.useState<string[]>([]);
  const [query, setQuery] = React.useState("");
  const { data: users, isLoading: usersLoading } = useGet(page, 10, query);
  const { data: stats } = useMembershipStats();
  const { data: PBS } = useMembershipPBS();
  const { data: noshows } = useGetNoShows();
  const { data: usersFromStats, isLoading: usersFromStatsLoading } =
    useGetUsersById(ids, ids.length > 0);

  const { data: noShowUsers } = useGetUsersById(
    noshows !== undefined
      ? noshows
          .map((x) => x.users.map((y) => y.userId))
          .reduce((accumulator, value) => accumulator.concat(value), [])
      : [],
    noshows && noshows.length > 0
  );

  const { data: pbsUsers } = useGetUsersById(
    PBS !== undefined ? PBS.map((x) => x.userId) : [],
    PBS && PBS.length > 0
  );

  const show = (title: string, ids: string[]) => {
    setTitle(title);
    setIds(ids);
  };
  let match = useRouteMatch();
  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.large}>
      <PageHeader>Medlemmer</PageHeader>
      <PageContent>
        <Box>
          <BoxHeader>SØG MEDLEM</BoxHeader>
          <input
            type="text"
            onKeyUp={(e) => {
              setQuery(e.currentTarget.value);
            }}
          />
          {usersLoading && <div>Loading</div>}
          {users && (
            <div>
              {users.items.map((u) => {
                return <UserLine user={u} key={u.id} />;
              })}

              {users.showFirstPage && (
                <>
                  <CustomButton
                    onClick={() => {
                      setPage(1);
                    }}
                  >
                    1
                  </CustomButton>
                  <span>...</span>
                </>
              )}
              {users.pages.map((p) => (
                <CustomButton
                  key={p}
                  onClick={() => {
                    setPage(p);
                  }}
                >
                  {p}
                </CustomButton>
              ))}

              {users.showLastPage && (
                <>
                  <span>...</span>
                  <CustomButton
                    onClick={() => {
                      setPage(users.totalPages);
                    }}
                  >
                    {users.totalPages}
                  </CustomButton>
                </>
              )}
            </div>
          )}
        </Box>

        <Box>
          <BoxHeader>MEDLEM STATISTIK</BoxHeader>
          <p>
            Antal brugere: <strong>{users?.totalRecords}</strong>
          </p>
          <BoxHeader>MEDLEMSKAB STATISTIK</BoxHeader>
          <p>
            Antal aktive medlemskaber:{" "}
            <strong>{stats?.totalMemberships.length}</strong>{" "}
            {stats && (
              <CustomButton
                onClick={() => {
                  show("Aktive", stats.totalMemberships);
                }}
              >
                Vis brugere
              </CustomButton>
            )}{" "}
            <br />
            Antal med <strong>gratis</strong> medlemskab:{" "}
            <strong>{stats?.freeMemberships.length}</strong>.{" "}
            {stats && (
              <CustomButton
                onClick={() => {
                  show("Gratis", stats.freeMemberships);
                }}
              >
                Vis brugere
              </CustomButton>
            )}
            <br />
            Antal med <strong>almindeligt</strong> medlemskab:{" "}
            <strong>{stats?.rollingMemberships.length}</strong>.{" "}
            {stats && (
              <CustomButton
                onClick={() => {
                  show("Almindelig", stats.rollingMemberships);
                }}
              >
                Vis brugere
              </CustomButton>
            )}
            <br />
            Antal med <strong>klippekort</strong> medlemskab:{" "}
            <strong>{stats?.punchCardMemberships.length}</strong>.
            {stats && (
              <CustomButton
                onClick={() => {
                  show("Klippekort", stats.punchCardMemberships);
                }}
              >
                Vis brugere
              </CustomButton>
            )}{" "}
          </p>
          <p>
            Brugere hvis medlemskab <strong>udløber</strong> inden for næste 14
            dage: <strong>{stats?.expiresSoon.length}</strong>{" "}
            {stats && (
              <CustomButton
                onClick={() => {
                  show("Udløber næste 14 dage", stats.expiresSoon);
                }}
              >
                Vis brugere
              </CustomButton>
            )}
          </p>
          <p>
            Brugere hvis medlemskab <strong>udløb</strong> inden for sidste 7
            dage: <strong>{stats?.expired.length}</strong>
            {stats && (
              <CustomButton
                onClick={() => {
                  show("Udløbet sidste 7 dage", stats.expired);
                }}
              >
                Vis brugere
              </CustomButton>
            )}
          </p>
          <p>
            Brugere som er <strong>instrukører:</strong> <strong>{stats?.coaches.length}</strong>
            {stats && (
              <CustomButton
                onClick={() => {
                  show("Instruktører", stats.coaches);
                }}
              >
                Vis brugere
              </CustomButton>
            )}
          </p>

          {title !== "" && (usersFromStats || usersFromStatsLoading) && (
            <>
              <BoxHeader>{title}</BoxHeader>
              <p>
                {usersFromStats &&
                  usersFromStats.map((u) => {
                    return (
                      <div key={u.id}>
                        <Link to={`${match.url}/${u.id}`}>
                          {u.firstName} {u.lastName}
                        </Link>
                      </div>
                    );
                  })}
              </p>
            </>
          )}

          <BoxHeader>IKKE MØDT OP STATISTIK</BoxHeader>
          <p>
            {noshows &&
              noshows.map((x) => {
                return (
                  <div key={x.month}>
                    <p>
                      <strong>{x.month}</strong>
                    </p>
                    {x.users.map((y) => {
                      const user =
                        noShowUsers &&
                        noShowUsers.find((x) => x.id === y.userId);
                      return (
                        <div key={y.userId}>
                          {user && (
                            <Link to={`${match.url}/${user.id}`}>
                              {user.firstName} {user.lastName}
                            </Link>
                          )}{" "}
                          ikke mødt op <strong>{y.count}</strong> gange
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </p>
          <BoxHeader>PBS LISTE</BoxHeader>
          <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Navn</TableCell>
                    <TableCell>PBS</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>Udløb</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PBS &&
                    PBS.map((x) => {
                      const user = pbsUsers && pbsUsers.find((y) => y.id === x.userId);
                      return (
                        <TableRow key={x.id}>
                            <TableCell>
                            {user && (
                                <Link to={`${match.url}/${user.id}`}>
                                  {user.firstName} {user.lastName}
                                </Link>
                              )}{" "}
                            </TableCell>
                            <TableCell>{x.pbs}</TableCell>
                            <TableCell>{moment(x.start).format("LL")}</TableCell>
                            <TableCell>{x.expires ? moment(x.expires).format("LL") : "-"}</TableCell>

                        </TableRow>
                      );
                    })}
                    </TableBody>
              </Table>
            </TableContainer>
        </Box>
      </PageContent>
    </PageContainer>
  );
}
