import { useMutation } from "react-query";
import axios from "./../../axios";
import { RegisterToUserOnDevicePayload } from "./notificationModels";

export default function useRegisterToUserOnDevice() {
  return useMutation<boolean, unknown, RegisterToUserOnDevicePayload>(
    (payload) => {
     // alert(JSON.stringify(payload))
      return axios.post(`/api/notification/RegisterToUserOnDevice`, payload);
    },
    {
      onSuccess: (data, notificationId) => {
       // alert("user register OK")
      }, 
      onError: (data, notificationId) => {
       // alert("user register fail")
      },
    }
  );

  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
