import { AxiosError } from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ErrorSummary, { addServerErrors } from "../../components/ErrorSummary";
import { useAuthState } from "../../Context/AuthProvider";
import { DASHBOARD } from "../../routes";
import qs from 'query-string'
import styled from "styled-components";
import CustomButton from "../../components/CustomButton";
import useResetPassword from "../../hooks/account/useResetPassword";
import Logo from "../../components/Logo";

import TextInput from "../../components/form/TextInput";

import { Capacitor } from "@capacitor/core";

import useRegisterToUserOnDevice from "../../hooks/notification/useRegisterToUserOnDevice";
import { BoxHeader } from "../../components/Box";
import { PasswordResetModel } from "../../hooks/account/accountModels";
import { Device } from "@capacitor/device";
const LogoHolder = styled.div`
  padding-bottom: 40px;
`;

const Spacer = styled.div`
  margin-bottom: 20px;
`;

export default function ResetPassword() {
  
  const history = useHistory();
  const { handleSubmit, errors, setError, control } = useForm<PasswordResetModel>();

  const [registerMutate] = useRegisterToUserOnDevice();
  const [loginMutate] = useResetPassword();
  const { setToken } = useAuthState();
  const onSubmit = (data: PasswordResetModel) => {
    const parsed = qs.parse(window.location.search);

//=> {foo: 'bar'} 

    data.token = decodeURIComponent(parsed.token?.toString() ?? "");
    data.email = decodeURIComponent(parsed.email?.toString() ?? "");
    loginMutate(data)
      .then((result) => {
        if (result?.data) {
          setToken(result?.data);
          if (Capacitor.getPlatform() !== "web") {
            Device.getId().then((info) => {
              //alert(info.uuid)
              registerMutate({ deviceId: info.identifier });
            });
          }
          history.push(DASHBOARD);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };

  return (
    <>
      <LogoHolder>
        <Logo forbackground={false} />
      </LogoHolder>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Spacer>          
          <BoxHeader>Nulstil kodeord</BoxHeader>
          <TextInput
            control={control}
            name="password"
            label="Adgangskode"
            type="password"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="passwordConfirmation"
            label="Gentag adgangskode"
            type="password"
            fullWidth={true}
          />
          <ErrorSummary errors={errors} />
        </Spacer>
        <CustomButton type="submit">Gem ny kode og log ind</CustomButton>
      </form>
    </>
  );
}
