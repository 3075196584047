import styled from "styled-components";
import React from "react";
import { useHistory } from "react-router-dom";

export type PageHeaderProps = React.HTMLAttributes<HTMLHeadingElement> & {
  backButton?: boolean;
};

export function PageHeader({
  backButton = false,
  children,
  ...rest
}: PageHeaderProps) {
  const history = useHistory();
  return (
    <StyledPageHeader {...rest}>
      {backButton && (
        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
      )}
      {children}
    </StyledPageHeader>
  );
}

export function BigPageHeader({
  backButton = false,
  children,
  ...rest
}: PageHeaderProps) {
  const history = useHistory();
  return (
    <StyledBigPageHeader {...rest}>
      {backButton && (
        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
      )}
      {children}
    </StyledBigPageHeader>
  );
}
export const BackButton = styled.div`
  position: absolute;
  cursor: pointer;
  left: 0px;
  top: -4px;
  padding-right: 20px;
  transform: rotate(180deg);
  &:after {
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='white' d='M6.2 1L4.8 2.5l7.4 7.5-7.4 7.5L6.2 19l9-9z'/></svg>");
  }
`;
export const StyledPageHeader = styled.h1`
  position: relative;
  grid-area: pageheader;
  color: white;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
`;

export const StyledBigPageHeader = styled(StyledPageHeader)`
  font-size: 18px;
`;
