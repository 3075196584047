import React from "react";
import { Box } from "../components/Box";
import CustomButton from "../components/CustomButton";
import useFindNewFriend from "../hooks/friendship/useFindNewFriend";
import useGetRequested from "../hooks/friendship/useGetRequested";
import useRequestFriendship from "../hooks/friendship/useRequestFriendship";
import useGetFriends from "../hooks/friendship/useGetFriends";
import UserLine from "./UserLine";
import { CssTextField } from "./form/TextInput";

import styled from "styled-components";
const SearchBar = styled.div`
  margin-bottom: 20px;

  > div {
    width: 100%;
  }
`;
export default function FindFriends() {
  // let match = useRouteMatch();
  const [query, setQuery] = React.useState("");

  const { data: users } = useFindNewFriend(query);
  const { data: requested } = useGetRequested();
  const { data: friends } = useGetFriends();
  const [requestMutate] = useRequestFriendship();

  const handleRequest = (userId: string) => {
    requestMutate(userId);
  };

  return (
    <>
      <Box>
        <SearchBar>
          <CssTextField
            label="Søg efter medlem"
            onChange={(e) => {
              console.log(e.target.value)
              setQuery(e.target.value);
            }}
          /> 
        </SearchBar>
        {users && (
          <div>
            {users.map((u) => {
              var canRequest =
                requested &&
                !requested.find((x) => x.userId === u.id) &&
                friends &&
                !friends.find((x) => x.userId === u.id);
              return (
                <UserLine key={u.id} user={{highlights: [], ...u}}>
                  {canRequest && (
                    <CustomButton
                      onClick={() => {
                        handleRequest(u.id);
                      }}
                    >
                      Anmod venskab
                    </CustomButton>
                  )}
                </UserLine>
              );
            })}
          </div>
        )}
      </Box>
    </>
  );
}
