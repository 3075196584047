import React, { useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import axios from "./../axios";
import useRefreshToken from "../hooks/account/useRefreshToken";
import { LOGIN } from "../routes";
import { useHistory } from "react-router-dom";

export type AuthState = {
  token: string | null;
  user: CurrentUser | null;
};

export type ContextAuthState = AuthState & {
  setToken: (token: string | null) => void;
};

export type CurrentUser = {
  expires: Date;
  userId: string;
  roles: string[];
};

export type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthStateContext = React.createContext<ContextAuthState>({
  setToken: () => {},
  token: null,
  user: null,
});
export interface TokenDto {
  foo: string;
  sub: string;
  exp: number;
  iat: number;
  given_name: string;
  family_name: string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string[];
}

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const decodeTokenIntoUser = (token: string | null): CurrentUser | null => {
    if (!token) {
      return null;
    }
    const decodedToken = jwt_decode<TokenDto>(token);

    const roles =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    return {
      userId: decodedToken.sub,
      expires: new Date(decodedToken.exp * 1000),
      roles: roles ? roles : [],
    };
  };

  const setToken = (token: string | null) => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }

    setUser(decodeTokenIntoUser(token));
  };

  const [user, setUser] = useState<CurrentUser | null>(
    decodeTokenIntoUser(localStorage.getItem("token"))
  );

  const value = {
    token: localStorage.getItem("token"),
    user: user,
    setToken: setToken,
  };

  const [refreshTokenMutate] = useRefreshToken();
  const history = useHistory();
  const hook = () => {
    let isRefreshing = false;
    let failedQueue: any[] = [];

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const status = error.response ? error.response.status : null;
        const header = error.response
          ? error.response.headers["www-authenticate"]
          : null;
        const originalRequest = error.config;
        if (
          status === 401 &&
          header.indexOf("token expired") > -1 &&
          !originalRequest._retry
        ) {
        
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then(() => {              
                const token = localStorage.getItem("token");
                originalRequest.headers["Authorization"] = "Bearer " + token;
                return axios(originalRequest);
              })
              .catch((err) => {               
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          refreshTokenMutate().then((result) => {
            if (result?.data) {
              var token = result?.data;

              localStorage.setItem("token", token);
              setUser(decodeTokenIntoUser(token));
              isRefreshing = false;
              failedQueue.forEach((prom) => {
                prom.resolve();
              });

              failedQueue = [];
            }
          }).catch(() => {
            localStorage.removeItem("token");
            history.push(LOGIN);
          });
        }

        return Promise.reject(error);
      }
    );
  };
  useEffect(hook, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthStateContext.Provider value={value}>
      {children}
    </AuthStateContext.Provider>
  );
};

export default AuthProvider;
