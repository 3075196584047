// src/common/providers/APIErrorProvider/index.js
import React, { useState, useCallback, useEffect } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import useMarkNotificationAsSeen from "../hooks/notification/useMarkNotificationAsSeen";
import { Capacitor } from "@capacitor/core";
import { NotificationTextViewModel } from "../hooks/notification/notificationModels";
import { useAuthState } from "./AuthProvider";
import { App } from "@capacitor/app";

export type SocketContextType = {
  notificationCount: number;
  notifications: UINotification[];

  removeNotification: (id: string, markAsSeen: boolean) => void;
};

export const SocketContext = React.createContext<SocketContextType>({
  notificationCount: 0,
  notifications: [],
  removeNotification: () => { },
});

export interface Notification {
  model: NotificationTextViewModel;
  id: string;
}

export interface UINotification {
  model: NotificationTextViewModel;
  id: string;
}
export interface Message {
  text: string;
  id: string;
}

export type APIErrorProviderProps = {
  children: React.ReactNode;
};

export default function SocketProvider({ children }: APIErrorProviderProps) {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [numNotification, setNumNotification] = useState<number>(0);
  const [notifications, setNotifications] = useState<UINotification[]>([]);
  const [mutate11] = useMarkNotificationAsSeen();
  const authState = useAuthState();

  const userid = authState?.user?.userId;



  useEffect(() => {
    if (connection && Capacitor.getPlatform() !== "web") {
      App.addListener('appStateChange', (state) => {

        if (!state.isActive) {
          if (connection) {
            connection.stop()

          }
     
        }else{
          if (connection) {
            connection.start()

          }
        }
      })
    }

  }, [connection]);

  useEffect(() => {
    if (userid) {
      console.log("new connection");
      const newConnection = new HubConnectionBuilder()
        .withUrl(
          Capacitor.getPlatform() === "web"
            ? "/hubs/chat"
            : "https://crossfit.prokode.dk/hubs/chat",
          {
            accessTokenFactory: () => localStorage.getItem("token") ?? "",
          }
        )
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    } else {
      setConnection(null);
    }
  }, [userid]);


  useEffect(() => {
    if (!userid && connection && connecting === true) {
      console.log("disconnection because logging out");
      console.log(connection.state);
      // connection.off()
      connection
        .stop()
        .then(() => {
          console.log("ok i am disconnected");
        })
        .catch(() => {
          console.log("unable to disconnect");
        });
      console.log(connection.state);
      setConnection(null);
      setConnecting(false);
    }

    if (
      userid &&
      connection &&
      connection.state === HubConnectionState.Disconnected &&
      connecting === false
    ) {
      setConnecting(true);

      connection
        .start()
        .then((result) => {
          console.log("connected111111");
          connection.on("NewNotification", (message: Notification) => {
            var notificationsCpoy = [...notifications];
            notificationsCpoy.push({
              id: message.id,
              model: message.model,
            });
            setNotifications(notificationsCpoy);
          });

          connection.on(
            "UpdateNotificationCount",
            (notificationCount: number) => {
              setNumNotification(notificationCount);
            }
          );

        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [notifications, connection, userid, connecting]);

  const removeNotification2 = (id: string, markAsSeen: boolean) => {
    var arr = notifications.filter((v, i, a) => v.id !== id);
    console.log("removing");
    if (markAsSeen) {
      mutate11([id]);
    }
    setNotifications(arr);
  };

  const contextValue = {
    notificationCount: numNotification,
    notifications: notifications,

    removeNotification: useCallback(
      (id: string, markAsSeen: boolean) => removeNotification2(id, markAsSeen),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
}
