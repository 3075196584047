import React from "react";

import styled, { css } from "styled-components";
import {
  OutlinedInputProps,
  Checkbox,
  withStyles,
  FormControlLabel,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

export type TextInputProps = {
  control: any;
  label: string;
  name: string;

  defaultChecked?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  inputProps?: OutlinedInputProps["inputProps"];
};
export type ExtraProps = {
  fullWidth: boolean;
};
const InputContainer = styled.div<ExtraProps>`
  margin-bottom: 10px;
  ${(props) =>
    props.fullWidth &&
    css`
      > div {
        width: 100%;
      }
    `}
`;

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--red)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--red)",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      //   borderColor: 'red',
      // },
      // '&:hover fieldset': {
      //   borderColor: 'yellow',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "var(--red)",
      },
    },
  },
})(Checkbox);

export default function CheckboxInput({
  control,
  label,
  name,
  inputProps,
  defaultChecked,
  fullWidth = false,
  disabled = false
}: TextInputProps) {
  return (
    <InputContainer fullWidth={fullWidth}>
      <FormControlLabel
        label={label}
        value={defaultChecked}
        defaultChecked={defaultChecked}
        control={
          <Controller
            name={name}
            control={control}
            defaultValue={defaultChecked}
            render={(props) => (
              <Checkbox
                {...props}
                checked={props.value}
                defaultChecked={defaultChecked}
                disabled={disabled}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            )}
          />
        }
      />
    </InputContainer>
  );
}
