import React from "react";

import styled, { css } from "styled-components";
import { OutlinedInputProps, TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

export type TextInputProps = {
  control: any;
  label: string;
  name: string;
  type?: string;
  defaultValue?: string;
  fullWidth?: boolean;
  inputProps?: OutlinedInputProps["inputProps"];
};
export type ExtraProps = {
  fullWidth: boolean;
};
const InputContainer = styled.div<ExtraProps>`
  margin-bottom: 10px;
  ${(props) =>
    props.fullWidth &&
    css`
      > div {
        width: 100%;
      }
    `}
`;

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--red)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--red)",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      //   borderColor: 'red',
      // },
      // '&:hover fieldset': {
      //   borderColor: 'yellow',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "var(--red)",
      },
    },
  },
})(TextField);

export default function TextInput({
  control,
  label,
  name,
  inputProps,
  type = "text",
  defaultValue = "",
  fullWidth = false,
}: TextInputProps) {
  return (
    <InputContainer fullWidth={fullWidth}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        as={
          <CssTextField
            label={label}
            type={type}
            defaultValue={defaultValue}
            inputProps={inputProps}
            // InputLabelProps={{
            //   shrink: false,
            // }}
          />
        }
      />
    </InputContainer>
  );
}
