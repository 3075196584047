import React from "react";
import {  Switch } from "react-router-dom";
import styled from "styled-components";
import AppRoute from "../AppRoute";
import Booking from "../pages/Booking";
import Class from "../pages/Class";
import ClassSetup from "../pages/ClassSetup";
import ClassTypes from "../pages/ClassTypes";
import Dashboard from "../pages/Dashboard";
import DefaultClasses from "../pages/DefaultClasses";
import EditProfile from "../pages/EditProfile";
import EditUser from "../pages/EditUser";
import Friends from "../pages/Friends";
import MyClasses from "../pages/MyClasses";
import Notifications from "../pages/Notifications";
import NotificationsSettings from "../pages/NotificationsSettings";
import Profile from "../pages/Profile";
import User from "../pages/User";
import Users from "../pages/Users";
import {
  BOOKING,
  CLASS,
  CLASSSETUP,
  CLASSTYPES,
  DASHBOARD,
  DEFAULTCLASSES,
  EDITPROFILE,
  EDITUSER,
  FRIENDSACTIVITY,
  MESSAGES,
  MYCLASSES,
  NOTIFICATIONSSETTINGS,
  PROFILE,
  USER,
  USERS,
} from "../routes";
import { Capacitor } from "@capacitor/core";

const StyledContent = styled.div`
  // background: red;
  grid-area: content;
  overflow: auto;
  // padding: 24px;
  @media only screen and (max-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    // height: 100%;
  }
`;

const Content = () => (
  <StyledContent>
    <Switch>
      <AppRoute path={EDITPROFILE} isPrivate={true}>
        <EditProfile />
      </AppRoute>

      <AppRoute path={PROFILE} isPrivate={true}>
        <Profile />
      </AppRoute>

      <AppRoute path={FRIENDSACTIVITY} isPrivate={true}>
        <Friends />
      </AppRoute>
   
      <AppRoute path={NOTIFICATIONSSETTINGS} isPrivate={true}>
        <NotificationsSettings />
      </AppRoute>
      <AppRoute path={MESSAGES} isPrivate={true}>
        <Notifications />
      </AppRoute>
      <AppRoute path={MYCLASSES} isPrivate={true}>
        <MyClasses />
      </AppRoute>
      <AppRoute path={CLASS} isPrivate={true}>
        <Class />
      </AppRoute>
      <AppRoute
        path={CLASSTYPES}
        isPrivate={true}
        requiresRoles="Administrator"
      >
        <ClassTypes />
      </AppRoute>
      <AppRoute
        path={CLASSSETUP}
        isPrivate={true}
        requiresRoles="Administrator"
      >
        <ClassSetup />
      </AppRoute>
      <AppRoute path={EDITUSER} isPrivate={true} requiresRoles="Administrator">
        <EditUser />
      </AppRoute>
      <AppRoute path={USER} isPrivate={true}>
        <User />
      </AppRoute>
      <AppRoute
        path={DEFAULTCLASSES}
        isPrivate={true}
        requiresRoles="Administrator"
      >
        <DefaultClasses />
      </AppRoute>
      <AppRoute path={USERS} isPrivate={true} requiresRoles="Administrator">
        <Users />
      </AppRoute>
      <AppRoute path={BOOKING} isPrivate={true}>
        <Booking />
      </AppRoute>
      <AppRoute path={DASHBOARD} isPrivate={true}>
        <Dashboard />
      </AppRoute>
    </Switch>
  </StyledContent>
);

export default Content;

