import { AxiosResponse } from "axios";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { ClassMessageWriteModel } from "./classModels";

export default function useMessageClass() {
  return useMutationForForms<AxiosResponse<boolean>, unknown, ClassMessageWriteModel>(
    (data: ClassMessageWriteModel) => axios.post(`/api/class/message`, data),
    {
      onSuccess: (data, createData) => {},
    }
  );
}
