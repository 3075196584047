import { useQuery } from "react-query";
import axios from "./../../axios"
import { FriendshipFeedModel } from "./friendshipModels";


const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/Friendship/GetFeed`);
  return data;
};

export default function useGetfeed() {
  return useQuery<FriendshipFeedModel[]>(["friendfeed"], getClasses);
}
