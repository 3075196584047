import React from "react";
import styled from "styled-components";
import Logo from "../../components/Logo";
import { BoxHeader } from "../../components/Box";

const LogoHolder = styled.div`
  padding-bottom: 40px;
`;

export default function DeleteAccount() {

  return (
    <>
      <LogoHolder> 
        <Logo forbackground={false} />
      </LogoHolder>
      <BoxHeader>Sletning af bruger</BoxHeader>
      <p>
       Hvis du gerne vil slette din bruger venligst kontakt info@crossfit8500.dk
      </p>
    
    </>
  );
}
