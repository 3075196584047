import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { SetCoachRoleByUserIdWriteModel } from "./accountModels";

export default function useSetCoachRoleByUserId() {
  return useMutationForForms<string, unknown, SetCoachRoleByUserIdWriteModel>(
    (data: SetCoachRoleByUserIdWriteModel) =>
      axios.post(`/api/account/SetCoachRoleByUserId?userId=${data.userId}&enable=${data.enable}`),
    {
      onSuccess: (data, updateData) => {
        queryCache.invalidateQueries(["account-get-full", updateData.userId]);
      }
    }
  );
}
