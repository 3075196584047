import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";

import { PageHeader } from "../components/PageHeader";
import {
  FINDFRIENDS,
  FRIENDS,
  FRIENDSACTIVITY,
} from "../routes";
import { PageContent } from "../components/PageContent";
import styled from "styled-components";
import FindFriends from "../components/FindFriends";
import FriendsActivity from "../components/FriendsActivity";
import FriendsList from "../components/FriendsList";
import { Capacitor } from "@capacitor/core";

const FriendsContainer = styled(PageContent)`
  grid-gap: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 80px minmax(0, 1fr);
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    grid-template-rows: 50px minmax(0, 1fr);
  }
  grid-template-areas:
    "friendslinks"
    "friendscontent";

  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    overflow-y: auto;
  }
`;

const LinksContainer = styled.div`
  grid-area: friendslinks;
  text-align: center;
`;

const Link = styled(NavLink)`
  display: inline-block;
  padding: 10px 20px;
  margin: 0px 10px;
  text-decoration: none;
  font-weight: 600;

  color: var(--white);
  &:hover{
    color: var(--white);
  }
  border-radius: 16px;
  &.active {
    background: var(--black);
  }
`;
const Content = styled.div`
  grid-area: friendscontent;
`;

export default function Friends() {

  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.large}>
      <PageHeader>venner</PageHeader>
      <FriendsContainer>
        <LinksContainer>
          <Link to={FRIENDSACTIVITY} exact={true} activeClassName="active">
            VENNE AKTIVITET
          </Link>
          <Link to={FRIENDS} exact={true} activeClassName="active">
            MINE VENNER
          </Link>
        </LinksContainer>
        <Content>
          <Switch>
            <Route path={FINDFRIENDS}>
              <FindFriends />
            </Route>
            <Route path={FRIENDS}>
              <FriendsList />
            </Route>
            <Route path={FRIENDSACTIVITY}>
              <FriendsActivity />
            </Route>
          </Switch>
        </Content>
      </FriendsContainer>
    </PageContainer>
  );
}
