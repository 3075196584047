import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";
import AccountAutoComplete from "../components/AccountAutoComplete";
import { Box, BoxHeader } from "../components/Box";
import { PageContainer } from "../components/PageContainer";
import { AccountViewModel } from "../hooks/account/accountModels";
import { BookingState } from "../hooks/booking/bookingModels";
import useBookClassByUserId from "../hooks/booking/useBookClassByUserId";
import useBookingsByClassId from "../hooks/booking/useBookingsByClassId";
import useFriendshipBookingsByClassId from "../hooks/booking/useFriendshipBookingsByClassId";
import useMarkBookingAsNoShowByUserId from "../hooks/booking/useMarkBookingAsNoShowByUserId";
import useMoveUserFromWaitlistToParticipant from "../hooks/booking/useMoveUserFromWaitlistToParticipant";
import useRemoveBookingFromClassByUserId from "../hooks/booking/useRemoveBookingFromClassByUserId";
import useUnmarkBookingAsNoShowByUserId from "../hooks/booking/useUnmarkBookingAsNoShowByUserId";
import {
  ClassMessageWriteModel,
  ClassViewModel,
} from "../hooks/class/classModels";
import useClass from "../hooks/class/useClass";

import useActiveUserBookings from "../hooks/booking/useActiveUserBookings";
import { PageHeader } from "../components/PageHeader";
import { PageContent } from "../components/PageContent";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import UserLine from "../components/UserLine";
import { useAuthState } from "../Context/AuthProvider";
import { BookingClass } from "./Booking";
import ButtonAligner from "../components/ButtonAligner";
import { useForm } from "react-hook-form";
import useMessageClass from "../hooks/class/useMessageClass";
import TextInput from "../components/form/TextInput";
import ErrorSummary, { addServerErrors } from "../components/ErrorSummary";
import { AxiosError } from "axios";
import Dropdown from "../components/form/Dropdown";
import useRebuildBookingProjectionsByClassId from "../hooks/booking/useRebuildBookingProjectionsByClassId";
import useBookingHistoryByClassId from "../hooks/booking/useBookingHistoryByClassId";
import { USER } from "../routes";
interface ClassParama {
  classId: string;
}

export default function Class() {
  let { classId } = useParams<ClassParama>();
  const { data: _class, isLoading: classLoading } = useClass(classId);

  const userDetails = useAuthState();

  const { data: bookings } = useActiveUserBookings();
  const booking = bookings?.find((b) => b.classId === classId);
  return (
    <PageContainer>
      <PageHeader backButton={true}>HOLD INFO</PageHeader>
      <PageContent>
        {classLoading && <Box>Henter info..</Box>}

        {_class && (
          <Box>
            <div>
              <BookingClass
                booking={booking}
                class={_class}
                showDate={true}
                activeBookings={
                  bookings
                    ? bookings.filter((x) => x.occupyActiveBookingSlot === true)
                        .length
                    : 0
                }
              />
            </div>
            {userDetails.user && userDetails.user.roles.indexOf("Coach") > -1 && (
              <div>
                Pladser: {_class.bookings}/{_class.slots}
              </div>
            )}
          </Box>
        )}

        {userDetails.user && userDetails.user.roles.indexOf("Coach") > -1 && (
          <>
            <Bookings classItem={_class} />
            <BookingHistory />
          </>
        )}

        {userDetails.user && userDetails.user.roles.indexOf("Coach") === -1 && (
          <FriendshipBookings classItem={_class} />
        )}
      </PageContent>
    </PageContainer>
  );
}
interface BookingsProps {
  classItem: ClassViewModel | undefined;
}

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 35px;
`;

function BookingHistory(){
  let { classId } = useParams<ClassParama>();
  const { data: history } = useBookingHistoryByClassId(classId);

  
  return  <Box>
          <BoxHeader>Booking historik</BoxHeader>

          {history && history.map(x => {
            return(
            <div><strong>{moment(x.timeOfEvent).format("DD/MM HH:mm")} </strong>
              <NavLink to={USER.replace(":userId", x.userId ?? "unknown")}>
              {x.userName}
            </NavLink> {x.text}
            {x.issuedByUserId !== x.userId && <span> Af <NavLink to={USER.replace(":userId", x.issuedByUserId ?? "unknown")}>
              {x.issuedByUserName}
            </NavLink></span>}
            </div>)

          })}
          </Box>
}
function Bookings(props: BookingsProps) {
  let { classId } = useParams<ClassParama>();
  const { data: bookings } = useBookingsByClassId(classId);
  const [useRemoveBookingFromClassByUserIdMutate] =
    useRemoveBookingFromClassByUserId();

  const [useMarkBookingAsNoShowByUserIdMutate] =
    useMarkBookingAsNoShowByUserId();

  const [useRebuildBookingProjectionsByClassIdMutate] =
    useRebuildBookingProjectionsByClassId();

  const [useUnmarkBookingAsNoShowByUserIdMutate] =
    useUnmarkBookingAsNoShowByUserId();
  const [useMoveUserFromWaitlistToParticipantMutate] =
    useMoveUserFromWaitlistToParticipant();

  function RemoveBooking(classId: string, userId: string) {
    useRemoveBookingFromClassByUserIdMutate({
      classId: classId,
      userId: userId,
    });
  }

  function RebuildBookingProjections(classId: string) {
    useRebuildBookingProjectionsByClassIdMutate({
      classId: classId,
    }).then(() => {
      alert("Rebuilt");
    })
  }

  function MarkAsNoShow(classId: string, userId: string) {
    useMarkBookingAsNoShowByUserIdMutate({
      classId: classId,
      userId: userId,
    });
  }

  function UnmarkAsNoShow(classId: string, userId: string) {
    useUnmarkBookingAsNoShowByUserIdMutate({
      classId: classId,
      userId: userId,
    });
  }

  function MoveUserFromWaitlistToParticipant(classId: string, userId: string) {
    useMoveUserFromWaitlistToParticipantMutate({
      classId: classId,
      userId: userId,
    });
  }

  const [open, setOpen] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const handleClickOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [useBookClassByUserIdMutate] = useBookClassByUserId();

  function BookClassByUserId(classId: string, userId: string) {
    useBookClassByUserIdMutate({ classId: classId, userId: userId });
  }
  const [accountToAdd, setAccountToAdd] =
    React.useState<AccountViewModel | null>(null);

  const filtered = bookings
    ? bookings.filter(
        (x) =>
          x.state === BookingState.Participating ||
          x.state === BookingState.NoShow
      )
    : undefined;
  const waitlist = bookings
    ? bookings.filter((x) => x.state === BookingState.WaitingList)
    : undefined;
  var bookingEnabled =
    props.classItem === undefined || !props.classItem.cancelled;

  var classStarted =
    props.classItem !== undefined && props.classItem.start > new Date();
  const {
    handleSubmit: handleMessageSubmit,
    setError,
    errors,
    control,
  } = useForm<ClassMessageWriteModel>();

  const [messageMutate] = useMessageClass();
  const onSubmit = (data: ClassMessageWriteModel) => {
    data.classId = classId;
    messageMutate(data)
      .then(() => {
        setOpenMessage(false);
        alert("Besked sendt");
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };
  const userDetails = useAuthState();
  return (
    <>
      {bookings && (
        <Box>
          <BoxHeader>Deltagere ({filtered && filtered.length})</BoxHeader>
          {/* {isLoading && <div>Loader</div>} */}

          <div>
            {filtered &&
              filtered.map((x) => {
                return (
                  <UserLine key={x.userId} user={x.user} position={x.position}>
                    {!classStarted &&
                      bookingEnabled &&
                      x.state === BookingState.Participating && (
                        <CustomButton
                          onClick={() => {
                            if (
                              window.confirm(
                                "Marker " +
                                  x.user.firstName +
                                  " som ikke mødt op?"
                              )
                            )
                              MarkAsNoShow(classId, x.userId);
                          }}
                          themecolor={ThemeColor.Grey2}
                        >
                          Mødte ikke op
                        </CustomButton>
                      )}
                    {bookingEnabled && x.state === BookingState.NoShow && (
                      <CustomButton
                        onClick={() => UnmarkAsNoShow(classId, x.userId)}
                      >
                        Mødte op alligevel
                      </CustomButton>
                    )}
                    {bookingEnabled && (
                      <CustomButton
                        onClick={() => {
                          if (
                            window.confirm(
                              "Fjern " + x.user.firstName + " fra hold?"
                            )
                          )
                            RemoveBooking(classId, x.userId);
                        }}
                        themecolor={ThemeColor.Black}
                      >
                        Afmeld
                      </CustomButton>
                    )}
                  </UserLine>
                );
              })}
          </div>
          <br />
          <br />
          {waitlist && waitlist.length > 0 && (
            <>
              <BoxHeader>Venteliste</BoxHeader>
              <div>
                {waitlist &&
                  waitlist.map((x) => {
                    return (
                      <UserLine
                        key={x.userId}
                        user={x.user}
                        position={x.position}
                      >
                        <CustomButton
                          onClick={() => {
                            if (
                              window.confirm(
                                "Flyt " +
                                  x.user.firstName +
                                  " fra venteliste til deltager?"
                              )
                            )
                              MoveUserFromWaitlistToParticipant(
                                classId,
                                x.userId
                              );
                          }}
                          themecolor={ThemeColor.Grey2}
                        >
                          Flyt til deltagere
                        </CustomButton>
                        <CustomButton
                          onClick={() => {
                            if (
                              window.confirm(
                                "Fjern " + x.user.firstName + " fra hold?"
                              )
                            )
                              RemoveBooking(classId, x.userId);
                          }}
                          themecolor={ThemeColor.Black}
                        >
                          Afmeld
                        </CustomButton>
                      </UserLine>
                    );
                  })}
              </div>
            </>
          )}

          <div>
            <ButtonAligner>
              {bookingEnabled && (
                <CustomButton onClick={handleClickOpen}>
                  Tilføj deltager til hold
                </CustomButton>
              )}
              <CustomButton onClick={handleClickOpenMessage}>
                Send besked til deltagere
              </CustomButton>
              {userDetails.user &&
                userDetails.user.roles.indexOf("Administrator") > -1 && (
                  <CustomButton
                    onClick={() => {
                      RebuildBookingProjections(classId);
                    }}
                  >
                    Rebuild booking projections
                  </CustomButton>
                )}
            </ButtonAligner>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Tilføj deltager</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tilføj en deltager. Det er ikke muligt at tilføje deltager,
                  hvis de ikke har aktivt medlemskab.
                </DialogContentText>
                <AccountAutoComplete
                  onAccountSelected={(account) => {
                    setAccountToAdd(account);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <CustomButton onClick={handleClose}>Annuller</CustomButton>
                <CustomButton
                  onClick={() => {
                    setOpen(false);
                    if (accountToAdd) {
                      BookClassByUserId(classId, accountToAdd.id);
                    }
                  }}
                  disabled={accountToAdd === null}
                >
                  Tilføj medlem på hold
                </CustomButton>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openMessage}
              onClose={handleCloseMessage}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Send besked til deltagere
              </DialogTitle>
              <form noValidate onSubmit={handleMessageSubmit(onSubmit)}>
                <DialogContent>
                  <Dropdown
                    label="Modtagere"
                    name="to"
                    control={control}
                    fullWidth={true}
                    defaultValue={"1"}
                  >
                    <MenuItem value={0} title="Alle">
                      Alle
                    </MenuItem>
                    <MenuItem value={"1"} title="Kun deltagere">
                      Kun deltagere
                    </MenuItem>
                    <MenuItem value={2} title="Kun veneliste">
                      Kun veneliste
                    </MenuItem>
                  </Dropdown>
                  <TextInput
                    label="Besked"
                    name="message"
                    control={control}
                    fullWidth={true}
                    defaultValue={""}
                  />

                  <ErrorSummary errors={errors} />
                </DialogContent>
                <DialogActions>
                  <CustomButton onClick={handleCloseMessage} color="primary">
                    Annuller
                  </CustomButton>
                  <CustomButton type="submit" color="primary">
                    Send besked
                  </CustomButton>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        </Box>
      )}
    </>
  );
}

function FriendshipBookings(props: BookingsProps) {
  let { classId } = useParams<ClassParama>();
  const { data: bookings } = useFriendshipBookingsByClassId(classId);

  const filtered = bookings
    ? bookings.filter(
        (x) =>
          x.state === BookingState.Participating ||
          x.state === BookingState.NoShow
      )
    : undefined;
  const waitlist = bookings
    ? bookings.filter((x) => x.state === BookingState.WaitingList)
    : undefined;

  return (
    <>
      {bookings && (
        <Box>
          <BoxHeader>Venner som deltager</BoxHeader>
          {filtered && filtered.length === 0 && (
            <div>Ingen af dine venner deltager på holdet</div>
          )}
          <div>
            {filtered &&
              filtered.map((x) => {
                return <UserLine key={x.userId} user={x.user}></UserLine>;
              })}
          </div>
          <br />
          <br />
          {waitlist && waitlist.length > 0 && (
            <>
              <BoxHeader>Venner på venteliste</BoxHeader>
              <div>
                {waitlist &&
                  waitlist.map((x) => {
                    return <UserLine key={x.userId} user={x.user}></UserLine>;
                  })}
              </div>
            </>
          )}
        </Box>
      )}
    </>
  );
}
