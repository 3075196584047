import { useQuery } from "react-query";
import axios from "./../../axios"
import {  MembershipViewModel } from "./membershipModels";


const getMemberships = async (_: any) => {
  const { data } = await axios.get(`/api/Membership`);
  return data;
};

export default function useGetMemberships() {
  return useQuery<MembershipViewModel[]>(["memberships"], getMemberships);
}
