import { useQuery } from "react-query";
import axios from "./../../axios";
import { BookingViewModel } from "./bookingModels";

const getBookings = async (_: any, membershipId: string) => {
  const { data } = await axios.get(
    `/api/booking/GetByMembershipId?membershipId=${membershipId}`
  );
  return data;
};

export default function useBookingsByMembershipId(
  membershipId: string,
  enabled: boolean = true
) {
  return useQuery<BookingViewModel[]>(
    ["bookingsByMembershipId", membershipId],
    getBookings,
    {
      enabled: enabled,
    }
  );
}
