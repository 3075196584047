import React from "react";

import useActiveUserBookings from "../hooks/booking/useActiveUserBookings";
import useClasses from "../hooks/class/useClasses";
import { BookingClass } from "../pages/Booking";

export default function MyClasses() {
  // let match = useRouteMatch();

  const { data: classes, isLoading: classesIsLoading } = useClasses();
  const {
    data: bookings,
    isLoading: bookingsLoading,
  } = useActiveUserBookings();

  if (bookingsLoading || classesIsLoading) {
    return <div>Loading</div>;
  }
  return (
    <div>
      {bookings &&
        bookings.map((x) => {
          const classItem = classes?.find((c) => c.id === x.classId);
          return (
            classItem && (
              <BookingClass
                key={classItem.id}
                activeBookings={bookings ? bookings.filter(x => x.occupyActiveBookingSlot === true).length : 0}
                showDate={true}
                booking={x}
                class={classItem}
              />
            )
          );
        })}
    </div>
  );
}
