import { AxiosResponse } from "axios";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { PasswordResetModel } from "./accountModels";

export default function useResetPassword() {
  return useMutationForForms<AxiosResponse<string>, unknown, PasswordResetModel>(
    (data: PasswordResetModel) => axios.post(`/api/account/PasswordReset`, data),
    {
      onSuccess: (data, updateData) => {
        
        
      },
    }
  );
}
