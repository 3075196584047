import { useQuery } from "react-query";
import axios from "../../axios"
import { ClassViewModel } from "./classModels";

const getClasses = async (_: any, date:string) => {
  const { data } = await axios.get(`/api/class/GetByDate?date=${date}`);
  return data;
};

export default function useGetClassesByDate(year: number, month: number, day: number) {
  
  return useQuery<ClassViewModel[]>(["classes", `${year}-${month}-${day}`], getClasses);
}
