import { useQuery } from "react-query";
import axios from "./../../axios";
import { AccountViewModel } from "./accountModels";

const getGet = async (_: any, userId: string) => {
  const { data } = await axios.get<AccountViewModel>(
    `/api/account/getuser`,
    { params: { userId } }
  );
  return data;
};

export default function useGetUser(
  userId: string,
  enabled: boolean = true
) {
  return useQuery(["account-get", userId], getGet, { enabled: enabled });
}
