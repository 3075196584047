import { Capacitor } from "@capacitor/core";

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../Context/AuthProvider";
import useUnRegisterToUserOnDevice from "../../hooks/notification/useUnRegisterToUserOnDevice";
import { LOGIN } from "../../routes";
import { Device } from "@capacitor/device";

export default function LogOut() {
  const history = useHistory();

  const { setToken } = useAuthState();

  const [unRegisterMutate] = useUnRegisterToUserOnDevice();

  const logout  = () => {
    if (Capacitor.getPlatform() !== "web") {
      Device.getId().then((info) => {
        unRegisterMutate({ deviceId: info.identifier });
      });
    }
    
    window.setTimeout(() => {
      setToken(null);
      history.push(LOGIN);
    }, 1000);
  };
  useEffect(logout, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <h2>Logger ud....</h2>;
}
