import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { SetPictureWriteModelById } from "./accountModels";

export default function useSetPictureById() {
  return useMutationForForms<unknown, unknown, SetPictureWriteModelById>(
    (data: SetPictureWriteModelById) =>
      axios.post(
        `/api/account/SetPictureByUserId?userId=${data.userId}`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event: any) => {
            console.log(Math.round((100 * event.loaded) / event.total));
          },
        }
      ),
    {
      onSuccess: (data, updateData) => {
        queryCache.invalidateQueries(["me"]);
      },
    }
  );
}
