import { useMutation } from "react-query";
import axios from "./../../axios";
import { queryCache } from "react-query";
import { SaveSettingsModel } from "./notificationModels";

export default function useSaveNotificationsSettings() {
  return useMutation<boolean, unknown, SaveSettingsModel>(
    (payload) => {
      return axios.post(`/api/notification/SaveSettings`, payload);
    },
    {
      onSuccess: (data, notificationId) => {
        // alert("ok ok")
        // Query Invalidations
        queryCache.invalidateQueries(["notificationsSettings"]);
      },
    }
  );

  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
