import React from "react";
import {  ErrorOption, FieldName, useForm } from "react-hook-form";
import {
  FullAccountViewModel,
  SetCoachRoleByUserIdWriteModel,
} from "../hooks/account/accountModels";
import { BoxHeader } from "./Box";
import ButtonAligner from "./ButtonAligner";
import CustomButton, { ThemeColor } from "./CustomButton";
import ErrorSummary from "./ErrorSummary";
import CheckboxInput from "./form/CheckboxInput";

export type EditUserEmailNotificationSettingsProps = {
  user: FullAccountViewModel;
  handleCancelEdit: () => void;
  handleSubmitEdit: (
    data: SetCoachRoleByUserIdWriteModel,
    setError: (name: FieldName<SetCoachRoleByUserIdWriteModel>, error: ErrorOption) => void
  ) => void;
};

const EditUserCoachSettings = ({
  user,
  handleCancelEdit,
  handleSubmitEdit,
}: EditUserEmailNotificationSettingsProps) => {
  const {
    handleSubmit,
    setError,
    errors,
    control,
  } = useForm<SetCoachRoleByUserIdWriteModel>();

  const onSubmit = (data: SetCoachRoleByUserIdWriteModel) => {
    console.log('data', data);
    handleSubmitEdit(data, setError);
  };
  return (
    <>
      {user && (
        <div>
          <BoxHeader>Rolle indstillinger</BoxHeader>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <CheckboxInput
              label="Bruger er indstruktør"
              name="enable"
              control={control}
              fullWidth={true}
              defaultChecked={user.isCoach}
            />
           
            <ErrorSummary errors={errors} />
            <br />
            <ButtonAligner>
              <CustomButton type="submit">Gem indstillinger</CustomButton>
              <CustomButton
                onClick={handleCancelEdit}
                themecolor={ThemeColor.Black}
              >
                Annuller
              </CustomButton>
            </ButtonAligner>
          </form>
        </div>
      )}
    </>
  );
};

export default EditUserCoachSettings;
