import { AxiosError } from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { BoxHeader } from "../../components/Box";

import styled from "styled-components";
import CustomButton from "../../components/CustomButton";
import ErrorSummary, { addServerErrors } from "../../components/ErrorSummary";
import { RegisterModel } from "../../hooks/account/accountModels";
import { DASHBOARD, LOGIN } from "../../routes";
import useRegisterAccount from "../../hooks/account/useRegisterAccount";
import { useAuthState } from "../../Context/AuthProvider";
import TextInput from "../../components/form/TextInput";

const Spacer = styled.div`
  margin-bottom: 20px;
  
  `;
export default function AccountRegister() {
  const history = useHistory();
  const { handleSubmit, errors, setError, control } = useForm<RegisterModel>();
  const { setToken } = useAuthState();
  const [registerAccountMutate] = useRegisterAccount();
  const onSubmit = (data: RegisterModel) => {
    registerAccountMutate(data)
      .then((result) => {
        if (result?.data) {
          setToken(result?.data);

          history.push(DASHBOARD);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
    // loginUser(dispatch, data)
    //   .then((dd) => {
    //     history.push(DASHBOARD);
    //   })
    //   .catch((err: AxiosError) => {
    //     if (err.response?.data.errors) {
    //       addServerErrors(err.response?.data.errors, setError);
    //     }
    //   });
  };
  const handleCancelEdit = () => {
    history.push(LOGIN);
  };
  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Spacer>
          <BoxHeader>LOGIN OPLYSNIGER</BoxHeader>
          <TextInput
            control={control}
            name="email"
            label="Email adresse"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="password"
            label="Adgangskode"
            type="password"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="passwordConfirmation"
            label="Gentag adgangskode"
            type="password"
            fullWidth={true}
          />
        </Spacer>
        <Spacer>
          <BoxHeader>PERSNOLIGE OPLYSNIGER</BoxHeader>
          <TextInput
            control={control}
            name="firstName"
            label="Fornavn"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="lastName"
            label="Efternavn"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="address"
            label="Adresse"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="zipCode"
            label="Postnr"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="city"
            label="By"
            fullWidth={true}
          />
          <TextInput
            control={control}
            name="phone"
            label="Telefon"
            fullWidth={true}
          />
          <ErrorSummary errors={errors} />
        </Spacer>
        <CustomButton type="submit">Opret bruger</CustomButton> eller{" "}
        <CustomButton onClick={handleCancelEdit} color="primary">
          Annuller
        </CustomButton>
      </form>
    </>
  );
}
