import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";
import { CancelClassModel } from "./classModels";

export default function useCancelClass() {
  return useMutationWithError<boolean, unknown, CancelClassModel>(
    ({ classId }) => axios.delete(`/api/class/cancel/${classId}`),
    {
      onSuccess: (data, payload) => {
        // Query Invalidations
        var converted = new Date(payload.date);
       
        queryCache.invalidateQueries(["classes", `${converted.getFullYear()}-${converted.getMonth() + 1}-${converted.getDate()}`]);
      },
    }
  );
}
