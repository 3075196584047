import moment from "moment";
import React, { useEffect } from "react";
import { Box } from "../components/Box";
import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";
import { PageHeader } from "../components/PageHeader";
import {
  NotificationState,
  NotificationTextViewModel,
  NotificationType,
} from "../hooks/notification/notificationModels";
import useGetNotifications from "../hooks/notification/useGetNotifications";
import useMarkAllNotificationsAsSeen from "../hooks/notification/useMarkAllNotificationsAsSeen";
import { NotificationText } from "../notifactionTextResolver";

import styled, { css } from "styled-components";
import { PageContent } from "../components/PageContent";
import {
  // NavLink, 
  useHistory,
} from "react-router-dom";
import {
  CLASS,
  // NOTIFICATIONSSETTINGS,
  USER,
} from "../routes";
import useMarkNotificationAsRead from "../hooks/notification/useMarkNotificationAsRead";
//import { PushNotifications } from '@capacitor/push-notifications';
// import ButtonAligner from "../components/ButtonAligner";
// import CustomButton from "../components/CustomButton";

type NotificationProps = {
  state: NotificationState;
};
const Notification = styled.div<NotificationProps>`
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey3);
    padding-bottom: 10px;
  }

  &:not(:first-child) {
    padding-top: 10px;
  }

  cursor: pointer;
  ${(props) =>
    props.state === NotificationState.Unread &&
    css`
      background: rgba(236, 236, 236, 0.5);
    `}
  ${(props) =>
    props.state === NotificationState.Seen &&
    css`
      background: rgba(236, 236, 236, 0.25);
    `}
`;

const Time = styled.div`
  text-transform: uppercase;
  // font-weight: 600;
  color: var(--grey2);
`;

export default function Notifications() {
  const notifications = useGetNotifications();
  const [mutate11] = useMarkAllNotificationsAsSeen();
  const [mutate22] = useMarkNotificationAsRead();
  const history = useHistory();
  const handleNotificationClick = (notification: NotificationTextViewModel) => {
    mutate22([notification.id]);
    switch (notification.type) {
      case NotificationType.ClassTimeUpdated:
      case NotificationType.UserAddedAsParticipantOnClass:
      case NotificationType.UserRemovedAsParticipantOnClass:
      case NotificationType.UserRemovedFromWaitlistOnClass:
      case NotificationType.UserRemovedAsParticipantOnClassBecauseClassCancelled:
      case NotificationType.UserRemovedFromWaitlistOnClassBecauseClassCancelled:
      case NotificationType.UserMovedFromWaitlistToParticipantOnClass:
      case NotificationType.UserAddedToWaitlistOnClass:
      case NotificationType.UserMarkedAsNoShowOnClass:
      case NotificationType.UserUnmarkedAsNoShowOnClass:
      case NotificationType.UserWaitlistPositionChangedOnClass:
        history.push(CLASS.replace(":classId", notification.data.classId));
        break;
      case NotificationType.FriendshipApproved:
      case NotificationType.FriendshipCreated:
        history.push(USER.replace(":userId", notification.data.userId));
        break;
    }
  };

  useEffect(() => {

    if (notifications.data) {
       mutate11();
    }
  }, [mutate11, notifications.data]);
  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.normal}>
      <PageHeader>Beskeder</PageHeader>
      <PageContent>
        <Box>
          {notifications.isLoading && <div>Loader</div>}
          {notifications.data &&
            notifications.data.map((x) => {
              var text = <NotificationText model={x} />;
              return (
                text && (
                  <Notification
                    state={x.state}
                    key={x.id}
                    onClick={() => handleNotificationClick(x)}
                  >
                    {text}
                    <Time>{moment(x.createdTimestamp).fromNow()}</Time>
                  </Notification>
                )
              );
            })}
        </Box>
        {/* <Box>
          <ButtonAligner>
          <CustomButton component={NavLink} to={NOTIFICATIONSSETTINGS}>
              Notifikationsindstillinger
            </CustomButton>
          </ButtonAligner>
        </Box> */}
      </PageContent>
    </PageContainer>
  );
}
