import { queryCache } from "react-query";
import axios from "../../axios"
import useMutationWithError from "../useMutationWithError";
import { DefaultClassCreateModel, DefaultClassViewModel } from "./defaultClassModels";


export default function useCreateDefaultClass() {
  return useMutationWithError<DefaultClassViewModel, unknown, DefaultClassCreateModel>(
    (data: DefaultClassCreateModel) => axios.post(`/api/defaultclass`, data),
    {
      onSuccess: () => {
        // Query Invalidations
        queryCache.invalidateQueries("defaultClasses");
      },
    }
  );
}
