import { useQuery } from "react-query";
import axios from "./../../axios"
import { DefaultClassViewModel } from "./defaultClassModels";


const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/defaultclass`);
  return data;
};

export default function useDefaultClasses() {
  return useQuery<DefaultClassViewModel[]>(["defaultClasses"], getClasses);
}
