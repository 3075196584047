import { useEffect } from "react";
import { useMutation } from "react-query";
import useAPIError from "./useAPIError";
import {
  MutationResultPair,
  MutationFunction,
  MutationConfig,
} from "react-query/types/core";

export default function useMutationWithError<
  TResult,
  TError = unknown,
  TVariables = undefined,
  TSnapshot = unknown
>(
  mutationFn: MutationFunction<TResult, TVariables>,
  config?: MutationConfig<TResult, TError, TVariables, TSnapshot>
): MutationResultPair<TResult, TError, TVariables, TSnapshot> {
  const { addError } = useAPIError();

  const mutation = useMutation<TResult, TError, TVariables, TSnapshot>(
    mutationFn,
    config
  );

  const error = mutation[1].error;

  useEffect(() => {
    if (error) {
      addError((error as any).response.data, (error as any).response.status);
    }
  }, [addError, error]);

  return mutation;
}
