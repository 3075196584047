import React from "react";
import { NavLink } from "react-router-dom";
import { Box, BoxHeader } from "../components/Box";
import useGetFeed from "../hooks/friendship/useGetFeed";
import useClassesByIds from "../hooks/class/useClassesByIds";
import useActiveUserBookings from "../hooks/booking/useActiveUserBookings";
import { BookingClass } from "./../pages/Booking";
import { FINDFRIENDS } from "../routes";
import CustomButton, { ThemeColor } from "../components/CustomButton";
import ButtonAligner from "../components/ButtonAligner";

import useGetRequests from "../hooks/friendship/useGetRequests";
import useDeleteFriendship from "../hooks/friendship/useDeleteFriendship";
import useApproveFriendship from "../hooks/friendship/useApproveFriendship";
import UserLine from "./UserLine";
export default function FriendsActivity() {
  // let match = useRouteMatch();
  const { data: feed } = useGetFeed();

  const { data: classes } = useClassesByIds(
    feed ? feed.map((x) => x.classId) : [],
    feed !== undefined && feed.length !== 0
  );

  const { data: bookings } = useActiveUserBookings();
  const { data: friendRequests } = useGetRequests();

  const [approveMutate] = useApproveFriendship();
  const [deleteMutate] = useDeleteFriendship();
  const handleApprove = (friendship: string) => {
    approveMutate(friendship);
  };
  const handleDelete = (friendship: string) => {
    deleteMutate(friendship);
  };
  return (
    <> 
      {friendRequests && friendRequests.length > 0 && (
        <Box>
          <BoxHeader>Venneanmodninger</BoxHeader>

          <div>
            {friendRequests.map((x) => {
              return (
                <UserLine
                  key={x.id}
                  user={{
                    firstName: x.firstName,
                    lastName: x.lastName,
                    id: x.userId,
                    picture: x.picture,
                    highlights: []
                  }}
                >
                  <CustomButton
                    onClick={() => {
                      handleApprove(x.id);
                    }}
                  >
                    Godkend
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      if (window.confirm("Er du sikker?")) {
                        handleDelete(x.id);
                      }
                    }}
                    themecolor={ThemeColor.Black}
                  >
                    Afvis
                  </CustomButton>
                </UserLine>
              );
            })}
          </div>
        </Box>
      )}
      <Box>
        {feed && (
          <>
            {feed.map((x) => {
              const classItem = classes?.find((b) => b.id === x.classId);
              const booking = bookings?.find((b) => b.classId === x.classId);
              return (
                classItem && (
                  <BookingClass
                    key={x.classId + x.userId}
                    booking={booking}
                    class={classItem}
                    showDate={true}
                    activeBookings={bookings ? bookings.filter(x => x.occupyActiveBookingSlot === true).length : 0}
                    user={{
                      firstName: x.firstName,
                      lastName: x.lastName,
                      id: x.userId,
                      picture: x.picture,
                    }}
                  />
                )
              );
            })}
            {feed.length === 0 && (
              <div>
                <p>Ingen af dine venner har noget aktivitet</p>

                <ButtonAligner>
                  <CustomButton component={NavLink} to={FINDFRIENDS}>
                    Find nye venner
                  </CustomButton>
                </ButtonAligner>
              </div>
            )}
          </>
        )}
      </Box>
    </>
  );
}
