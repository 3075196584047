import styled from "styled-components";

export const Box = styled.div`
  background: white;
  margin: 10px;
  margin-top: 0px;
  padding: 20px;
`;
export const BoxHeader = styled.h2`
  color: white;
  font-size: 12px;
  color: var(--black);
  text-transform: uppercase;
  padding-top: 0px;
  margin-top: 0px;
`;
