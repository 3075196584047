import { useQuery } from "react-query";
import axios from "./../../axios";
import { NoShowMonthModel } from "./bookingModels";

const getMemberships = async (_: any) => {
  const { data } = await axios.get(`/api/booking/GetNoShows`);
  return data;
};

export default function useGetNoShows() {
  return useQuery<NoShowMonthModel[]>(["GetNoShows"], getMemberships);
}
