import React from "react";

import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import {
  LOGOUT,
  LOGIN,
  REGISTERUSER,
  PRIVACYPOLICY,
  SUPPORT,
  REQUESTPASSWORDRESET,
  PASSWORDRESET,
  DELETEACCOUNT,
} from "../../routes";
import Login from "./LogIn";
import LogOut from "./LogOut";
import AccountRegister from "./AccountRegister";
import PrivacyPolicy from "./PrivacyPolicy";
import Support from "./Support";
import RequestResetPassword from "./RequestResetPassword";
import ResetPassword from "./ResetPassword";
import DeleteAccount from "./DeleteAccount";

const Box = styled.div`
  width: 350px;
  background: white;
  margin-top: -10%;
  margin-left: 10px;
  margin-right: 10px;
  padding: 30px;
  max-height: 80%;
  overflow: auto;
`;

const PublicLayoutElement = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function PublicLayout() {
  return (
    <PublicLayoutElement>
      <Box>
        <Switch>
          <Route path={REQUESTPASSWORDRESET}>
            <RequestResetPassword />
          </Route>
          <Route path={PASSWORDRESET}>
            <ResetPassword />
          </Route>
          <Route path={PRIVACYPOLICY}>
            <PrivacyPolicy />
          </Route>
          <Route path={DELETEACCOUNT}>
            <DeleteAccount />
          </Route>
          <Route path={SUPPORT}>
            <Support />
          </Route>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={LOGOUT}>
            <LogOut />
          </Route>
          <Route path={REGISTERUSER}>
            <AccountRegister />
          </Route>
        </Switch>
      </Box>
    </PublicLayoutElement>
  );
}
