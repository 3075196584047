// src/components/APIErrorNotification/index.js
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext } from "react";
import styled from "styled-components";
import { SocketContext } from "../Context/SocketProvider";
import { NotificationText } from "../notifactionTextResolver";

const StyledAlert = styled(Alert)`
  background: var(--green);
  color: var(--white);
`;
const StyledSnackbar = styled(Snackbar)`
  top: calc(50px + var(--platform-top));
`;
export default function UINotifications() {
  const socketContext = useContext(SocketContext);

  const handleSubmit = (id: string, markAsSeen: boolean) => {
    socketContext.removeNotification(id, markAsSeen);
  };
  return (
    <>
      {socketContext.notifications.map((x) => {    
        return (
          <StyledSnackbar
            key={x.id}
            open
            autoHideDuration={6000}
            onClose={(event: object, reason: string) => {
              if (reason === "timeout") {
                handleSubmit(x.id, false);
              }
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <StyledAlert icon={false} onClose={() => handleSubmit(x.id, true)} severity="success">
              <NotificationText model={x.model} />
            </StyledAlert>
          </StyledSnackbar>
        );
      })}
    </>
  );
}
