import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export interface useMarkBookingAsNoShowByUserIdPayload {
  classId: string;
  userId: string;
}
export default function useMarkBookingAsNoShowByUserId() {
  return useMutationWithError<boolean, unknown, useMarkBookingAsNoShowByUserIdPayload>(
    ({ classId, userId }) =>
      axios.post(
        `/api/booking/MarkAsNoShow?classId=${classId}&userId=${userId}`
      ),
    {
      onSuccess: (data, { classId }) => {
        // Query Invalidations
        queryCache.invalidateQueries(["bookingsByClassId", classId]);        
        queryCache.invalidateQueries("activeUserBookings");
      },
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
