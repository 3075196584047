import { useMutation } from "react-query";
import axios from "./../../axios";
import { UnRegisterToUserOnDevicePayload } from "./notificationModels";

export default function useUnRegisterToUserOnDevice() {
  return useMutation<boolean, unknown, UnRegisterToUserOnDevicePayload>(
    (payload) => {
    
      return axios.post(`/api/notification/UnRegisterToUserOnDevice`, payload);
    },
    {
      onSuccess: (data, notificationId) => {
        // Query Invalidations
        // queryCache.invalidateQueries(["bookingsByClassId", classId]);
      },
    }
  );

  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
