import React from "react";

export default function MessagesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        fill="#868685"
        d="M494.4 230.65C506.33 381.98 381 507.31 229.67 495.4 110.63 486.03 14.17 389.61 4.76 270.57-7.21 119.23 118.14-6.16 269.5 5.77c119.05 9.38 215.51 105.83 224.9 224.88z"
      />
      <g fill="#FFF">
        <path d="M163.05 287.86l-1.95-.52h-1.59c-2.12 0-6.45 0-29.14 12l7.75-21.83-6.83-4.64c-21.16-14.38-32.82-33.65-32.82-54.26 0-41.39 47.01-75.05 104.8-75.05 31.43 0 59.6 10 78.83 25.74 4.78-.42 9.61-.69 14.54-.69 5.05 0 10 .29 14.9.73-22.05-28.18-62.26-47.15-108.26-47.15-69.56 0-126.16 43.25-126.16 96.42 0 25.11 12.82 49.14 35.45 67L91.1 346.03l25.06-14.4c16.37-9.4 36.44-20.1 43.2-22.6 9.37 2.67 19.1 4.37 29.03 5.25-5.69-7.25-10.15-15.12-13.19-23.44-4.11-.83-8.18-1.79-12.15-2.98z" />
        <path d="M412.12 265.01c0-47.33-51.67-85.73-115.48-85.73-63.78 0-115.49 38.4-115.49 85.73 0 47.34 51.72 85.72 115.49 85.72 15.34 0 29.96-2.22 43.34-6.24 4.45-1.38 49.08 24.29 49.08 24.29l-14.44-40.67c23-15.66 37.5-38.09 37.5-63.1zm-53.49 27.61H239.5v-11.17h119.13v11.17zm0-22.35H239.5V259.1h119.13v11.17zm0-22.31H239.5v-11.17h119.13v11.17z" />
      </g>
    </svg>
  );
}
