import { useQuery } from "react-query";
import axios from "./../../axios"
import { FriendshipViewModel } from "./friendshipModels";


const getClasses = async (_: any) => {
  const { data } = await axios.get(`/api/Friendship/GetRequests`);
  return data;
};

export default function useGetRequests() {
  return useQuery<FriendshipViewModel[]>(["friendrequests"], getClasses);
}
