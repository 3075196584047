import { useQuery } from "react-query";
import axios from "./../../axios"
import {  MembershipViewModel } from "./membershipModels";


const getMemberships = async (_: any, userId: string) => {
  const { data } = await axios.get(`/api/Membership/GetByUserId/${userId}`);
  return data;
};

export default function useMembershipsByUserId(userId: string) {
  return useQuery<MembershipViewModel[]>(["memberships", userId], getMemberships);
}
