import { AxiosError } from "axios";
import React from "react";
import { ErrorOption, FieldName } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import EditUserDetails from "../components/EditUserDetails";
import { addServerErrors } from "../components/ErrorSummary";
import {
  EmailSettingsWriteModel,
  SetCoachRoleByUserIdWriteModel,
  UpdateWriteModel,
} from "../hooks/account/accountModels";
import useGetFullUser from "../hooks/account/useGetFullUser";
import useUpdateAccountById from "../hooks/account/useUpdateAccountById";
import { USER } from "../routes";

import { Box } from "../components/Box";
import { PageHeader } from "../components/PageHeader";
import { PageContent } from "../components/PageContent";

import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";
import EditUserEmailNotificationSettings from "../components/EditUserEmailNotificationSettings";
import useSetEmailSettingsByUserId from "../hooks/account/useSetEmailSettingsByUserId";
import EditUserCoachSettings from "../components/EditUserCoachSettings";
import useSetCoachRoleByUserId from "../hooks/account/useSetCoachRoleByUserId";
interface UserParams {
  userId: string;
}
export default function EditUser() {
  const history = useHistory();
  let { userId } = useParams<UserParams>();
  const { data: user } = useGetFullUser(userId);
  const [updateAccountByIdMutate] = useUpdateAccountById();
  const [setEmailSettingsByUserIdMutate] = useSetEmailSettingsByUserId();
  const [setCoachRoleByUserIdMutate] = useSetCoachRoleByUserId();
  const handleCancelEdit = () => {
    history.push(USER.replace(":userId", userId));
  };
  const handleSubmitEdit = (
    data: UpdateWriteModel,
    setError: (name: FieldName<UpdateWriteModel>, error: ErrorOption) => void
  ) => {
    const newData = { userId: userId, ...data };
    updateAccountByIdMutate(newData)
      .then(() => {
        // alert("done");
        history.push(USER.replace(":userId", userId));
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };
  const handleSubmitEdit2 = (
    data: EmailSettingsWriteModel,
    setError: (
      name: FieldName<EmailSettingsWriteModel>,
      error: ErrorOption
    ) => void
  ) => {
    const newData = { userId: userId, ...data };
    setEmailSettingsByUserIdMutate(newData)
      .then(() => {
        // alert("done");
        history.push(USER.replace(":userId", userId));
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };
  const handleSubmitEditCoach = (
    data: SetCoachRoleByUserIdWriteModel,
    setError: (
      name: FieldName<SetCoachRoleByUserIdWriteModel>,
      error: ErrorOption
    ) => void
  ) => {
    data.userId = userId;
    setCoachRoleByUserIdMutate(data)
      .then(() => {
        // alert("done");
        history.push(USER.replace(":userId", userId));
      })
      .catch((err: AxiosError) => {
        if (err.response?.data.errors) {
          addServerErrors(err.response?.data.errors, setError);
        }
      });
  };

  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.large}>
      <PageHeader backButton={true}>Ret bruger oplysninger</PageHeader>
      <PageContent>
        <Box>
          {user && (
            <EditUserDetails
              user={user}
              handleCancelEdit={handleCancelEdit}
              handleSubmitEdit={handleSubmitEdit}
            />
          )}
        </Box>
        <Box>
          {user && (
            <EditUserEmailNotificationSettings
              user={user}
              handleCancelEdit={handleCancelEdit}
              handleSubmitEdit={handleSubmitEdit2}
            />
          )}
        </Box>
        <Box>
          {user && (
            <EditUserCoachSettings
              user={user}
              handleCancelEdit={handleCancelEdit}
              handleSubmitEdit={handleSubmitEditCoach}
            />
          )}
        </Box>
      </PageContent>
    </PageContainer>
  );
}
