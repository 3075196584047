import React from "react";

import { Route, Redirect } from "react-router-dom";
import { useAuthState } from "./Context/AuthProvider";
import { LOGIN } from "./routes";

export type AppRouteProps = {
  isPrivate: boolean;
  path: string;
  children: React.ReactNode;
  requiresRoles?: 'Administrator' | 'Coach' | null;
};

const AppRoute = ({
  children,
  path,
  isPrivate,
  requiresRoles = null,
}: AppRouteProps ) => {
  const userDetails = useAuthState();
  // if (
  //   userDetails != null &&
  //   userDetails.token !== null &&
  //   new Date() > userDetails.expires
  // ) {
  //   alert("expired!");
  // }

  let hasAccess = !(
    isPrivate &&
    (userDetails === null ||
      userDetails.token === null ||
      userDetails.user === null)
  );

  if (hasAccess && requiresRoles) {
    if (userDetails?.user?.roles)
      hasAccess = userDetails.user.roles.indexOf(requiresRoles) > -1;
    else hasAccess = false;
  }
  return (
    <Route
      path={path}
      render={(props) =>
        !hasAccess ? <Redirect to={{ pathname: LOGIN }} /> : children
      }
    />
  );
};

export default AppRoute;
