import { useQuery } from "react-query";
import axios from "../../axios"
import { ClassViewModel } from "./classModels";


const getClass = async (_: any,classId: string) => {
  const { data } = await axios.get(`/api/class/${classId}`);
  return data;
};

export default function useClass(classId: string) {
  return useQuery<ClassViewModel>(["classes", classId], getClass);
}
