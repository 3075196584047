import React from "react";
import { useRef } from "react";
import { AccountViewModel } from "../hooks/account/accountModels";
import useSetPicture from "../hooks/account/useSetPicture";
import useSetPictureById from "../hooks/account/useSetPictureById";

import styled from "styled-components";
export type UserDetailsProps = {
  user: AccountViewModel;
  isAdmin: boolean;
  canEdit: boolean;
};

const PictureContainer = styled.div`
  text-align: center;
  position: relative;
`;
const Picture = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 10px;
`;

const EditOverlay = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 10px;
  position: absolute;
  overflow: hidden;
  display: inline-block;

  & div {
    height: 45px;
    position: absolute;
    width: 100%;
    bottom: 0;
    color: var(--white);
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

const UserPicture = ({ user, isAdmin, canEdit }: UserDetailsProps) => {
  const [setPictureByIdMutate] = useSetPictureById();

  const [setPictureMutate] = useSetPicture();
  const inputFile = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    if (!canEdit) {
      return;
    }
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!canEdit) {
      return;
    }
    var files = e?.target?.files;
    if (files !== null && files.length > 0) {
      let formData = new FormData();
      formData.append("picture", files[0]);
      if (files !== null && files.length > 0) {
        if (isAdmin) {
          setPictureByIdMutate({
            userId: user.id,
            formData: formData,
          });
        } else {
          setPictureMutate({
            formData: formData,
          });
        }
      }
      //   const model: SetPictureWriteModel = {};
      //
      //   axios.post("/api/account/SetPicture", formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //     onUploadProgress: (event: any) => {
      //       console.log(Math.round((100 * event.loaded) / event.total));
      //     },
      //   });
    }
  };
  return (
    <PictureContainer>
      {canEdit && (
        <EditOverlay onClick={handleUploadClick}>
          <div>Nyt billede</div>
        </EditOverlay>
      )}
      <Picture
        src={user.picture}
        alt={user.firstName}
        onClick={handleUploadClick}
      />

      <input
        type="file"
        id="file"
        onChange={handleInputChange}
        ref={inputFile}
        style={{ display: "none" }}
      />
    </PictureContainer>
  );
};

export default UserPicture;
