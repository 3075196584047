import { queryCache } from "react-query";
import useMutationForForms from "../useMutationForForms";
import axios from "./../../axios";
import { UpdateWriteModelById } from "./accountModels";

export default function useUpdateAccountById() {
  return useMutationForForms<string, unknown, UpdateWriteModelById>(
    (data: UpdateWriteModelById) =>
      axios.post(`/api/account/UpdateByUserId?userId=${data.userId}`, data),
    {
      onSuccess: (data, updateData) => {
        queryCache.invalidateQueries(["account-get-full", updateData.userId]);
      }
    }
  );
}
