import { useQuery } from "react-query";
import axios from "../../axios";
import { ClassViewModel } from "./classModels";

const getClasses = async (_: any, classIds: string[]) => {
  const { data } = await axios.get(
    `/api/class/GetByIds?ids=${classIds.join("&ids=")}`
  );
  return data;
};

export default function useClassesByIds(
  classIds: string[],
  enabled: boolean = true
) {
  return useQuery<ClassViewModel[]>(["classesbyids", classIds], getClasses, {
    enabled: enabled,
  });
}
