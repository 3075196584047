import React from "react";

export default function BookingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        fill="#868685"
        d="M494.4 230.65C506.33 381.98 381 507.31 229.67 495.4 110.63 486.03 14.17 389.61 4.76 270.57-7.21 119.23 118.14-6.16 269.5 5.77c119.05 9.38 215.51 105.83 224.9 224.88z"
      />
      <g fill="#FFF">
        <path d="M167.33 160.76h20.77c3.14 0 5.68-2.56 5.68-5.69v-45.7c0-3.14-2.54-5.68-5.68-5.68h-20.77a5.68 5.68 0 00-5.7 5.68v45.7c0 3.13 2.54 5.69 5.7 5.69zM313.73 160.76h20.79c3.12 0 5.66-2.56 5.66-5.69v-45.7c0-3.14-2.54-5.68-5.66-5.68h-20.79c-3.15 0-5.7 2.54-5.7 5.68v45.7c.01 3.13 2.55 5.69 5.7 5.69z" />
        <path d="M358.99 125.53h-7.32v29.53c0 9.46-7.69 17.19-17.15 17.19h-20.79c-9.46 0-17.19-7.72-17.19-17.19v-29.53h-91.28v29.53c0 9.46-7.71 17.19-17.17 17.19h-20.77c-9.46 0-17.19-7.72-17.19-17.19v-29.53h-7.35c-18.24 0-33.06 14.82-33.06 33.06V344.9c0 18.28 14.82 33.08 33.06 33.08h216.2c18.28 0 33.08-14.8 33.08-33.08V158.59c.01-18.24-14.79-33.06-33.07-33.06zm12.17 219.37c0 6.7-5.46 12.16-12.17 12.16h-216.2c-6.7 0-12.12-5.46-12.12-12.16V219.94h240.5V344.9z" />
      </g>
      <g fill="#FFF">
        <path d="M158 261h72v12h-72zM238 261h106v12H238z" />
      </g>
      <g fill="#FFF">
        <path d="M158 241h72v12h-72zM238 241h106v12H238z" />
      </g>
      <g fill="#FFF">
        <path d="M158 281h72v12h-72zM238 281h106v12H238z" />
      </g>
      <g fill="#FFF">
        <path d="M322.68 341.87l-1.96-4.41c-.8-1.72-1.4-3.13-2.08-4.69h-.08c-.48 1.56-1.12 2.97-1.76 4.65l-1.8 4.45h-8.22l7.62-13.67-7.34-13.35h8.3l1.84 4.29c.68 1.6 1.2 2.93 1.8 4.49h.08c.6-1.76 1.04-2.97 1.6-4.45l1.64-4.33h8.26l-7.33 13.19 7.82 13.83h-8.39zM347.62 341.87l-1.96-4.41c-.8-1.72-1.4-3.13-2.08-4.69h-.08c-.48 1.56-1.12 2.97-1.76 4.65l-1.8 4.45h-8.22l7.62-13.67-7.34-13.35h8.3l1.84 4.29c.68 1.6 1.2 2.93 1.8 4.49h.08c.6-1.76 1.04-2.97 1.6-4.45l1.64-4.33h8.26l-7.33 13.19 7.82 13.83h-8.39z" />
      </g>
    </svg>
  );
}
