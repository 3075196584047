import { useMutation } from "react-query";
import axios from "./../../axios";
import { RegisterRequestedToSendNotificationsPayload } from "./notificationModels";

export default function useRegisterRequestedToSendNotifications() {
  return useMutation<boolean, unknown, RegisterRequestedToSendNotificationsPayload>(
    (payload) => {    
      return axios.post(`/api/notification/RegisterRequestedToSendNotifications`, payload);
    },
    {
      onSuccess: (data, notificationId) => {
        // Query Invalidations
        // queryCache.invalidateQueries(["bookingsByClassId", classId]);
        //alert("OK")
      },
      onError: (data, notificationId) => {
      
       // alert("error")
      },
    }
  );

  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
