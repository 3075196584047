import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  PageContainer,
  ContentContainerBackgroundSize,
} from "../components/PageContainer";

import { Box, BoxHeader } from "../components/Box";
import { PageHeader } from "../components/PageHeader";
import { PageContent } from "../components/PageContent";
import UserDetails from "../components/UserDetails";
import { UserMemberships } from "../components/UserMemberships";
import UserPicture from "../components/UserPicture";
import useMe from "../hooks/account/useMe";
import { EDITPROFILE, LOGOUT } from "../routes";
import CustomButton from "../components/CustomButton";
import ButtonAligner from "../components/ButtonAligner";
import axios from "../axios";

export default function Profile() {
  // let match = useRouteMatch();
  const { data } = useMe();

  const [clicks, setClicks] = useState<number>(0);
  const [versionfromtxt, setversionfromtxt] = useState<string>("");
  const [onlineversion, setonlineversion] = useState<string>("");
  const [autoupdateEnabled, setautoupdateEnabled] = useState<boolean>(localStorage.getItem("autoupdate") === 'true');
  const [showDiagnostics, setShowDiagnostics] = useState<boolean>(false);
  const handleHiddenClick = () => {
    setClicks(clicks + 1);
    if (clicks > 3) {
      setClicks(0);
      setShowDiagnostics(true);
      axios
        .get(`/current-version.txt?timestamp=${new Date().getTime()}`, {
          //set to override default base url
          baseURL: "",
        })
        .then((res) => {
          setversionfromtxt(res.data);
        });
      axios
        .get(
          `https://crossfit.prokode.dk/current-version.txt?timestamp=${new Date().getTime()}`
        )
        .then((res) => {
          setonlineversion(res.data);
        });
    }
  };

  const toggleAutoUpdate = () => {
    if (localStorage.getItem("autoupdate")) {
      localStorage.removeItem("autoupdate");
      setautoupdateEnabled(false);
    } else {
      localStorage.setItem("autoupdate", "true");
      setautoupdateEnabled(true);
    }
  };
  return (
    <PageContainer backgroundSize={ContentContainerBackgroundSize.large}>
      <PageHeader>Profil</PageHeader>
      <PageContent>
        {data && <UserPicture user={data} isAdmin={false} canEdit={true} />}
        {data && (
          <UserDetails user={data}>
            <ButtonAligner>
              <CustomButton component={NavLink} to={EDITPROFILE}>
                Ret mine oplysninger
              </CustomButton>
            </ButtonAligner>
          </UserDetails>
        )}

        <UserMemberships />
        {showDiagnostics && (
          <Box>
            <BoxHeader>Debug</BoxHeader>
            <p>
              <strong>Akutuelle version fra env:</strong>{" "}
              {process.env.REACT_APP_VERSION}
            </p>
            <p>
              <strong>Akutuelle version fra txt:</strong> {versionfromtxt}
            </p>
            <p>
              <strong>Tilgængelig version fra txt (web):</strong>{" "}
              {onlineversion}
            </p>
            <p><strong>Auto update enabled:</strong> {autoupdateEnabled ? "Ja" : "Nej"}</p>
            <ButtonAligner>
              <CustomButton onClick={toggleAutoUpdate}>
                Toggle auto update
              </CustomButton>
            </ButtonAligner>
          </Box>
        )}
        <Box onClick={handleHiddenClick}>
          <ButtonAligner>
            <CustomButton component={NavLink} to={LOGOUT}>
              Log ud
            </CustomButton>
          </ButtonAligner>
          {/* <a href="/">Opdater</a> */}
        </Box>
      </PageContent>
    </PageContainer>
  );
}
