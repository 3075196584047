import { useQuery } from "react-query";
import axios from "./../../axios"
import { BookingViewModel } from "./bookingModels";


const getBookings = async (_: any) => {
  const { data } = await axios.get(`/api/booking`);
  return data;
};

export default function useActiveUserBookings() {
  return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
