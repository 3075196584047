import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";
import { BookingViewModel } from "./bookingModels";
export interface BookClassByUserIdPayload {
  classId: string;
  userId: string;
}

export default function useBookClassByUserId() {
  return useMutationWithError<BookingViewModel, unknown, BookClassByUserIdPayload>(
    ({ classId, userId }) => axios.post(`/api/booking/AddByUserId?classId=${classId}&userId=${userId}`),
    {
      onSuccess: (data, { classId }) => {
        // Query Invalidations
        queryCache.invalidateQueries(["bookingsByClassId", classId]);
        queryCache.invalidateQueries(["classes", classId]);
      },
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
