import { useQuery } from "react-query";
import axios from "./../../axios"
import { BookingFullViewModel } from "./bookingModels";


const getBookings = async (_: any, classId: string) => {
  const { data } = await axios.get(`/api/booking/GetFriendsByClassId?classId=${classId}`);
  return data;
};

export default function useFriendshipBookingsByClassId(classId: string) {
  return useQuery<BookingFullViewModel[]>(["friendshipbookingsByClassId", classId], getBookings);
}
