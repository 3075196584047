import { useQuery } from "react-query";
import axios from "./../../axios";
import { FullAccountViewModel } from "./accountModels";

const getGet = async (_: any, userId: string) => {
  const { data } = await axios.get<FullAccountViewModel>(
    `/api/account/getfulluser`,
    { params: { userId } }
  );
  return data;
};

export default function useGetFullUser(
  userId: string,
  enabled: boolean = true
) {
  return useQuery(["account-get-full", userId], getGet, { enabled: enabled });
}
