import React from "react";

import styled from "styled-components";
import CustomButton from "./CustomButton";
import { Capacitor } from "@capacitor/core";
export type ButtonAlignerProps = {
  children?: React.ReactNode;
};
const ClassContainer = styled.div`
  display: flex;
  flex-direction: column ;
  align-items: center;
  @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
    align-items: start;
    justify-content: center;
    flex-direction: row ;
  }
  & ${CustomButton} {
    &:not(:last-child) {
     
      margin-bottom: 10px;
      @media only screen and (min-width: ${(props) => (Capacitor.getPlatform() === "web" ? "769px" : "5000px")}) {
        margin-right: 10px;
    
      }
    }   
  }
`;
export default function ButtonAligner({
 
  children,
}: ButtonAlignerProps) {
  return (
    <ClassContainer>
      {children}
    </ClassContainer>
  );
}
