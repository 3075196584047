import { queryCache } from "react-query";
import useMutationWithError from "../useMutationWithError";
import axios from "./../../axios";

export type CustomDate = {
  year: string;
  month: string;
  day: string;
};

export default function useFillClasses() {
  return useMutationWithError<boolean, unknown, CustomDate>(
    (date: CustomDate) => {
      return axios.post(
        `/api/DefaultClass/FillDate?date=${date.year}-${date.month}-${date.day}`
      );
    },
    {
      onSuccess: (data, date) => {
        // Query Invalidations
        // queryCache.invalidateQueries("activeUserBookings");
        // alert(`${date.year}-${date.month}-${date.day}`)
        queryCache.invalidateQueries([
          "classes",
          `${date.year}-${date.month}-${date.day}`,
        ]);
      },
    }
  );
  //   return useQuery<BookingViewModel[]>(["activeUserBookings"], getBookings);
}
